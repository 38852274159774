import { Box, Button, Chip, CircularProgress, Radio, Stack, TextField, Typography } from '@mui/material';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';
import { Control, Controller, FieldErrors, FieldValues, UseFormHandleSubmit, useForm } from 'react-hook-form';

import { SHIFT_SLOT_CHANGE_REASON } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useMarkSlotOpen } from '~/scheduling/api/queries/shift-slot/markSlotOpen';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import SegmentedModal from '../../../../../components/SegmentedModal';
import {
    changeStaffModalSlotIdAtom,
    changeStaffSelectedReasonAtom,
    isChangeStaffModalOpenAtom,
    selectStaffModalSlotIdAtom,
} from '../../atoms';

const reasonOptions: { value: SHIFT_SLOT_CHANGE_REASON; label: string; withNotes?: true }[] = [
    { value: SHIFT_SLOT_CHANGE_REASON.CALL_OFF, label: 'Call off', withNotes: true },
    { value: SHIFT_SLOT_CHANGE_REASON.SWAP, label: 'Swap with another staff', withNotes: true },
    { value: SHIFT_SLOT_CHANGE_REASON.TIME_OFF, label: 'Time off' },
    { value: SHIFT_SLOT_CHANGE_REASON.END_EMPLOYMENT, label: 'End of employment' },
    { value: SHIFT_SLOT_CHANGE_REASON.MISPLACEMENT, label: 'Accidentally placed on shift' },
    { value: SHIFT_SLOT_CHANGE_REASON.OTHER, label: 'Other', withNotes: true },
];

const loadingButtonAtom = atom<'open' | 'select' | null>(null);

const ChangeStaffHeader = () => (
    <Stack spacing="8px">
        <Typography variant="body1" fontSize="16px" fontWeight={700}>
            Change Staff
        </Typography>
        <ChangeStaffHeaderChips />
    </Stack>
);

const ChangeStaffHeaderChips = () => {
    const slotId = useAtomValue(changeStaffModalSlotIdAtom);

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !slotById) return null;

    const { shiftDay, roleId, roleShiftId, locationId } = slotById.get(slotId)!;
    const dayStr = DateTime.fromISO(shiftDay).toFormat('EEE, MMM d');
    const role = roleById?.get(roleId)!.name;
    const roleShift = roleShiftById?.get(roleShiftId)!.name;
    const location = locationById?.get(locationId)!.abbreviation;

    return (
        <Stack direction="row" spacing="4px">
            <Chip variant="outlined" size="small" color="primary" label={dayStr} />
            <Chip variant="outlined" size="small" color="primary" label={roleShift} />
            <Chip variant="outlined" size="small" label={location} />
            <Chip variant="outlined" size="small" label={role} />
        </Stack>
    );
};

const ChangeStaffReasons = ({ control, errors }: { control: Control; errors: FieldErrors }) => (
    <form>
        <Stack spacing="12px">
            <Typography variant="body1" fontWeight={700}>
                Select reason for change
            </Typography>
            {reasonOptions.map((option) => (
                <ChangeStaffReason key={option.value} {...option}>
                    {option.withNotes && <ChangeStaffReasonTextField control={control} errors={errors} />}
                </ChangeStaffReason>
            ))}
        </Stack>
    </form>
);

const ChangeStaffReason = ({
    value,
    label,
    children,
}: {
    value: SHIFT_SLOT_CHANGE_REASON;
    label: string;
    children?: React.ReactNode;
}) => {
    const [selectedReason, setSelectedReason] = useAtom(changeStaffSelectedReasonAtom);
    const isSelected = selectedReason === value;

    return (
        <Stack spacing="8px">
            <Stack direction="row" alignItems="center" spacing="8px">
                <Radio checked={isSelected} onChange={() => setSelectedReason(value)} />
                <Typography variant="body1" fontWeight={700}>
                    {label}
                </Typography>
            </Stack>
            {isSelected && children && <Box pl="32px">{children}</Box>}
        </Stack>
    );
};

const ChangeStaffReasonTextField = ({ control, errors }: { control: Control; errors: FieldErrors }) => (
    <Controller
        control={control}
        name="notes"
        rules={{ required: 'Reason is required' }}
        render={({ field }) => (
            <TextField
                {...field}
                variant="outlined"
                placeholder="Input detailed reason"
                error={!!errors.notes}
                helperText={(errors.notes?.message as string) ?? ''}
                fullWidth
            />
        )}
    />
);

const ChangeStaffActions = ({
    getValues,
    handleSubmit,
    reset,
}: {
    getValues: () => Record<string, string>;
    handleSubmit: UseFormHandleSubmit<FieldValues>;
    reset: () => void;
}) => {
    const [loadingButton, setLoadingButton] = useAtom(loadingButtonAtom);

    const reason = useAtomValue(changeStaffSelectedReasonAtom);

    const slotId = useAtomValue(changeStaffModalSlotIdAtom);
    const toggle = useSetAtom(isChangeStaffModalOpenAtom);
    const setSelectStaffModalSlotId = useSetAtom(selectStaffModalSlotIdAtom);

    const { mutateAsync: markSlotOpen, isPending } = useMarkSlotOpen();

    if (!slotId) return null;

    const handleMarkOpen = async () => {
        const { notes } = getValues();

        await markSlotOpen({ slotId, data: { reason, notes } });

        reset();
        toggle();
    };

    const onMarkOpen = async () => {
        setLoadingButton('open');

        try {
            await handleMarkOpen();
        } finally {
            setLoadingButton(null);
        }
    };

    const onSelectStaff = async () => {
        setLoadingButton('select');

        try {
            // Temporary hack: always mark as open, even if selecting a new staff
            await handleMarkOpen();
        } finally {
            setLoadingButton(null);
        }

        setSelectStaffModalSlotId(slotId);
    };

    return (
        <>
            <Button variant="outlined" color="error" onClick={handleSubmit(onMarkOpen)} disabled={!reason || isPending}>
                {loadingButton === 'open' ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'error.100' }} />
                ) : (
                    'Mark as Open Shift'
                )}
            </Button>
            <Button onClick={handleSubmit(onSelectStaff)} disabled={!reason || isPending}>
                {loadingButton === 'select' ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    'Select Staff'
                )}
            </Button>
        </>
    );
};

const ChangeStaffModal = () => {
    const [isOpen, toggle] = useAtom(isChangeStaffModalOpenAtom);
    const isLoading = !!useAtomValue(loadingButtonAtom);

    const {
        getValues,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    return (
        <SegmentedModal
            isOpen={isOpen}
            onClose={toggle}
            header={<ChangeStaffHeader />}
            actions={<ChangeStaffActions getValues={getValues} handleSubmit={handleSubmit} reset={reset} />}
            closeButton
            closeButtonProps={{ disabled: isLoading }}
        >
            <ChangeStaffReasons control={control} errors={errors} />
        </SegmentedModal>
    );
};

export default ChangeStaffModal;
