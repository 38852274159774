import { atom, useAtom } from 'jotai';
import { DateTime, Interval } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';

import { useGetCommunitySettings } from './api/queries/community-settings/getCommunitySettings';
import { getWeekInterval } from './utils/dates';

const weekIntervalAtom = atom<Interval<true> | undefined>(undefined);

export const useWeekInterval = () => {
    const [weekInterval, _setWeekInterval] = useAtom(weekIntervalAtom);

    const { data: communitySettings } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const setWeekInterval = useCallback(
        (date: DateTime) => _setWeekInterval(firstDayOfWeek ? getWeekInterval(date, firstDayOfWeek) : undefined),
        [firstDayOfWeek, _setWeekInterval]
    );

    const weekDays = useMemo(
        () => weekInterval?.splitBy({ days: 1 }).map((interval: Interval<true>) => interval.start),
        [weekInterval]
    );

    const weekDayStrs = useMemo(() => weekDays?.map((day) => day.toISODate()), [weekDays]);

    useEffect(() => {
        if (firstDayOfWeek && !weekInterval) setWeekInterval(DateTime.now());
    }, [firstDayOfWeek, weekInterval, setWeekInterval]);

    return { weekInterval, setWeekInterval, weekDays, weekDayStrs };
};
