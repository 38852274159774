import { Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { PiUserPlusBold } from 'react-icons/pi';
import { useNavigate } from 'react-router';

const HeaderContainer = styled(Stack)(() => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px 24px',
    backgroundColor: '#fff',
}));

const HeaderSection = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
});

const Header = () => {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <HeaderSection>
                <Typography variant="h6">Staff</Typography>
            </HeaderSection>
            <HeaderSection>
                <Button
                    size="small"
                    startIcon={<PiUserPlusBold />}
                    sx={{ fontSize: '12px' }}
                    onClick={() => navigate('staff-details')}
                >
                    Add Staff
                </Button>
            </HeaderSection>
        </HeaderContainer>
    );
};

export default Header;
