import { GlobalStyles as GlobalThemeStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

const GlobalStyles = () => {
    const { palette } = useTheme();

    return (
        <GlobalThemeStyles
            styles={{
                '*': {
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                },
                html: {
                    width: '100%',
                    height: '100%',
                    WebkitOverflowScrolling: 'touch',
                },
                body: {
                    marginBottom: pxToRem(16),
                    padding: 0,
                    fontFamily: ['Open Sans', 'sans-serif'],
                    fontSize: pxToRem(16),
                    fontWeight: 400,
                    textAlign: 'justify',
                },
                '#react': {
                    height: '100%',
                },
                '#root': {
                    width: '100%',
                    height: '100%',
                },
                a: {
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    '&:active': {
                        textDecoration: 'none',
                        fontWeight: 'bold',
                    },
                    '&:focus': {
                        textDecoration: 'none',
                        fontWeight: 'bold',
                    },
                    '&:hover': {
                        textDecoration: 'none',
                        fontWeight: 'bold',
                    },
                },
                img: {
                    display: 'block',
                    maxWidth: '100%',
                },
                input: {
                    '&[type=number]': {
                        MozAppearance: 'textfield',
                        '&::-webkit-outer-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none',
                        },
                        '&::-webkit-inner-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none',
                        },
                    },
                },
                p: {
                    textAlign: 'justify',
                },
                textarea: {
                    '&::-webkit-input-placeholder': {
                        color: palette.text.disabled,
                    },
                    '&::-moz-placeolder': {
                        opacity: 1,
                        color: palette.text.disabled,
                    },
                    '&::-ms-input-placeholder': {
                        color: palette.text.disabled,
                    },
                    '&::placeholder': {
                        color: palette.text.disabled,
                    },
                },
                '.blur-up': {
                    WebkitFilter: 'blur(5px)',
                    filter: 'blur(5px)',
                    transition: 'filter 400ms, -webkit-filter 400ms',
                },
                '.blur-up.lazyloaded ': {
                    WebkitFilter: 'blur(0)',
                    filter: 'blur(0)',
                },
            }}
        />
    );
};

export default GlobalStyles;
