import { pxToRem } from '~/components/theme/typography';

export default function Tabs(theme) {
    const { palette } = theme;

    return {
        MuiTable: {
            styleOverrides: {
                root: {
                    '& .MuiTableHead-root': {
                        '& .MuiTableRow-root': {
                            '& #checkbox': {
                                padding: `${pxToRem(8)} ${pxToRem(4)}`,
                                width: pxToRem(40),
                            },
                            '& .MuiTableCell-root': {
                                padding: `${pxToRem(8)} ${pxToRem(16)}`,
                                color: palette.app.green.main,
                                backgroundColor: palette.tableHeader,
                                fontSize: pxToRem(14),
                            },
                        },
                    },
                    '& .MuiTableRow-root': {
                        '& #checkbox': {
                            padding: `${pxToRem(8)} ${pxToRem(4)}`,
                            width: pxToRem(40),
                        },
                        '& .MuiTableCell-root': {
                            padding: `${pxToRem(8)} ${pxToRem(16)}`,
                            fontSize: pxToRem(14),
                        },
                    },
                },
            },
        },
    };
}
