import { useMutation } from '@tanstack/react-query-v4';

import { FRAUD_STATUS } from '@allie/utils/src/constants/users.constants';

import { api } from '~/api';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';

type UserFraudStatusResponse = { status: keyof typeof FRAUD_STATUS | null };

export const useUserDeviceMetadataMutation = () => {
    const userToken = useToken();

    return useMutation({
        mutationFn: async ({ token, deviceId }: { token: string; deviceId: string }) => {
            await api.post(
                '/users/device-metadata',
                { pushNotificationToken: token, deviceId },
                {
                    headers: {
                        authorization: userToken,
                    },
                }
            );
        },
    });
};

export const useUserFraudStatusQuery = (branchId?: number, shiftId?: number) => {
    const token = useToken();

    return useErrorHandledQuery<UserFraudStatusResponse>(
        ['user-fraud-status', branchId, shiftId],
        async (): Promise<UserFraudStatusResponse> => {
            const { data } = await api.get(`/users/fraud-status`, {
                headers: { authorization: token },
                params: { branch_id: branchId, shift_id: shiftId },
            });
            return data.response;
        },
        {
            enabled: !!branchId && !!shiftId,
        }
    );
};
