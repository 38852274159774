import { Theme, Typography, styled } from '@mui/material';
import React from 'react';

const mapTypeToColor = (palette: Theme['palette']) =>
    ({
        positive: palette.primary[400] as string,
        neutral: palette.grey[600],
        negative: palette.error[600] as string,
    }) satisfies Record<ChipVariant, string>;

const ChipStyled = styled(Typography)<{ type: ChipVariant }>(({ theme, type }) => ({
    fontSize: 13,
    padding: '4px 8px',
    borderRadius: '12px',
    lineHeight: '16px',
    backgroundColor: '#FFF',
    fontWeight: 600,
    color: mapTypeToColor(theme.palette)[type],
}));

type ChipVariant = 'positive' | 'neutral' | 'negative';

interface ChipProp {
    variant: 'positive' | 'neutral' | 'negative';
    label: string;
}

export const CustomChip = (props: ChipProp) => <ChipStyled type={props.variant}>{props.label}</ChipStyled>;
