import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { Warning } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

import { useChangeSlotStaff } from '~/scheduling/api/queries/shift-slot/changeSlotStaff';
import { useGetSlotStaffWarnings } from '~/scheduling/api/queries/shift-slot/getSlotStaffWarnings';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import CustomModal from '~/scheduling/components/CustomModal';

import {
    confirmStaffWarningIndexAtom,
    isConfirmStaffModalOpenAtom,
    isSelectStaffModalOpenAtom,
    selectStaffModalSlotIdAtom,
    selectStaffSelectedStaffIdAtom,
} from '../../../atoms';

const ConfirmStaffIcon = () => {
    const { palette } = useTheme();

    return (
        <Box
            sx={{
                bgcolor: palette.secondary[50] as string,
                width: '88px',
                height: '88px',
                p: '32px',
                borderRadius: '50%',
            }}
        >
            <Warning color={palette.secondary[500] as string} weight="fill" fontSize="24px" />
        </Box>
    );
};

const ConfirmStaffMessage = () => {
    const { palette } = useTheme();

    const slotId = useAtomValue(selectStaffModalSlotIdAtom);
    const staffId = useAtomValue(selectStaffSelectedStaffIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    const { data: warnings } = useGetSlotStaffWarnings(slotId, staffId);
    const warningIndex = useAtomValue(confirmStaffWarningIndexAtom);

    if (!staffId || !warnings || warningIndex === null) return null;

    const firstName = staffById?.get(staffId)?.firstName;
    const warning = warnings[warningIndex];

    switch (warning?.type) {
        case 'OVERTIME':
            return (
                <Typography variant="h5" sx={{ textAlign: 'center', textWrap: 'balance' }}>
                    {firstName} will be{' '}
                    <span style={{ color: palette.error[600] as string }}>{warning.overtimeHours} hrs overtime</span>{' '}
                    with this shift. Are you sure?
                </Typography>
            );
        case 'CALL_OFF':
            return (
                <Typography variant="h5" sx={{ textAlign: 'center', textWrap: 'balance' }}>
                    {firstName}{' '}
                    <span style={{ color: palette.error[600] as string }}>
                        calls off {(warning.callOffRate * 100).toFixed(0)}%
                    </span>{' '}
                    of the time on this shift. Are you sure?
                </Typography>
            );
        default:
            return null;
    }
};

const ConfirmStaffActions = () => {
    const slotId = useAtomValue(selectStaffModalSlotIdAtom);
    const toggle = useSetAtom(isConfirmStaffModalOpenAtom);

    const [staffId, setStaffId] = useAtom(selectStaffSelectedStaffIdAtom);
    const toggleSelectStaffModal = useSetAtom(isSelectStaffModalOpenAtom);

    const { data: warnings } = useGetSlotStaffWarnings(slotId, staffId);
    const [warningIndex, setWarningIndex] = useAtom(confirmStaffWarningIndexAtom);

    const { mutateAsync: changeSlotStaff, isPending } = useChangeSlotStaff();

    if (!slotId || !staffId || !warnings || warningIndex === null) return null;

    return (
        <Stack width="100%" spacing="8px">
            <Button
                variant="outlined"
                color="success"
                size="large"
                onClick={() => {
                    setStaffId(null);
                    toggle();
                }}
                disabled={isPending}
                fullWidth
            >
                Find Another Staff
            </Button>
            <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={async () => {
                    if (warningIndex === warnings.length - 1) {
                        await changeSlotStaff({ slotId, data: { staffId } });
                        toggleSelectStaffModal();
                        toggle();
                    } else setWarningIndex(warningIndex + 1);
                }}
                disabled={isPending}
                fullWidth
            >
                {isPending ? <CircularProgress size={24} thickness={4} sx={{ color: 'error.100' }} /> : "Yes, I'm Sure"}
            </Button>
        </Stack>
    );
};

const ConfirmStaffModal = () => {
    const [isOpen, toggle] = useAtom(isConfirmStaffModalOpenAtom);

    return (
        <CustomModal isOpen={isOpen} onClose={toggle}>
            <Stack alignItems="center" p="32px" spacing="32px">
                <ConfirmStaffIcon />
                <ConfirmStaffMessage />
                <ConfirmStaffActions />
            </Stack>
        </CustomModal>
    );
};

export default ConfirmStaffModal;
