import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query-v4';
import { AxiosError } from 'axios';

import { useAppDispatch } from '~/constants/redux';
import { throwError } from '~/redux/actions/messages';

type QueryFn<T> = () => Promise<T>;

export function useErrorHandledQuery<T>(
    queryKey: QueryKey,
    queryFn: QueryFn<T>,
    options?: UseQueryOptions<T>,
    showAlertOnError = true
): UseQueryResult<T> {
    const dispatch = useAppDispatch();

    const newQueryFn = async () => {
        try {
            const data = await queryFn();
            return data;
        } catch (error: unknown) {
            const customError = error as AxiosError;

            dispatch(throwError(customError, showAlertOnError));
            throw customError;
        }
    };

    return useQuery(queryKey, newQueryFn, {
        retry: false,
        ...options,
    });
}
