import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

import { useCreateAgencyStaff } from '~/scheduling/api/queries/agency-staff/createAgencyStaff';
import { useChangeSlotStaff } from '~/scheduling/api/queries/shift-slot/changeSlotStaff';

import SegmentedModal from '../../../../../../../components/SegmentedModal';
import {
    addAgencyStaffNameAtom,
    isAddAgencyStaffModalOpenAtom,
    isSelectStaffModalOpenAtom,
    selectStaffModalSlotIdAtom,
} from '../../../../atoms';

const CreateAgencyStaffParams = () => {
    const [agencyStaffName, setAddAgencyStaffName] = useAtom(addAgencyStaffNameAtom);

    return (
        <Stack spacing="4px">
            <Typography variant="body1" fontWeight={700}>
                Staff Name
            </Typography>
            <TextField
                variant="outlined"
                placeholder="Input here"
                value={agencyStaffName}
                onChange={(e) => setAddAgencyStaffName(e.target.value)}
                fullWidth
            />
        </Stack>
    );
};

const CreateAgencyStaffActions = () => {
    const slotId = useAtomValue(selectStaffModalSlotIdAtom);
    const toggle = useSetAtom(isAddAgencyStaffModalOpenAtom);
    const toggleSelectStaff = useSetAtom(isSelectStaffModalOpenAtom);
    const agencyStaffName = useAtomValue(addAgencyStaffNameAtom);

    const { mutateAsync: createAgencyStaff, isPending: isCreateAgencyStaffPending } = useCreateAgencyStaff();
    const { mutateAsync: changeSlotStaff, isPending: isChangeSlotStaffPending } = useChangeSlotStaff();

    if (!slotId) return null;

    const isPending = isCreateAgencyStaffPending || isChangeSlotStaffPending;

    return (
        <>
            <Button variant="outlined" onClick={toggle} disabled={isPending}>
                Cancel
            </Button>
            <Button
                onClick={async () => {
                    const id = await createAgencyStaff({ data: { name: agencyStaffName! } });
                    await changeSlotStaff({ slotId, data: { agencyStaffId: id } });
                    toggle();
                    toggleSelectStaff();
                }}
                disabled={!agencyStaffName || isPending}
            >
                {isPending ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Confirm'}
            </Button>
        </>
    );
};

const CreateAgencyStaffModal = () => {
    const [isOpen, toggle] = useAtom(isAddAgencyStaffModalOpenAtom);

    return (
        <SegmentedModal
            isOpen={isOpen}
            onClose={toggle}
            header="Add Agency Staff"
            actions={<CreateAgencyStaffActions />}
        >
            <CreateAgencyStaffParams />
        </SegmentedModal>
    );
};

export default CreateAgencyStaffModal;
