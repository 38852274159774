import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

export const TaskListIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            d="M12.745 8.88H17.995"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.755 8.88L7.505 9.63L9.755 7.38"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.745 15.88H17.995"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.755 15.88L7.505 16.63L9.755 14.38"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.375 22H15.375C20.375 22 22.375 20 22.375 15V9C22.375 4 20.375 2 15.375 2H9.375C4.375 2 2.375 4 2.375 9V15C2.375 20 4.375 22 9.375 22Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Base>
);
