import { Stack } from '@mui/material';
import React from 'react';

import { LayoutBackground } from '../../components/LayoutBackground';

import Alerts from './components/Alerts';
import Header from './components/Header';
import PrintSchedule from './components/Modal/PrintScheduleModal';
import PublishNewScheduleModal from './components/Modal/PublishNewScheduleModal';
import ReviewOpenShiftsModal from './components/Modal/ReviewOpenShiftsModal';
import StaffVacationModal from './components/Modal/StaffVacationModal';
import QuickActions from './components/QuickActions';
import BudgetTable from './components/Table/BudgetTable';
import OpenShiftTable from './components/Table/OpenShiftTable';

const Dashboard = () => (
    <Stack position="relative" height="100%">
        <Header />
        <LayoutBackground>
            <Alerts />
            <QuickActions />
            <OpenShiftTable />
            <BudgetTable />
        </LayoutBackground>

        {/* TODO - change to the real behavior when the backend API is ready */}
        <ReviewOpenShiftsModal />
        <PublishNewScheduleModal />
        <StaffVacationModal />
        <PrintSchedule />
    </Stack>
);

export default Dashboard;
