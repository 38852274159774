/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material';
import { ArrowUUpLeft, UserPlus } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime, Interval } from 'luxon';
import React, { useMemo } from 'react';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetTeams } from '~/scheduling/api/queries/teams/getTeams';
import { useWeekInterval } from '~/scheduling/useWeekInterval';

import TeamSelector from '../../../../components/TeamSelector';
import { VerticalSeparator } from '../../../../components/shared';
import { SELECTOR_ALL_ID } from '../../../../constants';
import { isAddShiftModalOpenAtom, selectedCareTypeIdAtom, selectedPeriodAtom } from '../atoms';
import { SchedulePeriod } from '../types';

const periodOptions: { value: SchedulePeriod; label: string }[] = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
];

const formatPeriod = ({ start, end }: Interval<true>) => {
    const formatStart = start.toFormat('MMM d');
    const formatEnd = end.toFormat(start.month === end.month ? 'd, yyyy' : 'MMM d, yyyy');
    return `${formatStart} - ${formatEnd}`; // e.g. "Jan 1 - 7, 1970" or "Jan 31 - Feb 6, 1970"
};

const HeaderContainer = styled(Stack)(({ theme: { palette } }) => ({
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px 24px',
    borderBottom: `1px solid`,
    borderColor: palette.grey[50],
    zIndex: 10,
}));

const HeaderSection = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
});

const ItemSection = styled(Stack)({
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
});

const JumpButton = () => {
    const { setWeekInterval } = useWeekInterval();

    return (
        <ItemSection
            onClick={() => setWeekInterval(DateTime.now())}
            // A text button didn't work nicely here because padding and icon size are non-standard
            sx={({ palette }) => ({
                px: '8px',
                ml: '-8px',
                cursor: 'pointer',
                userSelect: 'none',
                borderRadius: '8px',
                '&:hover': { bgcolor: palette.grey[50] },
                '&:active': { bgcolor: palette.grey[100] },
            })}
        >
            <ArrowUUpLeft weight="bold" />
            <Typography variant="body1" fontWeight={600}>
                Jump to this week
            </Typography>
        </ItemSection>
    );
};

const LocationSelector = () => {
    const [selectedCareTypeId, setSelectedCareTypeId] = useAtom(selectedCareTypeIdAtom);

    const { data: locationData } = useGetLocations();
    const locations = locationData?.locations ?? [];

    const locationOptions = useMemo(
        () => [
            { value: SELECTOR_ALL_ID, label: 'All' },
            ...locations
                .map(({ id, abbreviation }) => ({ value: id, label: abbreviation }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        ],
        [locations]
    );

    return (
        <ToggleButtonGroup
            value={selectedCareTypeId}
            onChange={(_, locationId: number) => locationId && setSelectedCareTypeId(locationId)}
            exclusive
        >
            {locationOptions.map(({ value, label }) => (
                <ToggleButton key={value} value={value}>
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const PeriodSelector = () => {
    const [selectedPeriod, setSelectedPeriod] = useAtom(selectedPeriodAtom);

    return (
        <ToggleButtonGroup
            value={selectedPeriod}
            onChange={(_, period: SchedulePeriod) => period && setSelectedPeriod(period)}
            exclusive
        >
            {periodOptions.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const Header = () => {
    const { weekInterval } = useWeekInterval();

    const { data: teamData, isPending: isTeamsPending } = useGetTeams();
    const teams = teamData?.teams;

    const { data: locationData, isPending: isLocationsPending } = useGetLocations();
    const locations = locationData?.locations;

    const now = DateTime.now();
    const isCurrentWeek = weekInterval?.contains(now);

    const selectedPeriod = useAtomValue(selectedPeriodAtom);

    const toggleAddShiftModal = useSetAtom(isAddShiftModalOpenAtom);

    return (
        <HeaderContainer>
            <HeaderSection>
                {!weekInterval ? (
                    <Skeleton width="160px" height="36px" />
                ) : (
                    <>
                        <Typography variant="h6">{formatPeriod(weekInterval)}</Typography>
                        {!isCurrentWeek && (
                            <>
                                <VerticalSeparator height="20px" />
                                <JumpButton />
                            </>
                        )}
                    </>
                )}
            </HeaderSection>

            <HeaderSection>
                <ItemSection>
                    {isTeamsPending ? (
                        <Skeleton width="120px" height="36px" />
                    ) : (
                        teams && teams.length > 1 && <TeamSelector />
                    )}

                    {isLocationsPending ? (
                        <Skeleton width="120px" height="36px" />
                    ) : (
                        locations && locations.length > 1 && <LocationSelector />
                    )}

                    {/* TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1291 */}
                    {/* <PeriodSelector /> */}
                </ItemSection>

                {/* TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1291 */}
                {/* <VerticalSeparator height="20px" />
                
                {selectedPeriod === 'day' ? (
                    <Button size="small" startIcon={<UserPlus weight="bold" />} onClick={toggleAddShiftModal}>
                        Add Shift
                        </Button>
                        ) : (
                            <Button
                            size="small"
                            startIcon={<UserPlus weight="bold" />}
                            onClick={() => {}} // TODO: Implement notify open shifts
                            sx={{ fontSize: '12px' }}
                            >
                            Notify Open Shifts
                            </Button>
                            )} */}
            </HeaderSection>
        </HeaderContainer>
    );
};

export default Header;
