import { Button, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

import langDictionary from '~/app-strings';
import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';
import TranscriptionTextField from '~/components/Shared/TranscriptionTextField';
import { pxToRem } from '~/components/theme/typography';
import { ADD_COMMENT_DIALOG_ANALYTICS } from '~/constants/analytics';

type Props = {
    isOpen: boolean;
    taskNote: string;
    onClose: () => void;
    onSubmit: (taskNote: string) => void;
};

export const AddTaskNotesDialog = (props: Props) => {
    const { isOpen, taskNote, onClose, onSubmit } = props;

    const isMobile = useMediaQuery('(max-width:1024px)');

    const [notes, setNotes] = useState<string>(taskNote);

    const [showActivateMicConfirmation, setShowActivateMicConfirmation] = useState<boolean>(false);

    const onSubmitHandler = () => onSubmit(notes);

    const handleNoteChange = (newNote: string) => {
        setNotes(newNote);
    };

    const {
        addTaskNoteDialogTitle: addTaskNoteDialogTitleTxt,
        addTaskNoteFieldTitle: addTaskNoteFieldTitleTxt,
        addTaskNoteFieldPlaceholder: addTaskNoteFieldPlaceholderTxt,
        addTaskNoteSubmitButton: addTaskNoteSubmitButtonTxt,
    } = langDictionary.home;

    // since the component doesn't get re-rendered when it opens and
    // closes we have to force it to reset it's value on open/close so we don't get a stale value
    useEffect(() => {
        setNotes(taskNote);
    }, [taskNote]);

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={showActivateMicConfirmation ? '' : addTaskNoteDialogTitleTxt}
            width="100%"
            onClose={() => {
                onClose();
                setShowActivateMicConfirmation(false);
            }}
            content={
                <>
                    <TranscriptionTextField
                        label={addTaskNoteFieldTitleTxt}
                        placeholder={addTaskNoteFieldPlaceholderTxt}
                        showActivateMicConfirmation={showActivateMicConfirmation}
                        text={notes}
                        analyticsIdText={ADD_COMMENT_DIALOG_ANALYTICS.TRANSCRIPTION_BUTTON}
                        onChange={handleNoteChange}
                        toggleShowActivateMicConfirmation={(show: boolean) => setShowActivateMicConfirmation(show)}
                    />
                    {!showActivateMicConfirmation && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmitHandler}
                            sx={
                                isMobile
                                    ? {
                                          width: '100%',
                                          padding: pxToRem(12),
                                          fontSize: pxToRem(14),
                                      }
                                    : {}
                            }
                        >
                            {addTaskNoteSubmitButtonTxt}
                        </Button>
                    )}
                </>
            }
        />
    );
};
