import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { SHIFT_SLOT_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { GetLocationsResult, useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { FullScheduleSlot, useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { GetRolesResult, useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useWeekInterval } from '~/scheduling/useWeekInterval';

import { WeekGridSchema } from './types';

// The week grid is rendered with a matrix of roles and shift slots exactly as they appear on screen.
// This preparates the data for the week grid by grouping slots by shift/role/location/day ASC.
const shiftSlotsToWeekShifts = ({
    shiftRoles,
    shiftRoleShiftById,
    shiftLocations,
    shiftSlots,
    shiftDays,
}: {
    shiftRoles: GetRolesResult['roles'];
    shiftRoleShiftById: GetRolesResult['roleShiftById'];
    shiftLocations: GetLocationsResult['locations'];
    shiftSlots: FullScheduleSlot[];
    shiftDays: DateTime[];
}): WeekGridSchema.Shift[] => {
    // Buffer shifts grid creation, as they're split by shift/role/location. Even if two roles have separate
    // AM shifts, for example, they should still be displayed together, since they have the same 0 index.
    const shiftByIndex: Record<number, WeekGridSchema.Shift> = {};
    const previewDays = Array<boolean>(7).fill(false);

    const compare = (a: number | string, b: number | string) => (a < b ? -1 : a > b ? 1 : 0);

    // Group shifts, then roles, then locations, then days
    const sortedShiftSlots = shiftSlots.sort(
        (a, b) =>
            compare(a.roleShiftId, b.roleShiftId) ||
            compare(a.roleId, b.roleId) ||
            compare(a.locationId, b.locationId) ||
            compare(a.shiftDay, b.shiftDay)
    );

    let currentShiftId: number;
    let currentRoleId: number;
    let currentLocationId: number;
    let currentNumberOfRows: number;
    let currentSlotsByDay: Record<string, FullScheduleSlot[]>;

    // Transform current shift/role/location combination for the
    // given days into the grid representation and flush them
    const flushCurrent = () => {
        if (!currentShiftId) return;

        // Transform shift (create if not exists)
        const newShift = shiftRoleShiftById.get(currentShiftId)!;
        const foundShift = (shiftByIndex[newShift.index] ??= {
            index: newShift.index,
            name: newShift.name,
            roles: [],
            days: [],
        });

        // Transform role (match group/name/location)
        const newShiftRoleGroup = shiftRoles.findIndex(({ id }) => id === currentRoleId);
        const newShiftRoleName = shiftRoles[newShiftRoleGroup].name;
        const newShiftLocation = shiftLocations.find(({ id }) => id === currentLocationId)!.abbreviation;
        const newShiftRole = { group: newShiftRoleGroup, name: newShiftRoleName, location: newShiftLocation };
        const newShiftRoles = Array<WeekGridSchema.Role>(currentNumberOfRows).fill(newShiftRole);
        foundShift.roles.push(...newShiftRoles);

        // Transform days (fill non-existing slots as blank cells)
        const shiftDaySlots = Object.values(currentSlotsByDay).map((slots) => [
            ...Array<FullScheduleSlot | null>(currentNumberOfRows)
                .fill(null)
                .map((_, i) => slots[i] ?? null),
        ]);
        shiftDaySlots.forEach((daySlots, i) => {
            const day = (foundShift.days[i] ??= { day: shiftDays[i], slots: [], isPreview: false });
            day.slots.push(...daySlots);

            if (daySlots.some((slot) => slot?.status === SHIFT_SLOT_STATUS.DRAFT)) previewDays[i] = true;
        });
    };

    const resetCurrent = (roleShiftId: number, roleId: number, locationId: number) => {
        currentShiftId = roleShiftId;
        currentRoleId = roleId;
        currentLocationId = locationId;
        currentNumberOfRows = 0;
        currentSlotsByDay = Object.fromEntries(shiftDays.map((day) => [day.toFormat('yyyy-MM-dd'), []]));
    };

    // Split shift slots into correct days and calculate row count
    sortedShiftSlots.forEach((slot) => {
        const { shiftDay, roleId, roleShiftId, locationId } = slot;

        if (currentRoleId !== roleId || currentShiftId !== roleShiftId || currentLocationId !== locationId) {
            flushCurrent();
            resetCurrent(roleShiftId, roleId, locationId);
        }

        const currentDay = currentSlotsByDay[shiftDay];
        if (!currentDay) return; // Day should not be rendered

        currentNumberOfRows = Math.max(currentNumberOfRows, currentDay.push(slot)); // Update this shift's row count
    });

    flushCurrent(); // For last shift/role/location combination

    const parsedShifts = Object.values(shiftByIndex);

    // If a single slot is a draft, the whole day is considered a preview
    parsedShifts.forEach((shift) => shift.days.forEach((day, i) => (day.isPreview = previewDays[i])));

    return parsedShifts;
};

export const useWeekShifts = () => {
    const { weekDays } = useWeekInterval();

    const { data: roleData, isPending: isRoleDataPending } = useGetRoles();
    const roles = roleData?.roles;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData, isPending: isLocationDataPending } = useGetLocations();
    const locations = locationData?.locations;

    const { data: fullScheduleData, isPending: isFullSchedulePending } = useGetFullSchedule();
    const slots = fullScheduleData?.slots;

    const isPending = isRoleDataPending || isLocationDataPending || isFullSchedulePending;

    const weekShifts = useMemo(() => {
        if (!roles?.length || !roleShiftById?.size || !locations?.length || !slots?.length || !weekDays?.length)
            return [];

        return shiftSlotsToWeekShifts({
            shiftRoles: roles,
            shiftRoleShiftById: roleShiftById,
            shiftLocations: locations,
            shiftSlots: slots,
            shiftDays: weekDays,
        });
    }, [roles, roleShiftById, locations, slots, weekDays]);

    return { weekShifts, isPending };
};
