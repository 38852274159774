import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetAgencyStaffList } from '~/scheduling/api/types/agency-staff/getAgencyStaffList';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetAgencyStaffListResult = NonNullable<ReturnType<typeof useGetAgencyStaffList>['data']>;

export const useGetAgencyStaffList = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetAgencyStaffList.Response>(`/scheduling/${branchId}/agency-staff`, options);

        const agencyStaffList = data.response;
        const agencyStaffById = new Map(agencyStaffList.map((staff) => [staff.id, staff]));

        return { agencyStaffList, agencyStaffById };
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['agency-staff', branchId], queryFn, staleTime: 300000 });
};
