import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAlert } from '~/scheduling/components/CustomAlert';

const handleError = (showAlert: ReturnType<typeof useAlert>['showAlert']) => (_error: unknown) => {
    const error = _error as AxiosError<{ error: string }>;

    const message =
        error.response?.data?.error || // AxiosError<{ error: string }> with standardized error field
        error.message || // Known client/network Error (with message)
        'An internal error occurred'; // Other unexpected/unknown Error (without message)

    showAlert('error', message);

    // Rethrow the error for react-query to handle that state
    throw error;
};

export const useErrorHandledQuery = <T>({ queryFn: _queryFn, ...options }: UseQueryOptions<T>) => {
    const { showAlert } = useAlert();

    // Intercept the error and show an alert
    const queryFn = () => (_queryFn as () => Promise<T>)().catch(handleError(showAlert));

    return useQuery({ queryFn, retry: false, ...options });
};

export const useErrorHandledMutation = <TVariables, TData>({
    onError: _onError,
    ...options
}: UseMutationOptions<TData, unknown, TVariables>) => {
    const { showAlert } = useAlert();

    // Extend the error and show an alert
    const onError = (_error: unknown, variables: TVariables, context: unknown) => {
        _onError?.(_error, variables, context);
        handleError(showAlert)(_error);
    };

    return useMutation({ onError, retry: false, ...options });
};
