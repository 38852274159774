import { Add as AddIcon } from '@mui/icons-material';
import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { subDays } from 'date-fns';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';

import { getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useAssignmentsQuery } from '~/api/queries/assignments';
import { useBranchShifts, withBranchShiftSelector } from '~/api/queries/branch';
import { useCompanyCarePlansListQuery } from '~/api/queries/companyCarePlans';
import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { useDailyTasks, useUpdateDailyTasks } from '~/api/queries/tasks/dailyTasks';
import langDictionary from '~/app-strings';
import notificationSound from '~/assets/notification-sound.mp3';
import { CustomTextField, CustomTabPanel as TabPanel } from '~/components/Custom';
import CustomConfetti from '~/components/Custom/CustomConfetti';
import { INPUT_STYLES, MOBILE_INPUT_STYLES } from '~/components/Custom/constants';
import { BranchSelector, ShiftSelector, ZoneSelector } from '~/components/Filtering';
import AssignmentSelector from '~/components/Filtering/AssignmentSelector';
import Loading from '~/components/Shared/Loading';
import SortButton from '~/components/Shared/Sorting/SortButton';
import SortDialog from '~/components/Shared/Sorting/SortDialog';
import { pxToRem } from '~/components/theme/typography';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { HOME_FILTERS } from '~/constants/localStorage';
import { getDateInUtc } from '~/lib/date';
import CheckTaskDialog from '~/pages/Home/components/CheckTaskDialog';
import TaskNotesDialog from '~/pages/Home/components/TaskNotesDialog';
import UnscheduledTaskDialog from '~/pages/Home/components/UnscheduledTaskDialog';
import PageStructure from '~/pages/PageStructure';
import { usePermissions } from '~/permissions/utils';
import { setSortBy, setSortOrder } from '~/redux/actions/filters';
import { hideAlert, showAlert } from '~/redux/actions/messages';
import { toggleResidentParty } from '~/redux/actions/residents';
import { readUser } from '~/redux/actions/users';
import { selectedAssignmentIdAtom } from '~/state/filteringAtoms';
import {
    CheckTaskMode,
    DailyTasksByTabStrict,
    DailyTasksShiftDetail,
    ResidentTasks,
    TaskToUpdate,
} from '~/types/dailyTasks';
import { MessageProps } from '~/types/messages';
import { ReduxStore } from '~/types/redux';
import { UserResponse } from '~/types/users';

import { ResidentRows as ConfirmedResidentRows } from './confirmedTaskListComponents/ResidentRows';
import {
    ALL_ZONES_ID,
    NO_RESIDENT_SELECTED_ID,
    filterTasksByName,
    groupTasksByResidentId,
    shouldUseAssignments,
} from './helpers';
import { ResidentRows as PendingResidentRows } from './taskListComponents/ResidentRows';

const TopFixedBar = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        backgroundColor: { xs: '#006B75', md: 'common.white' },
        position: { xs: 'fixed', md: 'unset' },
        top: pxToRem(48), // Avoid app bar
        left: 0,
        right: 0,
        zIndex: 2,
        pt: 'env(safe-area-inset-top)', // Avoid OS status bar
    })
);

const TopFiltersContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: 0,
        px: { xs: pxToRem(12), md: 0 },
        pt: { xs: pxToRem(8), md: 0 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', md: 'flex-end' },
        flexWrap: 'wrap',
        columnGap: { xs: 1, md: 2 },
    })
);

const TopFiltersRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        height: pxToRem(48),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: pxToRem(8),
    })
);

const TasksContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(80),
        position: 'relative',
        width: '100%',
    })
);

const AddButtonStyle = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        p: 0,
        borderRadius: '50%',
        minWidth: 'unset',
        width: pxToRem(72),
        height: pxToRem(72),
        position: 'fixed',
        right: 0,
        bottom: pxToRem(90),
        border: '2px solid #fff',
    })
);

const AddIconStyle = styled(AddIcon)(({ theme }) =>
    theme.unstable_sx({
        fontSize: pxToRem(30),
    })
);

type Props = {
    timezone: string;
    zoneId: number;
    sortBy: string;
    sortOrder: string;
    displayParty: boolean;
    user: UserResponse;
    selectedBranchId: number;
    setSelectedBranchId: (branchId: number) => void;
    dispatchShowAlert: (message: MessageProps) => void;
    dispatchHideAlert: () => void;
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
    dispatchToggleResidentParty: () => void;
    dispatchReadUser: () => void;
};

type TabProps = {
    isMobile: boolean;
    tabs: DailyTasksByTabStrict;
    selectedTab: number;
    handleTabChange: (event: SyntheticEvent, newValue: number) => void;
};

const TabSelector = (props: TabProps) => {
    const { isMobile, tabs, selectedTab, handleTabChange } = props;
    return (
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={
                isMobile
                    ? {
                          '& .MuiTab-root': {
                              color: '#ffffffd0',
                              fontSize: pxToRem(14),
                              lineHeight: 1.5,
                              '&.Mui-selected': {
                                  color: 'common.white',
                              },
                              mb: pxToRem(4),
                          },
                          '& .MuiTabs-indicator': {
                              backgroundColor: 'common.white',
                          },
                      }
                    : {
                          '& .MuiTab-root': {
                              lineHeight: 1.2,
                          },
                      }
            }
        >
            {tabs.map((tab, index) => {
                const { key, tabName, tasks } = tab;
                const isSelectedTab = index === selectedTab;
                return (
                    <Tab
                        key={key}
                        label={langDictionary.home[tabName]}
                        icon={
                            <Box
                                sx={{
                                    ...(isMobile
                                        ? {
                                              color: isSelectedTab ? '#006B75' : '#ffffffd0',
                                              backgroundColor: isSelectedTab ? 'common.white' : '#ffffff30',
                                          }
                                        : {
                                              color: isSelectedTab ? 'common.black' : 'unset',
                                              backgroundColor: isSelectedTab ? '#F4F4F5' : 'unset',
                                              border: '2px solid #F4F4F5',
                                          }),
                                    minWidth: pxToRem(32),
                                    px: pxToRem(8),
                                    borderRadius: pxToRem(16),
                                }}
                            >
                                {tasks.length}
                            </Box>
                        }
                        iconPosition="end"
                        sx={{
                            ...(isMobile ? { flex: 1 } : {}),
                            fontSize: pxToRem(14),
                            fontWeight: 700,
                            color: { xs: '#fff', md: 'common.black' },
                        }}
                    />
                );
            })}
        </Tabs>
    );
};
const Home = (props: Props) => {
    const {
        timezone,
        zoneId,
        sortBy,
        sortOrder,
        displayParty,
        user,
        selectedBranchId,
        setSelectedBranchId,
        dispatchShowAlert,
        dispatchHideAlert,
        dispatchSetSortBy,
        dispatchSetSortOrder,
        dispatchToggleResidentParty,
        dispatchReadUser,
    } = props;
    const { home, error: errorDictionary } = langDictionary;
    const queryParams = useMemo(() => new URLSearchParams(useLocation().search), [useLocation().search]);

    const { company, companyId, regionId, branch, branchId } = useSelector(
        (state: ReduxStore) => state.session.sessionData
    );

    const shifts = useBranchShifts(selectedBranchId);
    const updateTaskMutation = useUpdateDailyTasks();

    const {
        shift: { id: currentShiftId },
        shiftDayDate: currentShiftDayDate,
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const currentShiftDateTzMoment = moment(currentShiftDayDate);

    const [datepickerDate, setDatepickerDate] = useState<Moment>(currentShiftDateTzMoment);
    const [shift, setShift] = useState<number | null>(currentShiftId as number);
    // only select shift if it is a valid shift
    const isSelectedShiftValid =
        nearbyShifts.find(({ shift: { id: nearbyShiftId } }) => nearbyShiftId === shift) !== undefined;
    const selectedShiftId = shift && isSelectedShiftValid ? shift : (currentShiftId as number);

    // get shift id from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftId = queryParams.get('selected_shift_id');

        if (selectedShiftId && !isNaN(Number(selectedShiftId))) {
            setShift(Number(selectedShiftId));
        }
    }, [queryParams.get('selected_shift_id')]);

    // get shift day date from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftDate = queryParams.get('selected_shift_date');

        const momentDate = moment(selectedShiftDate);

        if (selectedShiftDate && momentDate.isValid()) {
            setDatepickerDate(momentDate);
        }
    }, [queryParams.get('selected_shift_date')]);

    const [selectedAssignmentId, setSelectedAssignmentId] = useAtom(selectedAssignmentIdAtom);
    const resetSelectedAssignmentId = useResetAtom(selectedAssignmentIdAtom);

    const [tasksByTab, setTasksByTab] = useState<DailyTasksByTabStrict>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [isAddedTaskDialogOpen, setIsAddedTaskDialogOpen] = useState<boolean>(false);
    const [isCheckTaskDialogOpen, setIsCheckTaskDialogOpen] = useState<boolean>(false);
    const [checkTaskDialogType] = useState<CheckTaskMode>('Complete');
    const [isTaskNotesDialogOpen, setIsTaskNotesDialogOpen] = useState<boolean>(false);
    const [selectedTaskNotes, setSelectedTaskNotes] = useState<string>();
    const [selectedResidentId, setSelectedResidentId] = useState<string>(NO_RESIDENT_SELECTED_ID);
    const [playCoinSound] = useSound(notificationSound);
    const [addedTaskDefaultResidentId, setAddedTaskDefaultResidentId] = useState<number>();
    const [isSortDialogOpen, setIsSortDialogOpen] = useState<boolean>(false);
    const [, setIsPhoneRequestDialogOpen] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const {
        data: branchesByRegionData,
        // isLoading: branchesByRegionIsLoading,
        // isError: branchesByRegionIsError,
    } = useBranchesByRegionQuery({ branchId, regionId, companyId });

    const branchesWithAccess = branchesByRegionData?.branches.filter((branch) => branch.hasAccess) ?? [];

    const hasPermission = usePermissions();

    const { data: dailyTasksList, isLoading: dailyTasksListIsLoading } = useDailyTasks({
        branchId: Number(selectedBranchId),
        date: datepickerDate.format('YYYY-MM-DD'),
    });

    const { data: carePlanData } = useCompanyCarePlansListQuery(branchId!);

    const carePlanIdToKeyService = useMemo(() => {
        const entries = carePlanData?.map(({ companyCarePlanId: id, keyService }) => [id, keyService]) ?? [];
        return Object.fromEntries(entries);
    }, [carePlanData]);

    const { data: assignments } = useAssignmentsQuery(branchId);

    const filteredAssignments = useMemo(
        () =>
            assignments?.filter(
                ({ branchShiftId, assignmentId }) =>
                    (!branchShiftId || branchShiftId === selectedShiftId) &&
                    dailyTasksList &&
                    dailyTasksList[selectedShiftId].some((task) => task.assignmentId === assignmentId) // Only show assignments that have tasks related for the shift
            ) ?? [],
        [assignments, selectedShiftId, dailyTasksList]
    );

    const assignmentOptions = useMemo(
        () => [
            {
                label: 'All',
                value: ALL_ASSIGNMENTS_ID, // Not ideal, but useful while we don't have nicer types for CustomSelect
            },
            ...filteredAssignments.map(({ assignmentId, assignmentName }) => ({
                label: assignmentName,
                value: assignmentId,
            })),
        ],
        [filteredAssignments]
    );

    const permissionError = () => {
        // Set the message to display into the alert.
        const message: MessageProps = {
            open: true,
            message: errorDictionary.onlyDirectorsCareGivers,
            alertSeverity: 'error',
            status: 400,
        };

        dispatchShowAlert(message);
    };

    // TODO: Refactor this to only take numbers (AH-1021)
    const handleFilterChange = (filter: string) => (newValue: string | number) => {
        if (filter === 'assignmentId') setSelectedAssignmentId(Number(newValue));
        else resetSelectedAssignmentId();

        if (filter === 'branch') {
            setSelectedBranchId(Number(newValue));

            // Reset the shift id to use the current shift
            setShift(null);
        } else if (filter === 'shift') {
            setShift(Number(newValue));
        } else if (filter === 'resident') {
            setSelectedResidentId(newValue as string);
        } else {
            // when zone/assignment filter is changed unselect the currently selected resident
            setSelectedResidentId(NO_RESIDENT_SELECTED_ID);
        }
    };

    const handleAddTaskSubmit = () => {
        dispatchToggleResidentParty();
        playCoinSound();
    };

    const handleAddedTaskDialogOpen = () => {
        // If the user is not a Caregiver, throw an error and exit the function.
        if (!hasPermission('Community', 'undertake-resident-action')) {
            permissionError();
            return;
        }

        setIsAddedTaskDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsAddedTaskDialogOpen(false);
        setIsCheckTaskDialogOpen(false);
        setIsTaskNotesDialogOpen(false);
        setSelectedTaskNotes('');
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCompleteTaskClick = async ({ task }: { task: TaskToUpdate }) => {
        // If the user is not a allowed to perform this action, exit the function.
        if (!hasPermission('Community', 'update-resident-action')) {
            return;
        }

        // Trigger the action for updating the Task as Complete.
        await updateTaskMutation.mutateAsync({ tasks: [task], branchId: Number(selectedBranchId) });

        if (hasPermission('Community', 'update-reward') && task.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }
    };

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const togglePhoneRequestDialog = () => setIsPhoneRequestDialogOpen((prevState) => !prevState);

    const taskRecordSorter = (a: DailyTasksShiftDetail, b: DailyTasksShiftDetail) => {
        const aDateTime = moment(`${a.taskDate}T${a.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        const bDateTime = moment(`${b.taskDate}T${b.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        if (aDateTime.isBefore(bDateTime)) {
            return -1;
        }

        if (aDateTime.isAfter(bDateTime)) {
            return 1;
        }

        return 0;
    };

    const handleSortButtonClick = () => {
        setIsSortDialogOpen(true);
    };

    const handleSortDialogClose = () => {
        setIsSortDialogOpen(false);
    };

    const handleSortOptions = (selectedSortBy: string, selectedSortOrder: string) => {
        if (sortBy !== selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }
        if (sortOrder !== selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    const hasTaskWithKeyService = (tasks: DailyTasksShiftDetail[], keyService: string): boolean =>
        tasks.some(({ companyCarePlanId }) => carePlanIdToKeyService[companyCarePlanId] === keyService);

    const sortByShowerAndLaundry = (residentsTasksList: ResidentTasks[], isAscending: boolean): ResidentTasks[] => {
        const sortedArray = orderBy(
            residentsTasksList,
            [
                (item) => hasTaskWithKeyService(item.tasks, 'SHOWERING'),
                (item) => hasTaskWithKeyService(item.tasks, 'LAUNDRY'),
            ],
            [isAscending ? 'desc' : 'asc', isAscending ? 'desc' : 'asc']
        );

        return sortedArray;
    };

    const handleSort = (
        residentsTasksList: ResidentTasks[],
        selectedSortBy: keyof ResidentTasks | 'shower&Laundry',
        isAscending: boolean
    ): ResidentTasks[] => {
        if (selectedSortBy === 'shower&Laundry') {
            return sortByShowerAndLaundry(residentsTasksList, isAscending);
        }

        if (selectedSortBy === 'roomNumber') {
            return orderBy(residentsTasksList, (item) => Number(item[selectedSortBy]), isAscending ? 'asc' : 'desc');
        }

        return orderBy(residentsTasksList, selectedSortBy, isAscending ? 'asc' : 'desc');
    };

    useEffect(() => {
        dispatchReadUser();
    }, []);

    useEffect(() => {
        if (!user?.userId || !hasPermission('Community', 'update-reward')) return;
        if (user?.phoneNumberConfirmed === false) {
            togglePhoneRequestDialog();
        }
    }, [user?.userId, user?.phoneNumberConfirmed]);

    useEffect(() => {
        if (selectedShiftId) {
            const newTasksToShow: DailyTasksShiftDetail[] =
                dailyTasksList && dailyTasksList[selectedShiftId]?.length
                    ? cloneDeep(dailyTasksList[selectedShiftId])
                          .filter((task) =>
                              shouldUseAssignments(company, branch)
                                  ? selectedAssignmentId === ALL_ASSIGNMENTS_ID ||
                                    task.assignmentId === selectedAssignmentId
                                  : zoneId === ALL_ZONES_ID || task.zoneId === zoneId
                          )
                          .filter((task) => {
                              if (selectedResidentId === NO_RESIDENT_SELECTED_ID) {
                                  return true;
                              }

                              return task.resident.id.toString() === selectedResidentId;
                          })
                    : [];

            const pendingTasks = newTasksToShow.filter((task) => task.taskStatus === 'Undocumented');
            const confirmedTasks = newTasksToShow.filter((task) => task.taskStatus !== 'Undocumented');
            pendingTasks.sort(taskRecordSorter);
            confirmedTasks.sort(taskRecordSorter);

            setTasksByTab([
                {
                    key: 0,
                    tabName: 'pending',
                    tasks: pendingTasks,
                },
                {
                    key: 1,
                    tabName: 'nonPending',
                    tasks: confirmedTasks,
                },
            ]);

            // Update the filters into the localStorage.
            const newFilters = {
                date: datepickerDate.format('YYYY-MM-DD HH:mm:ss'),
                shift: selectedShiftId,
            };
            localStorage.setItem(HOME_FILTERS, JSON.stringify(newFilters));
        }
    }, [
        datepickerDate.format('YYYY-MM-DD'),
        selectedShiftId,
        selectedAssignmentId,
        zoneId,
        selectedBranchId,
        JSON.stringify(dailyTasksList),
        selectedResidentId,
    ]);

    useEffect(() => {
        if (dailyTasksListIsLoading) {
            // const message: MessageProps = {
            //     open: true,
            //     message: 'Loading',
            //     alertSeverity: 'info',
            //     status: 200,
            // };
            // dispatchShowAlert(message);
        } else {
            dispatchHideAlert();
        }
    }, [dailyTasksListIsLoading]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // HACK ASCENT: not showing loading so the page doesn't scroll to the top again immediately
    if (!currentShiftDateTzMoment || (hasPermission('Community', 'undertake-resident-action') && !selectedBranchId)) {
        return <Loading />;
    }

    const selectedDate = datepickerDate.format('YYYY-MM-DD');

    const handleDateChange = (newDate: Date | null) => {
        const newDateMoment = moment(newDate);
        setDatepickerDate(newDateMoment);
    };

    const getDateInputProps = (date: Date) => {
        const selectedDate = moment(date).format('MM/DD/YYYY');
        return {
            InputProps: {
                value: selectedDate === currentShiftDateTzMoment.format('MM/DD/YYYY') ? 'Today' : selectedDate,
            },
        };
    };

    const renderBranchSelector =
        hasPermission('Community', 'read-all-resident-actions') && branchesWithAccess.length > 1;

    return (
        <PageStructure>
            {/* DEBUG */}
            {/* <Typography variant="subtitle1">Distance: {distance}</Typography> */}
            <TopFixedBar>
                <TopFiltersContainer>
                    {renderBranchSelector && (
                        <BranchSelector
                            value={Number(selectedBranchId)}
                            options={branchesWithAccess.map((branch) => ({
                                label: branch.branchName,
                                value: branch.branchId.toString(),
                            }))}
                            onChange={(newValue) => handleFilterChange('branch')(newValue.toString())}
                            isHeader
                            fullWidth
                        />
                    )}
                    <TopFiltersRow
                        sx={{
                            mt: { xs: pxToRem(2), md: pxToRem(10) },
                            mb: { xs: pxToRem(4), md: pxToRem(8) },
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box
                                sx={{
                                    width: '100%',
                                    mb: pxToRem(8),
                                    ...(isMobile ? MOBILE_INPUT_STYLES : INPUT_STYLES),
                                }}
                            >
                                <MobileDatePicker
                                    label={home.date}
                                    format="MM/dd/yyyy"
                                    value={datepickerDate.toDate()}
                                    onChange={handleDateChange}
                                    minDate={
                                        hasPermission('Community', 'read-all-resident-actions')
                                            ? undefined
                                            : subDays(new Date(), 3) // For caregivers, from AH-784
                                    }
                                    maxDate={currentShiftDateTzMoment.toDate()}
                                    slotProps={{
                                        textField: (params) => getDateInputProps(params.value),
                                    }}
                                />
                            </Box>
                        </LocalizationProvider>
                        <Box sx={{ width: '100%' }}>
                            <ShiftSelector
                                width="100%"
                                selectedShiftId={selectedShiftId}
                                currentShiftId={
                                    currentShiftDateTzMoment.isSame(datepickerDate, 'day')
                                        ? (currentShiftId as number)
                                        : undefined
                                }
                                shiftOptions={nearbyShifts}
                                fullWidth
                                onChange={handleFilterChange('shift')}
                            />
                        </Box>
                    </TopFiltersRow>
                    <TopFiltersRow
                        sx={{
                            mb: { xs: pxToRem(4), md: pxToRem(8) },
                        }}
                    >
                        <CustomTextField
                            label={'Resident Name/Apt'}
                            placeholder={'Type by Name/Apt'}
                            value={searchValue}
                            fullWidth
                            startAdornment={<SearchIcon htmlColor="#DEDEE0" />}
                            onChange={setSearchValue}
                            sx={isMobile ? MOBILE_INPUT_STYLES : INPUT_STYLES}
                        />
                    </TopFiltersRow>
                    <TopFiltersRow>
                        {shouldUseAssignments(company, branch) ? (
                            <AssignmentSelector
                                options={assignmentOptions}
                                value={selectedAssignmentId}
                                onChange={handleFilterChange('assignmentId')}
                                fullWidth
                                disabled={!filteredAssignments.length}
                            />
                        ) : (
                            <ZoneSelector
                                width="100%"
                                branchId={Number(selectedBranchId)}
                                fullWidth
                                onChange={handleFilterChange('zoneId')}
                            />
                        )}
                        <Box sx={{ width: '40%', mb: pxToRem(8) }}>
                            <SortButton onClick={handleSortButtonClick} />
                        </Box>
                    </TopFiltersRow>
                </TopFiltersContainer>
                {isMobile && (
                    <TabSelector
                        isMobile={isMobile}
                        tabs={tasksByTab}
                        selectedTab={selectedTab}
                        handleTabChange={handleTabChange}
                    />
                )}
            </TopFixedBar>
            {isMobile && (
                <Box height={196 + (renderBranchSelector ? 56 : 0)} /> // Spacer for the fixed header
            )}
            {isEmpty(tasksByTab) ? (
                <Typography variant="subtitle1">{home.noTasks}</Typography>
            ) : (
                <>
                    {!isMobile && (
                        <TabSelector
                            isMobile={isMobile}
                            tabs={tasksByTab}
                            selectedTab={selectedTab}
                            handleTabChange={handleTabChange}
                        />
                    )}
                    <TasksContainer>
                        {!!branchId &&
                            tasksByTab.map(({ key, tasks }) => {
                                const filteredTasks = filterTasksByName({
                                    zoneId,
                                    tasks,
                                    searchValue,
                                    assignmentId: selectedAssignmentId,
                                    filterBy: shouldUseAssignments(company, branch) ? 'assignmentId' : 'zoneId',
                                });

                                const residentsTasks = groupTasksByResidentId(filteredTasks);

                                const residentsTasksList = Object.values(residentsTasks);

                                const defaultResidentsTasksList = orderBy(
                                    residentsTasksList,
                                    (item) => Number(item.roomNumber),
                                    sortOrder === 'asc' ? 'asc' : 'desc'
                                );

                                const residentsTasksListSorted = handleSort(
                                    defaultResidentsTasksList,
                                    sortBy as keyof ResidentTasks | 'shower&Laundry',
                                    sortOrder === 'asc'
                                );

                                return (
                                    <TabPanel key={key} value={selectedTab} index={key}>
                                        {selectedTab === 0 ? (
                                            <PendingResidentRows
                                                residentTasksList={residentsTasksListSorted}
                                                date={selectedDate}
                                                shift={selectedShiftId}
                                                branchId={branchId}
                                                setSelectedResidentId={setAddedTaskDefaultResidentId}
                                            />
                                        ) : (
                                            <ConfirmedResidentRows
                                                residentTasksList={residentsTasksListSorted}
                                                branchId={branchId}
                                            />
                                        )}
                                    </TabPanel>
                                );
                            })}
                    </TasksContainer>
                </>
            )}
            {hasPermission('Community', 'undertake-resident-action') && (
                <AddButtonStyle variant="contained" color="primary" onClick={handleAddedTaskDialogOpen}>
                    <AddIconStyle />
                </AddButtonStyle>
            )}
            <UnscheduledTaskDialog
                isOpen={isAddedTaskDialogOpen}
                defaultResidentId={addedTaskDefaultResidentId}
                timezone={timezone}
                currentShift={selectedShiftId}
                onSubmit={handleAddTaskSubmit}
                onClose={handleCloseDialog}
                canChangeResident
            />
            <CheckTaskDialog
                isOpen={isCheckTaskDialogOpen}
                dialogType={checkTaskDialogType}
                onSubmit={handleCompleteTaskClick}
                onClose={handleCloseDialog}
            />
            <TaskNotesDialog isOpen={isTaskNotesDialogOpen} taskNote={selectedTaskNotes} onClose={handleCloseDialog} />
            <SortDialog
                isOpen={isSortDialogOpen}
                onClose={handleSortDialogClose}
                onSort={handleSortOptions}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <CustomConfetti activate={displayParty} stop={dispatchToggleResidentParty} />
            {/* TODO: uncomment when complete */}
            {/* <Onboarding /> */}
        </PageStructure>
    );
};

const mapStateToProps = ({ session, filters, residents, users }) => {
    const { timezone } = session;
    const { displayParty } = residents;
    const {
        caregiverApp: { zoneId, sortBy, sortOrder },
    } = filters;
    const { user } = users;

    return {
        timezone,
        zoneId,
        sortBy,
        sortOrder,
        displayParty,
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchShowAlert: (message: MessageProps) => dispatch(showAlert(message)),
    dispatchHideAlert: () => dispatch(hideAlert()),
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) => dispatch(setSortOrder(sortOrder)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
    dispatchReadUser: () => dispatch(readUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withBranchShiftSelector(Home));
