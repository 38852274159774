import { pxToRem } from '~/components/theme/typography';

export default function BottomNavigation(theme) {
    const { palette } = theme;
    return {
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.navigation.background,
                    width: '100%',
                    '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
                        color: palette.navigation.unselected,
                        fontSize: pxToRem(24),
                        ['@media (max-width: 314px)']: {
                            paddingTop: pxToRem(12),
                            fontSize: pxToRem(16),
                            justifyContent: 'flex-start',
                            minWidth: pxToRem(64),
                        },
                        '&.Mui-selected': {
                            color: palette.navigation.selected,
                        },
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: pxToRem(9),
                        },
                    },
                },
            },
        },
    };
}
