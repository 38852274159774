import { Button, ButtonGroup } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

type ButtonProps = {
    title: string;
    isSelected?: boolean;
    action?: () => void;
};

type SortButtonGroupProps = {
    buttons: ButtonProps[];
    sx?: SxProps;
};

const SortButtonGroup = ({ buttons, sx }: SortButtonGroupProps) => (
    <ButtonGroup
        variant="outlined"
        fullWidth
        sx={{
            minHeight: pxToRem(48),
            ...sx,
        }}
    >
        {buttons.map(({ title, isSelected, action }) => (
            <Button
                variant={isSelected ? 'contained' : 'outlined'}
                onClick={action}
                sx={{
                    color: isSelected ? '' : 'grey.700',
                    borderColor: isSelected ? '' : 'grey.400',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                }}
                key={title}
            >
                {title}
            </Button>
        ))}
    </ButtonGroup>
);

export default SortButtonGroup;
