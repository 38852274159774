import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetMyNotifications } from '~/scheduling/api/types/shift-slot/getMyNotifications';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

import { remapSlot } from './getMySchedule';

export type GetMyNotificationsResult = NonNullable<ReturnType<typeof useGetMyNotifications>['data']>;

export const useGetMyNotifications = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetMyNotifications.Response>(
            `/scheduling/${branchId}/shift-slot/my-notifications`,
            options
        );

        return data.response.map(remapNotification);
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['my-notifications', branchId], queryFn, staleTime: 30000 });
};

const remapNotification = ({ slot, ...notification }: GetMyNotifications.Notification) => ({
    ...notification,
    slot: remapSlot(slot),
});
