import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import langDictionary from '~/app-strings';
import { CustomSelect } from '~/components/Custom';
import { setCaregiverZoneId } from '~/redux/actions/filters';
import { readZones } from '~/redux/actions/zones';
import { ZoneResponse, ZonesListReadParams } from '~/types/zones';

import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from './constants';

type Props = {
    branchId: number;
    fullWidth?: boolean;
    width?: number | string;
    zonesList: ZoneResponse[];
    zoneId?: number;
    dispatchReadZones: (params: ZonesListReadParams) => void;
    dispatchSetCaregiverZoneId: (zoneId: number) => void;
    onChange?: (newZoneId: string) => void;
};

const ZoneSelectorFilter = (props: Props) => {
    const {
        branchId,
        fullWidth,
        width = '48%',
        zonesList,
        zoneId,
        dispatchReadZones,
        dispatchSetCaregiverZoneId,
        onChange,
    } = props;
    const { home } = langDictionary;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [currentBranchId, setCurrentBranchId] = useState<number>();

    const handleFilterChange = (newValue: string) => {
        dispatchSetCaregiverZoneId(parseInt(newValue, 10));
        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        if (branchId && branchId !== currentBranchId) {
            // Update the value for the current Branch ID.
            setCurrentBranchId(branchId);

            // Define the params for fetching the Zones list.
            const zonesParams = {
                branchId,
            };

            // Fetch the Zones list based on the Branch ID.
            dispatchReadZones(zonesParams);
        }
    }, [branchId, currentBranchId]);

    const zoneOptions = [
        {
            label: 'All',
            value: '-1',
        },
        ...zonesList.map((zone) => ({
            label: zone.zoneName,
            value: zone.zoneId.toString(),
        })),
    ];

    const currentZone = useMemo(
        () => zoneOptions.find(({ value }) => value === zoneId?.toString())?.value,
        [zoneId, zoneOptions]
    );

    useEffect(() => {
        if (zoneOptions?.length && (!zoneId || !currentZone)) {
            dispatchSetCaregiverZoneId(parseInt(zoneOptions[0].value, 10));
        }
    }, [zoneId, currentZone, zoneOptions]);

    return (
        <Box sx={{ width }}>
            <CustomSelect
                id="zone"
                label={home.zone}
                value={!zoneId || !currentZone ? '' : currentZone}
                options={zoneOptions}
                fullWidth={fullWidth}
                onChange={handleFilterChange}
                sx={isMobile ? { ...MOBILE_SELECTOR_STYLES, ...SELECTOR_STYLES } : SELECTOR_STYLES}
            />
        </Box>
    );
};

const mapStateToProps = ({ zones, filters }) => {
    const { zonesList } = zones;
    const {
        caregiverApp: { zoneId },
    } = filters;

    return {
        zonesList,
        zoneId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadZones: (params: ZonesListReadParams) => dispatch(readZones(params)),
    dispatchSetCaregiverZoneId: (zoneId: number) => dispatch(setCaregiverZoneId(zoneId)),
});

export const ZoneSelector = connect(mapStateToProps, mapDispatchToProps)(ZoneSelectorFilter);
