import { Box, IconButton, Link, Typography, styled } from '@mui/material';
import React from 'react';
import { MdClose, MdFlag, MdWarning } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetScheduledHours } from '~/scheduling/api/queries/reports/useGetScheduledHours';
import useDismissibleAlert from '~/scheduling/hooks/useDismissibleAlert';

import { usePeriod } from '../../usePeriod';

type AlertTypes = 'primary' | 'alert';

const AlertContainer = styled(Box)<{ type: AlertTypes }>(({ theme, type }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '16px',
    backgroundColor: type === 'primary' ? theme.palette.primary[50] : theme.palette.error[50],
    border: `1px solid ${type === 'primary' ? theme.palette.primary[100] : theme.palette.error[100]}`,
    borderRadius: '6px',
}));

const CloseIcon = styled(MdClose)(({ theme }) => ({
    color: theme.palette.grey[900],
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    flexShrink: 0,
}));

const WarningIcon = styled(MdWarning)(({ theme }) => ({
    color: theme.palette.error[600],
    fontSize: '20px',
    flexShrink: 0,
}));

const FlagIcon = styled(MdFlag)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontSize: '20px',
    flexShrink: 0,
}));

type AlertProps = {
    type: AlertTypes;
    textComponent: React.ReactNode;
    onClose?: () => void;
};

const Alert = (props: AlertProps) => (
    <AlertContainer type={props.type}>
        <Box display="flex" alignItems="center" gap="4px">
            {props.type === 'primary' ? <FlagIcon /> : <WarningIcon />}
            {props.textComponent}
        </Box>
        <IconButton color="secondary" aria-label="close" onClick={props.onClose}>
            <CloseIcon />
        </IconButton>
    </AlertContainer>
);

const Alerts = () => {
    const { data: communitySettings } = useGetCommunitySettings();
    const { period } = usePeriod();
    const { data } = useGetScheduledHours({ period, firstDayOfWeek: communitySettings?.firstDayOfWeek });

    const areScheduledHoursHigherThanBudgetedHours = data && data.scheduledHours > data.budgetedHours;

    const { show, closeAlert } = useDismissibleAlert('scheduled_hours');

    if (!show) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" gap="16px">
            {areScheduledHoursHigherThanBudgetedHours && (
                <Alert
                    type="alert"
                    onClose={closeAlert}
                    textComponent={
                        <Typography variant="body1" color="grey.900" fontWeight={400}>
                            Your budgeted hours are lower than your scheduled hours. Adjust the schedule in{' '}
                            <Link
                                component={RouterLink}
                                variant="body1"
                                to="/scheduling/staff-optimizations"
                                sx={({ palette }) => ({
                                    fontWeight: 600,
                                    textDecoration: 'underline',
                                    color: palette.grey[900],
                                })}
                            >
                                Staff Optimizations.
                            </Link>
                        </Typography>
                    }
                />
            )}
        </Box>
    );
};

export default Alerts;
