import { api } from '~/api';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';
import { PermissionListResponse } from '~/types/permissions';

export const usePermissionsQuery = () => {
    const token = useToken();

    return useErrorHandledQuery(
        ['permissions'],
        async () => {
            const options = { headers: { Authorization: token } };
            const { data } = await api.get<PermissionListResponse>(`/permissions`, options);
            return data.response;
        },
        {
            enabled: !!token, // Prevent auto-fetching if logged out
            staleTime: 1000 * 60 * 60, // 1 hour
        }
    );
};
