import { useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { withBranchShiftSelector } from '~/api/queries/branch';
import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { useResidentsQuery } from '~/api/queries/residents';
import Loading from '~/components/Shared/Loading';
import PageStructure from '~/pages/PageStructure';
import FilterContainer from '~/pages/Residents/components/FilterContainer';
import ResidentsContainer from '~/pages/Residents/components/ResidentsContainer';
import { usePermissions } from '~/permissions/utils';
import { ResidentTasks } from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';
import { ResidentsListResponse } from '~/types/residents';

type Props = {
    timezone: string;
    zoneId: number;
    sortBy: string;
    sortOrder: string;
    selectedBranchId: number;
    setSelectedBranchId: (branchId: number) => void;
};

const Residents = (props: Props) => {
    const { regionId, companyId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const { zoneId, sortBy, sortOrder, selectedBranchId, setSelectedBranchId } = props;
    const [residentsToShow, setResidentsToShow] = useState<ResidentsListResponse[]>([]);
    const [totalResidentsToShow, setTotalResidentsToShow] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [statusId, setStatusId] = useState<string>('all');

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionIsLoading,
        isError: branchesByRegionIsError,
    } = useBranchesByRegionQuery({ branchId: selectedBranchId, regionId, companyId });

    const branchesWithAccess = branchesByRegionData?.branches.filter((branch) => branch.hasAccess) ?? [];

    const hasPermission = usePermissions();

    const renderBranchSelector =
        hasPermission('Community', 'read-all-resident-actions') && branchesWithAccess.length > 1;

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery({ branchId: selectedBranchId });

    const handleSort = (
        newResidentsList: ResidentsListResponse[],
        selectedSortBy: keyof ResidentTasks | 'shower&Laundry',
        isAscending: boolean
    ): ResidentsListResponse[] => {
        if (selectedSortBy === 'shower&Laundry') {
            return orderBy(
                newResidentsList,
                ['hasShower', 'hasLaundry'],
                [isAscending ? 'desc' : 'asc', isAscending ? 'desc' : 'asc']
            );
        }

        if (selectedSortBy === 'roomNumber') {
            return orderBy(newResidentsList, (item) => Number(item[selectedSortBy]), isAscending ? 'asc' : 'desc');
        }

        return orderBy(newResidentsList, selectedSortBy, isAscending ? 'asc' : 'desc');
    };

    const handleSearchChange = (newSearchValue: string) => {
        setSearchText(newSearchValue);
        const searchInLowerCase = newSearchValue.toLowerCase();

        if (!residentsList) {
            return;
        }

        const newResidentsList: ResidentsListResponse[] = residentsList.filter((resident) => {
            const statusValidation = statusId !== 'all' ? resident.buildingStatus === statusId : true;
            const residentFullName = `${resident.firstName} ${resident.lastName}`.toLowerCase();

            return (
                (resident.firstName.toLowerCase().includes(searchInLowerCase) ||
                    resident.lastName.toLowerCase().includes(searchInLowerCase) ||
                    residentFullName.includes(searchInLowerCase) ||
                    resident.roomNumber?.toLowerCase().includes(searchInLowerCase)) &&
                (zoneId === -1 || // All zones
                    resident.zoneId === zoneId) && // Specific zone
                statusValidation
            );
        });

        const defaultResidentsList = orderBy(
            newResidentsList,
            (item) => Number(item.roomNumber),
            sortOrder === 'asc' ? 'asc' : 'desc'
        );

        const residentsListSorted = handleSort(
            defaultResidentsList,
            sortBy as keyof ResidentTasks | 'shower&Laundry',
            sortOrder === 'asc'
        );

        setResidentsToShow(residentsListSorted);
        setTotalResidentsToShow(residentsListSorted?.length);
    };

    const handleStatusChange = (newStatusId: string) => {
        setStatusId(newStatusId);
    };

    useEffect(() => {
        if (residentsList?.length) {
            handleSearchChange(searchText);
        }
    }, [residentsList, zoneId, sortBy, sortOrder, statusId]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <PageStructure>
            <FilterContainer
                totalResidentsToShow={totalResidentsToShow}
                renderBranchSelector={renderBranchSelector}
                branchesWithAccess={branchesWithAccess}
                branchId={selectedBranchId}
                onBranchChange={setSelectedBranchId}
                onSearch={handleSearchChange}
                onStatusChange={handleStatusChange}
            />
            {isMobile && <Box height={108 + (renderBranchSelector ? 56 : 0)} /> /* Spacer for the fixed header */}
            <ResidentsContainer residentsToShow={residentsToShow} />
        </PageStructure>
    );
};

const mapStateToProps = ({ session, filters }: ReduxStore) => {
    const { timezone } = session;
    const {
        caregiverApp: { zoneId, sortBy, sortOrder },
    } = filters;

    return {
        timezone,
        zoneId,
        sortBy,
        sortOrder,
    };
};

export default connect(mapStateToProps)(withBranchShiftSelector(Residents));
