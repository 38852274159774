import { TypographyOptions } from '@mui/material/styles/createTypography';

import { THEME_FONT_FAMILY } from '~/constants/theme';

export const pxToRem = (value: number) => `${value / 16}rem`;

export const responsiveFontSizes = ({ xs, sm, md, lg, xl }) => ({
    fontSize: pxToRem(xs),
    '@media (min-width: 425px)': {
        fontSize: pxToRem(sm),
    },
    '@media (min-width: 728px)': {
        fontSize: pxToRem(md),
    },
    '@media (min-width: 1024px)': {
        fontSize: pxToRem(lg),
    },
    '@media (min-width: 1440px)': {
        fontSize: pxToRem(xl),
    },
});

const typography: TypographyOptions = {
    fontFamily: THEME_FONT_FAMILY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 22, sm: 22, md: 24, lg: 26, xl: 26 }),
        lineHeight: 1.5,
    },
    h2: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 19, sm: 19, md: 22, lg: 24, xl: 24 }),
        lineHeight: 1.5,
    },
    h3: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 16, sm: 16, md: 20, lg: 22, xl: 22 }),
        lineHeight: 1.25,
    },
    h4: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 14, sm: 14, md: 18, lg: 20, xl: 20 }),
        lineHeight: 1.25,
    },
    h5: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 14, sm: 14, md: 18, lg: 20, xl: 20 }),
        lineHeight: 1,
    },
    h6: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 14, sm: 14, md: 18, lg: 20, xl: 20 }),
        lineHeight: 1,
    },
    subtitle1: {
        fontWeight: 600,
        ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 18, xl: 18 }),
        lineHeight: 1.5,
    },
    subtitle2: {
        fontWeight: 600,
        ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16, xl: 16 }),
        lineHeight: 1.5,
    },
    body1: {
        ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 18, xl: 18 }),
        lineHeight: 1.5,
    },
    body2: {
        ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16, xl: 16 }),
        lineHeight: 1.5,
    },
    caption: {
        margin: 0,
        fontWeight: 500,
        ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16, xl: 16 }),
        lineHeight: 1.5,
    },
    overline: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16, xl: 16 }),
        lineHeight: 1.5,
        letterSpacing: 1.1,
        textTransform: 'uppercase',
    },
    button: {
        fontWeight: 700,
        ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16, xl: 16 }),
        lineHeight: 1.5,
        textTransform: 'capitalize',
    },
};

export default typography;
