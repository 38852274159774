import { Box, Menu, MenuItem } from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch } from 'react-redux';

import { pxToRem } from '~/components/theme/typography';
import { setSelectedStartDate } from '~/redux/actions/opsDashboard';
import { OperationsPeriod } from '~/types/operationsDashboard';

const formatDate = (date: string) => format(parse(date, 'yyyy-MM-dd', new Date()), 'M/d');

type ButtonProps = {
    selectedDate: string;
    dateOptions: string[];
};

const Button = ({ selectedDate, dateOptions }: ButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = (option?: string) => {
        if (option) {
            dispatch(setSelectedStartDate(option));
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    backgroundColor: '#F2F8F8',
                    paddingTop: pxToRem(4),
                    paddingBottom: pxToRem(4),
                    paddingLeft: pxToRem(8),
                    paddingRight: pxToRem(8),
                    color: '#006B75',
                    fontWeight: 600,
                    fontSize: pxToRem(14),
                    borderRadius: pxToRem(4),
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <Box sx={{ marginRight: pxToRem(2) }}>{formatDate(selectedDate)}</Box>
                <IoIosArrowDown />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                PaperProps={{
                    style: {
                        maxHeight: pxToRem(200),
                        maxWidth: pxToRem(200),
                    },
                }}
                sx={{
                    '& .MuiPaper-root': {
                        padding: 0,
                        fontSize: pxToRem(14),
                        borderRadius: pxToRem(4),
                    },
                    '& .MuiMenuItem-root': {
                        padding: `${pxToRem(8)} ${pxToRem(16)} ${pxToRem(8)} ${pxToRem(8)} !important`, // for some reason only way to set the padding on the menu items
                        fontSize: pxToRem(14),
                    },
                }}
            >
                {dateOptions.map((option) => (
                    <MenuItem key={option} onClick={() => handleClose(option)} disableRipple>
                        {formatDate(option)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

type Props = {
    sectionTitle: string;
    dateOptions: string[];
    selectedDate: string;
    selectedTimePeriod: OperationsPeriod;
};

export const DateGroupSelector = ({ sectionTitle, dateOptions, selectedDate, selectedTimePeriod }: Props) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            lineHeight: pxToRem(24),
        }}
    >
        <Box
            sx={{
                color: '#2C2D3B',
                fontWeight: 700,
                size: pxToRem(16),
                marginRight: pxToRem(8),
                textAlign: 'left',
            }}
        >
            {sectionTitle} for {selectedTimePeriod !== 'day' ? `${selectedTimePeriod} of` : ''}
        </Box>
        <Button selectedDate={selectedDate} dateOptions={dateOptions} />
    </Box>
);
