import { AnyAction } from 'redux';

import { UI_DEFAULT_STATE } from '~/constants/redux';
import actionTypes from '~/redux/actionTypes';
import { UIState } from '~/types/redux';

const ui = (state: UIState = UI_DEFAULT_STATE, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.MOBILE_DRAWER_TOGGLE:
            return {
                ...state,
                isMobileDrawerOpen: !state.isMobileDrawerOpen,
            };
        default:
            return state;
    }
};

export default ui;
