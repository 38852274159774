import { Box, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';

import ShiftDayColumn from './ShiftDayColumn';
import { GRID_COLUMNS, SingleLineTypography } from './shared';
import type { WeekGridSchema } from './types';

const Cell = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
});

const ShiftNameCell = styled(Cell)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100],
    height: '40px',
    padding: '8px 12px',
    borderRight: '1px solid',
    borderColor: palette.grey[100],
}));

const RoleNameCell = styled(Cell)(({ theme: { palette } }) => ({
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
    gap: '8px',
    borderBottom: '1px solid',
    borderColor: palette.grey[200],
}));

const RoleLocationChip = ({ location }: { location: string }) => (
    <Box
        sx={{
            color: 'primary.500',
            bgcolor: 'white',
            padding: '0 4px',
            borderRadius: '6px',
        }}
    >
        <Typography variant="body1" fontSize="12px" fontWeight={600}>
            {location}
        </Typography>
    </Box>
);

const RoleCell = ({ role: { group, name, location } }: { role: WeekGridSchema.Role }) => (
    <RoleNameCell bgcolor={`primary.${300 + group * 100}`}>
        <SingleLineTypography variant="body1" color="white" maxHeight={20}>
            {name}
        </SingleLineTypography>
        <RoleLocationChip location={location} />
    </RoleNameCell>
);

const ShiftInfoColumn = ({ name, roles }: Pick<WeekGridSchema.Shift, 'name' | 'roles'>) => (
    <Grid container direction="column" xs>
        <ShiftNameCell>
            <Typography color="primary.600" variant="h6">
                {name}
            </Typography>
        </ShiftNameCell>
        {roles.map((role, index) => (
            <RoleCell key={index} role={role} />
        ))}
    </Grid>
);

const ShiftGrid = ({ shift: { name, roles, days } }: { shift: WeekGridSchema.Shift }) => (
    <Grid container xs={GRID_COLUMNS} width="100%">
        <ShiftInfoColumn name={name} roles={roles} />
        {days.map((day) => (
            <ShiftDayColumn key={day.day.toMillis()} day={day} />
        ))}
    </Grid>
);

export default ShiftGrid;
