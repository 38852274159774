import { TabContext, TabList } from '@mui/lab';
import { Stack, Tab } from '@mui/material';
import { useAtom } from 'jotai';
import React, { ComponentProps } from 'react';

import { MOBILE_BOTTOM_NAVIGATION_HEIGHT } from '~/components/Layout/shared';

import { selectedTabAtom } from './atoms';
import FilledSlotsTabPanel from './components/FilledSlotsTabPanel';
import Header from './components/Header';
import NotificationsDrawer from './components/NotificationsDrawer';
import OpenSlotsTabPanel from './components/OpenSlotsTabPanel';

const CustomTabContext = (props: ComponentProps<typeof TabContext>) => (
    <Stack
        sx={({ palette }) => ({
            height: `calc(100% - ${MOBILE_BOTTOM_NAVIGATION_HEIGHT}px)`,
            '& .MuiTabs-root': { flexShrink: 0, bgcolor: palette.primary.main },
            '& .MuiTab-root': {
                flex: 1,
                color: 'white !important',
                height: '48px',
                fontSize: '16px',
                fontWeight: 400,
                '&.Mui-selected': { fontWeight: 700 },
            },
            '& .MuiTabs-indicator': { bgcolor: 'white' },
        })}
    >
        <TabContext {...props} />
    </Stack>
);

const Tabs = () => {
    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

    return (
        <CustomTabContext value={selectedTab}>
            <TabList onChange={(_, newTab: typeof selectedTab) => setSelectedTab(newTab)} sx={{ px: '24px' }}>
                <Tab label="Scheduled" value="filled" />
                <Tab label="Open" value="open" />
            </TabList>

            {selectedTab === 'filled' && <FilledSlotsTabPanel />}
            {selectedTab === 'open' && <OpenSlotsTabPanel />}
        </CustomTabContext>
    );
};

const Schedule = () => (
    <Stack height="100%">
        {/* TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1324 */}
        <Header /* actions={<HeaderNotificationButton />} */ />
        <Tabs />
        <NotificationsDrawer />
    </Stack>
);

export default Schedule;
