import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/scheduling/api/common';
import { GetDashboardAlerts } from '~/scheduling/api/types/dashboard/getDashboardAlerts';

export const useGetDashboardAlerts = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };

        const { data } = await api.get<GetDashboardAlerts.Response>(
            `/scheduling/${branchId}/dashboard/alerts`,
            options
        );

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['dashboard-alerts', branchId],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId,
    });
};
