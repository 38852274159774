import { atom } from 'jotai';

import { atomWithStorage } from '~/scheduling/utils/atoms';
import { OperationsPeriod } from '~/types/operationsDashboard';

export const regionAtom = atom<string>('all');
export const branchAtom = atom<string>('all');
export const careTypeAtom = atomWithStorage<string>('operationsDashboard_CareType', 'all');
export const operationsPeriodAtom = atomWithStorage<OperationsPeriod>('operationsDashboard_OperationsPeriod', 'week');
export const mobileTabSelectedAtom = atom<0 | 1>(0);
