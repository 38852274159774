import { TabPanel, TabPanelProps } from '@mui/lab';
import {
    Box,
    InputAdornment,
    Popper,
    PopperProps,
    Skeleton,
    Stack,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import { CirclesThreePlus, MagnifyingGlass, UserPlus } from '@phosphor-icons/react';
import { useAtomValue, useSetAtom } from 'jotai';
import range from 'lodash/range';
import React, { Fragment, forwardRef, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetSlotSuggestedStaff } from '~/scheduling/api/queries/shift-slot/getSlotSuggestedStaff';
import { HorizontalSeparator } from '~/scheduling/components/shared';

import {
    isAddAgencyStaffModalOpenAtom,
    selectStaffModalSlotIdAtom,
    selectStaffSearchValueAtom,
    selectStaffSelectedStaffIdAtom,
} from '../../../../atoms';
import StaffOption from '../StaffOption';

import CreateAgencyStaffModal from './CreateAgencyStaffModal';

const SearchStaffTextField = forwardRef<HTMLDivElement, TextFieldProps>(function SearchStaffTextField(props, ref) {
    const setSearchValue = useSetAtom(selectStaffSearchValueAtom);

    return (
        <TextField
            ref={ref}
            variant="outlined"
            placeholder="Enter staff name"
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <MagnifyingGlass fontSize={18} />
                    </InputAdornment>
                ),
            }}
            fullWidth
            {...props}
        />
    );
});

const SearchStaffOptions = ({
    anchorEl,
    hasAnchorBeenFocused,
}: {
    anchorEl: HTMLDivElement | null;
    hasAnchorBeenFocused: boolean;
}) => {
    const slotId = useAtomValue(selectStaffModalSlotIdAtom);

    const searchValue = useAtomValue(selectStaffSearchValueAtom);
    const setSelectedStaffId = useSetAtom(selectStaffSelectedStaffIdAtom);

    const { data: suggestedStaffData, isPending } = useGetSlotSuggestedStaff(slotId);
    const suggestedStaffList = suggestedStaffData?.suggestedStaff ?? [];

    const staffOptions = useMemo(() => {
        const splitSearchValue = searchValue.split(' ').map((s) => s.toLowerCase());
        return suggestedStaffList
            .filter(({ name }) => {
                const lowerCaseName = name.toLowerCase();
                return splitSearchValue.some((search) => lowerCaseName.includes(search));
            })
            .slice(0, 5);
    }, [searchValue]);

    // Only show if the user clicked on the input at least once
    if (!searchValue) return hasAnchorBeenFocused ? <SearchStaffEmpty anchorEl={anchorEl} /> : null;

    if (!isPending && !staffOptions.length) return <SearchStaffNotFound anchorEl={anchorEl} />;

    return (
        <SearchStaffPopper anchorEl={anchorEl} sx={{ p: '4px 0' }}>
            {!isPending ? (
                staffOptions.map(({ id, name, flags, details }, index) => (
                    <Fragment key={name}>
                        {index > 0 && <HorizontalSeparator width="100%" />}
                        <StaffOption
                            name={name}
                            flags={flags}
                            details={details}
                            actions={
                                <Button variant="outlined" size="small" onClick={() => setSelectedStaffId(id)}>
                                    Select
                                </Button>
                            }
                        />
                    </Fragment>
                ))
            ) : (
                <Stack p="8px" spacing="8px">
                    {range(3).map((i) => (
                        <Skeleton key={i} height={64} />
                    ))}
                </Stack>
            )}
        </SearchStaffPopper>
    );
};

const SearchStaffEmpty = ({ anchorEl }: { anchorEl: HTMLDivElement | null }) => {
    const { palette } = useTheme();

    const toggleAddAgencyStaffModal = useSetAtom(isAddAgencyStaffModalOpenAtom);

    return (
        <SearchStaffPopper
            anchorEl={anchorEl}
            sx={{
                alignItems: 'center',
                p: '20px',
            }}
        >
            <SearchStaffAddButton
                size="small"
                startIcon={<CirclesThreePlus color={palette.primary.main} weight="bold" />}
                onClick={toggleAddAgencyStaffModal}
            >
                Add Agency Staff
            </SearchStaffAddButton>
        </SearchStaffPopper>
    );
};

const SearchStaffNotFound = ({ anchorEl }: { anchorEl: HTMLDivElement | null }) => {
    const { palette } = useTheme();

    const toggleAddAgencyStaffModal = useSetAtom(isAddAgencyStaffModalOpenAtom);

    return (
        <SearchStaffPopper
            anchorEl={anchorEl}
            sx={{
                alignItems: 'center',
                p: '20px',
            }}
        >
            <Stack spacing="24px">
                <Stack alignItems="center" spacing="12px">
                    <Box
                        sx={{
                            bgcolor: palette.grey[50],
                            width: '56px',
                            height: '56px',
                            p: '16px',
                            borderRadius: '50%',
                        }}
                    >
                        <MagnifyingGlass color={palette.grey[500]} weight="fill" fontSize="24px" />
                    </Box>
                    <Stack alignItems="center" spacing="4px">
                        <Typography fontSize="15px" fontWeight={600}>
                            No results found
                        </Typography>
                        <Typography>{"Can't find the staff you're looking for?"}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing="12px">
                    <Link to="../staff-list/staff-details">
                        <SearchStaffAddButton
                            size="small"
                            startIcon={<UserPlus color={palette.primary.main} weight="bold" />}
                        >
                            Add New Staff
                        </SearchStaffAddButton>
                    </Link>
                    <SearchStaffAddButton
                        size="small"
                        startIcon={<CirclesThreePlus color={palette.primary.main} weight="bold" />}
                        onClick={toggleAddAgencyStaffModal}
                    >
                        Add Agency Staff
                    </SearchStaffAddButton>
                </Stack>
            </Stack>
        </SearchStaffPopper>
    );
};

const SearchStaffAddButton = styled(Button)(({ theme: { palette } }) => ({
    color: palette.primary.main,
    backgroundColor: palette.primary[50],
    width: 'fit-content',
    padding: '10px 12px',
    '&:hover': {
        color: palette.primary.main,
        backgroundColor: palette.primary[100] as string,
    },
    '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: '6px',
    },
}));

const SearchStaffPopper = ({
    anchorEl,
    children,
    sx,
    ...props
}: { anchorEl: HTMLDivElement | null; children: React.ReactNode } & Partial<PopperProps>) => (
    <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
            width: anchorEl?.clientWidth,
            zIndex: 9999,
        }}
        disablePortal
        {...props}
    >
        <Stack
            sx={{
                bgcolor: 'white',
                width: '100%',
                mt: '4px',
                borderRadius: '8px',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                ...sx,
            }}
        >
            {children}
        </Stack>
    </Popper>
);

const SearchStaffTabPanel = (props: TabPanelProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [hasAnchorBeenFocused, setHasAnchorBeenFocused] = useState(false);

    return (
        <>
            <TabPanel {...props}>
                <SearchStaffTextField ref={setAnchorEl} onFocus={() => setHasAnchorBeenFocused(true)} />
                <SearchStaffOptions anchorEl={anchorEl} hasAnchorBeenFocused={hasAnchorBeenFocused} />
            </TabPanel>

            <CreateAgencyStaffModal />
        </>
    );
};

export default SearchStaffTabPanel;
