import { Capacitor } from '@capacitor/core';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import React, { useState } from 'react';

import GeolocationDisabled from '~/assets/geolocation-disabled.svg';
import GeolocationPrompt from '~/assets/geolocation-prompt.svg';
import Location1Android from '~/assets/location_1_android.png';
import Location1iOS from '~/assets/location_1_ios.png';
import Location2Android from '~/assets/location_2_android.png';
import Location2iOS from '~/assets/location_2_ios.png';
import Location3Android from '~/assets/location_3_android.png';
import Precise1Android from '~/assets/precise_1_android.png';
import Precise1iOS from '~/assets/precise_1_ios.png';
import { pxToRem } from '~/components/theme/typography';
import { useGeolocation } from '~/contexts/GeolocationContext';

const isNativePlatform = Capacitor.isNativePlatform();
const platform = Capacitor.getPlatform() as 'android' | 'ios' | 'web';

const InstructionImage = ({ src, alt = 'image' }) => (
    <img
        style={{
            marginBottom: pxToRem(24),
        }}
        src={src}
        alt={alt}
    />
);

const StatusIcon = ({ src, alt }) => (
    <Box
        component="img"
        sx={{
            width: {
                xs: pxToRem(100),
                lg: pxToRem(120),
            },
            mb: pxToRem(24),
            mx: 'auto',
        }}
        src={src}
        alt={alt}
    />
);

const GeolocationInfo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { permissionStatus, updatePosition, isPositionAccurate } = useGeolocation();

    const isPermissionDenied = () => {
        if (Capacitor.getPlatform() === 'android') {
            return permissionStatus?.coarseLocation === 'denied';
        }

        return permissionStatus?.location === 'denied';
    };

    // Components

    const Container = styled(Box)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '65ch',
            width: {
                xs: '85%',
                lg: 'auto',
            },
            mx: 'auto',
            pt: {
                xs: platform === 'ios' ? 'calc(env(safe-area-inset-top) - 30px)' : pxToRem(32),
                lg: pxToRem(80),
            },
            pb: {
                xs: platform === 'ios' ? 'calc(env(safe-area-inset-bottom) + 50px)' : pxToRem(48),
                lg: pxToRem(32),
            },
        })
    );

    const Title = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: { xs: pxToRem(24) },
            lineHeight: 1.6,
            fontWeight: 700,
            color: '#2C2D3B',
            mb: pxToRem(16),
            mt: pxToRem(24),
            textAlign: 'center',
        })
    );

    const Subtitle = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#6F6F79',
            fontSize: {
                xs: pxToRem(14),
                lg: pxToRem(16),
            },
            lineHeight: 1.5,
            marginBottom: pxToRem(24),
            textAlign: 'left',
        })
    );

    const ActionButton = styled(Button)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            borderRadius: pxToRem(4),
            py: pxToRem(16),
            fontWeight: 700,
            fontSize: {
                xs: pxToRem(16),
            },
            lineHeight: {
                xs: 1.5,
            },
            color: '##fff !important',
            backgroundColor: '#006B75 !important',
            marginBottom: pxToRem(32),
            '&:disabled': {
                backgroundColor: '#61A3A9 !important',
            },
        })
    );

    const handleActivateGeolocation = () => {
        setIsLoading(true);
        updatePosition();
    };

    // Application States

    if (permissionStatus?.location.includes('prompt')) {
        return (
            <Container>
                <StatusIcon src={GeolocationPrompt} alt="geolocation prompt icon" />

                <Title>Activate Geolocation</Title>
                <Subtitle>
                    Geolocation enables essential features such as emergency response, location-based reminders, safe
                    zones. Activate now to unlock your access to the app.
                </Subtitle>
                <ActionButton
                    disabled={isLoading}
                    endIcon={isLoading ? <CircularProgress size={16} color="inherit" /> : null}
                    onClick={handleActivateGeolocation}
                >
                    {isLoading ? 'Activating' : 'Allow'}
                </ActionButton>
            </Container>
        );
    }
    if (isPermissionDenied()) {
        return (
            <Container>
                <StatusIcon src={GeolocationDisabled} alt="geolocation denied icon" />

                <Title>Disabled Geolocation</Title>
                <Subtitle>
                    {`Activating geolocation is important to make sure that you're here in our community.`}
                </Subtitle>
                <Subtitle>
                    {`If you have accidentally deactivated your geolocation, don't worry! You can manually activate geolocation on your ${
                        platform === 'web' ? 'browser' : 'phone'
                    } settings. Follow these steps:`}
                </Subtitle>

                {/* iOS Steps */}
                {platform === 'ios' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location1iOS} />
                        <Subtitle>{`3. Select "While Using the App"`}</Subtitle>
                        <InstructionImage src={Location2iOS} />
                    </>
                )}

                {platform === 'android' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Permissions"`}</Subtitle>
                        <InstructionImage src={Location1Android} />
                        <Subtitle>{`3. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location2Android} />
                        <Subtitle>{`4. Select "Allow only while using the app"`}</Subtitle>
                        <InstructionImage src={Location3Android} />
                    </>
                )}

                {platform === 'web' && (
                    <>
                        <Subtitle>{`1. Click on the website's security or lock icon near the address bar.`}</Subtitle>
                        <Subtitle>{`2. Adjust the location permission to "Allow".`}</Subtitle>
                        <Subtitle>{`For specific steps, refer to your browser's help section.`}</Subtitle>
                    </>
                )}

                {isNativePlatform && (
                    <ActionButton
                        onClick={() => {
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App,
                            });
                        }}
                    >
                        Go to Settings
                    </ActionButton>
                )}
            </Container>
        );
    }
    if (!isPositionAccurate && platform !== 'web') {
        return (
            <Container>
                <StatusIcon src={GeolocationDisabled} alt="icon" />

                <Title>Inaccurate Geolocation</Title>
                <Subtitle>
                    {`Please enable the "Precise Location" option for AllieHealth in your system settings. Follow these steps:`}
                </Subtitle>
                {platform === 'ios' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location1iOS} />
                        <Subtitle>{`3. Turn on "Precise Location"`}</Subtitle>
                        <InstructionImage src={Precise1iOS} />
                    </>
                )}
                {platform === 'android' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Permissions"`}</Subtitle>
                        <InstructionImage src={Location1Android} />
                        <Subtitle>{`3. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location2Android} />
                        <Subtitle>{`4. Turn on "Use precise location"`}</Subtitle>
                        <InstructionImage src={Precise1Android} />
                    </>
                )}
                {isNativePlatform && (
                    <ActionButton
                        onClick={() => {
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App,
                            });
                        }}
                    >
                        Go to Settings
                    </ActionButton>
                )}
            </Container>
        );
    }

    return null;
};

export default GeolocationInfo;
