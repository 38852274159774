import { range } from 'lodash';
import { DateTime, Interval } from 'luxon';

import { DAYS_IN_WEEK } from '~/scheduling/constants';

export const getWeekDays = (date: DateTime, weekStartsOn: number): DateTime<true>[] => {
    // Always substract a positive modulo, as in https://stackoverflow.com/a/4467559/5699461
    const moduloDays = (((1 - weekStartsOn) % DAYS_IN_WEEK) + DAYS_IN_WEEK) % DAYS_IN_WEEK;
    const startOfWeek = date.startOf('week').minus({ days: moduloDays });
    return range(DAYS_IN_WEEK).map((i) => startOfWeek.plus({ days: i }));
};

/**
 * @param firstDayOfWeek number from 1 to 7
 * @returns array of numbers from 0 to 6 representing
 * the days ordered respecting the first day of the week
 * from the community settings
 * @example getWeekDaysOrder(3) => [5, 6, 0, 1, 2, 3, 4]
 **/
export const getWeekDaysOrder = (firstDayOfWeek: number) => range(7).map((i) => (firstDayOfWeek + i - 1) % 7);

/**
 * Overwrite the time of a DateTime with an ISO time string
 */
export const mergeDateAndTime = (date: DateTime, time: string) => {
    const { hour, minute, second, millisecond } = DateTime.fromISO(time);
    return date.set({ hour, minute, second, millisecond });
};

/**
 * This accounts for shifts that physically end on the next day, but are still called today's
 */
export const buildShiftInterval = (shiftDay: DateTime, startTime: string, endTime: string) => {
    const start = mergeDateAndTime(shiftDay, startTime);
    const end = mergeDateAndTime(shiftDay, endTime).plus({ days: startTime > endTime ? 1 : 0 });
    return Interval.fromDateTimes(start, end) as Interval<true>;
};

export const getWeekInterval = (date: DateTime, firstDayOfWeek: number) => {
    const moduloDays = (date.weekday - firstDayOfWeek + DAYS_IN_WEEK) % DAYS_IN_WEEK;
    const start = date.minus({ days: moduloDays }).startOf('day');
    const end = start.plus({ days: DAYS_IN_WEEK }).minus({ milliseconds: 1 });
    return Interval.fromDateTimes(start, end) as Interval<true>;
};
