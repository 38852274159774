import { Typography, styled } from '@mui/material';

import { DAYS_IN_WEEK } from '~/scheduling/constants';

export const GRID_COLUMNS = 1 + DAYS_IN_WEEK; // Shift info + days in week

export const SingleLineTypography = styled(Typography)({
    overflow: 'hidden',
    textAlign: 'start',
    wordBreak: 'break-all',
    // Webkit-specific properties for line-clamping:
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
});
