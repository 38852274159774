import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { currentMonthString } from './util';

const ChevronLeft = styled(FiChevronLeft)(({ theme }) => ({
    color: theme.palette.grey[600],
    cursor: 'pointer',
    width: '16px',
    height: '16px',
}));

const ChevronRight = styled(FiChevronRight)(({ theme }) => ({
    color: theme.palette.grey[600],
    cursor: 'pointer',
    width: '16px',
    height: '16px',
}));

interface CalendarHeaderProps {
    currentYear: number;
    monthIndex: number;
    hideLeftArray?: boolean;
    hideRightArray?: boolean;
    onNextMonth: () => void;
    onPreviousMonth: () => void;
}

export const CalendarHeader = (props: CalendarHeaderProps) => {
    return (
        <Box display="flex" justifyContent="space-between" p="16px 8px">
            {!props.hideLeftArray ? (
                <ChevronLeft onClick={props.onPreviousMonth} />
            ) : (
                <Box width="16px" height="16px" />
            )}
            <Typography
                variant="body1"
                lineHeight="16px"
                fontSize="12px"
                fontWeight={700}
                color="grey.600"
                textTransform="uppercase"
            >
                {currentMonthString(props.monthIndex)} {props.currentYear}
            </Typography>
            {!props.hideRightArray ? <ChevronRight onClick={props.onNextMonth} /> : <Box width="16px" height="16px" />}
        </Box>
    );
};
