import React from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';
import { CreateMasterSchedule } from '~/scheduling/api/types/master-schedule/createMasterSchedule';

export const useCreateMasterSchedule = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = React.useCallback(
        async (input: CreateMasterSchedule.Params) => {
            const options = { headers: { Authorization: token } };
            await api.post<CreateMasterSchedule.Response>(
                `/scheduling/${branchId}/master-schedule/team/${teamId}`,
                input,
                options
            );
        },
        [branchId, token, teamId]
    );

    const onSuccess = React.useCallback(async () => {
        // Staff Optimizations
        await queryClient.invalidateQueries({ queryKey: ['master-schedule', branchId, teamId] });
        await queryClient.invalidateQueries({ queryKey: ['budget', branchId, teamId] });

        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });

        // Dashboard
        await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['open-shifts', branchId] });
    }, [branchId, teamId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
