import { Capacitor } from '@capacitor/core';

import { NavigationOption, PlatformType } from '~/components/Layout/shared';

export const platformMap: Record<string, PlatformType> = {
    ios: 'native',
    android: 'native',
    web: 'web',
};

export function isPlatformBlacklisted(navigationOption: NavigationOption) {
    const platform = platformMap[Capacitor.getPlatform()];

    return navigationOption.platformGuardBlacklist?.includes(platform);
}

export default function platformGuardFilter(navigationOptions: NavigationOption[]) {
    return navigationOptions.filter((route) => !isPlatformBlacklisted(route));
}
