import { Box, Button, Chip, CircularProgress, Typography, styled } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { SHIFT_SLOT_REQUEST_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { GetSlotRequestsResult, useGetSlotRequests } from '~/scheduling/api/queries/shift-slot/getSlotRequests';
import { useUpdateSlotRequest } from '~/scheduling/api/queries/shift-slot/updateSlotRequest';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';

import { CustomChip } from '../../../../components/CustomChip';
import SegmentedModal from '../../../../components/SegmentedModal';
import { isReviewOpenShiftsModalOpenAtom } from '../../atom';

const SelectButton = styled(Button)<{ selected?: boolean }>(({ theme, selected }) => ({
    color: !selected ? theme.palette.primary[500] : undefined,
    lineHeight: '20px',
    padding: '8px 12px',
}));

const Caption = styled(Typography)(() => ({
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '16px',
}));

const CaregiverCard = ({
    request,
    selected,
    onClick,
    isLoading,
}: {
    request: GetSlotRequestsResult[number]['requests'][number];
    selected?: boolean;
    onClick: (requestId: number) => void;
    isLoading: boolean;
}) => {
    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    if (!staffById) return null;

    const { id: requestId, staffId, flags, createdAt } = request;
    const staff = staffById.get(staffId)!;

    return (
        <Box
            sx={{
                backgroundColor: 'primary.100',
                display: 'flex',
                gap: '12px',
                padding: '12px',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box display="flex" flexDirection="column" gap="4px">
                <Box display="flex" gap="10px">
                    <Typography variant="body1" color="grey.900" fontWeight={700} fontSize="16px">
                        {staff.name}
                    </Typography>
                    <Box display="flex" gap="8px">
                        {flags.map((flag, index) => (
                            <CustomChip key={index} variant={flag.level} label={flag.text} />
                        ))}
                    </Box>
                </Box>
                <Box display="flex" gap="4px">
                    <Caption color="grey.500">Requested at</Caption>
                    <Caption color="primary.500" fontWeight={500}>
                        {createdAt.toRelative({ base: DateTime.now(), locale: 'en' })}
                    </Caption>
                </Box>
            </Box>
            <SelectButton
                variant={selected ? 'contained' : 'outlined'}
                size="small"
                onClick={() => onClick(requestId)}
                selected={selected}
                disabled={isLoading}
            >
                {selected ? 'Selected' : 'Select'}
            </SelectButton>
        </Box>
    );
};

const Header = ({ slot }: { slot: GetSlotRequestsResult[number] }) => {
    const { shiftDay, roleId, roleShiftId, locationId } = slot;

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const dayStr = shiftDay.toFormat('EEE, MMM d');
    const role = roleById?.get(roleId)?.name;
    const roleShift = roleShiftById?.get(roleShiftId)?.name;
    const location = locationById?.get(locationId)?.abbreviation;

    return (
        <Box display="flex" gap="48px" justifyContent="space-between">
            <Box display="flex" gap="4px">
                <Typography variant="h6" color="grey.900">
                    {dayStr}
                </Typography>
                <Typography variant="h6" fontWeight={400} color="grey.900">
                    ({roleShift} Shift)
                </Typography>
            </Box>
            <Box display="flex" gap="8px">
                <Chip size="medium" label={role} />
                <Chip size="medium" label={location} />
            </Box>
        </Box>
    );
};

const ReviewOpenShiftsActions = ({
    selectedShiftsQuantity,
    onConfirm,
    isLoading,
}: {
    selectedShiftsQuantity: number;
    onConfirm: () => Promise<void>;
    isLoading: boolean;
}) => {
    const toggle = useSetAtom(isReviewOpenShiftsModalOpenAtom);

    const handleConfirm = async () => {
        await onConfirm();
        toggle();
    };

    return (
        <>
            <Button variant="outlined" onClick={toggle} disabled={isLoading}>
                Cancel
            </Button>
            <Button disabled={!selectedShiftsQuantity || isLoading} onClick={handleConfirm}>
                {isLoading ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    `Confirm${selectedShiftsQuantity ? ` ${selectedShiftsQuantity}` : ''} Shifts`
                )}
            </Button>
        </>
    );
};

const ReviewOpenShiftsModal = () => {
    const [isOpen, toggle] = useAtom(isReviewOpenShiftsModalOpenAtom);
    const [selectedRequestIds, setSelectedRequestIds] = React.useState<Record<string, number>>({});

    const { data: slotRequestsData } = useGetSlotRequests();
    const slotRequests = slotRequestsData ?? [];

    const { mutateAsync: updateSlotRequest, isPending } = useUpdateSlotRequest();

    const handleSelectedRequest = (slotId: number, requestId: number) => {
        setSelectedRequestIds((prevSelectedRequestIds) => ({ ...prevSelectedRequestIds, [slotId]: requestId }));
    };

    const handleConfirm = async () => {
        const slotIdByRequestId: Record<number, number> = {};
        const statusByRequestId: Record<number, SHIFT_SLOT_REQUEST_STATUS> = {};

        slotRequests.forEach(({ id: slotId, requests }) => {
            const selectedRequestId = selectedRequestIds[slotId];

            // If no request for a slot is selected, skip it
            if (selectedRequestId) {
                requests.forEach(({ id: requestId }) => {
                    if (requestId === selectedRequestId) {
                        slotIdByRequestId[requestId] = slotId;
                        statusByRequestId[requestId] = SHIFT_SLOT_REQUEST_STATUS.APPROVED;
                    }
                });
            }
        });

        // TODO: Batch update requests
        for (const [requestIdStr, status] of Object.entries(statusByRequestId)) {
            const requestId = Number(requestIdStr);
            await updateSlotRequest({ slotId: slotIdByRequestId[requestId], requestId, data: { status } });
        }

        setSelectedRequestIds({});
    };

    return (
        <SegmentedModal
            sx={{
                width: 'fit-content',
                maxWidth: 'unset',
                minWidth: '480px',
            }}
            isOpen={isOpen}
            onClose={toggle}
            header="Review Open Shift Requests"
            actions={
                <ReviewOpenShiftsActions
                    selectedShiftsQuantity={Object.keys(selectedRequestIds).length}
                    onConfirm={handleConfirm}
                    isLoading={isPending}
                />
            }
        >
            <Box maxHeight="400px" overflow="auto" display="flex" flexDirection="column">
                {slotRequests.map((slot, idx) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            borderBottom: '1px solid #E5E5E5',
                            ...(idx !== 0 ? { paddingBlock: '16px' } : { paddingBottom: '16px' }),
                        }}
                        key={slot.id}
                        display="flex"
                        flexDirection="column"
                        gap="16px"
                    >
                        <Header slot={slot} />
                        {slot.requests.map((request) => (
                            <CaregiverCard
                                key={request.id}
                                request={request}
                                onClick={(requestId) => handleSelectedRequest(slot.id, requestId)}
                                selected={selectedRequestIds[slot.id] === request.id}
                                isLoading={isPending}
                            />
                        ))}
                    </Box>
                ))}
            </Box>
        </SegmentedModal>
    );
};

export default ReviewOpenShiftsModal;
