import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { useMatches, useNavigate } from 'react-router-dom';

import { isPlatformBlacklisted } from '~/components/Layout/PlatformGuard/platformGuardFilter';
import { isPostHogEnabled } from '~/helpers/PostHogHandler';
import { usePermissions } from '~/permissions/utils';
import { isSoftMatch } from '~/router';

import { BOTTOM_NAVIGATION_OPTIONS } from './shared';

const BottomNavigationPaper = styled(Paper)(({ theme }) =>
    theme.unstable_sx({
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        pb: 'env(safe-area-inset-bottom)',
        boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
    })
);

const MobileBottomNavigation = () => {
    const matches = useMatches();
    const navigate = useNavigate();

    const hasPermission = usePermissions();
    const posthog = usePostHog();

    const filteredOptions = BOTTOM_NAVIGATION_OPTIONS.filter((route) => {
        const { permission, featureFlag } = route;
        if (permission && !hasPermission(permission.type, permission.action)) return false;
        if (isPostHogEnabled && featureFlag && !posthog.isFeatureEnabled(featureFlag)) return false;
        if (isPlatformBlacklisted(route)) return false;
        return true;
    });

    const bottomNavigationValue = filteredOptions.find(({ path }) => isSoftMatch(matches, path))?.title;

    return (
        <BottomNavigationPaper>
            <BottomNavigation value={bottomNavigationValue} sx={{ bgcolor: '#fff' }} showLabels>
                {filteredOptions.map(({ title, path, icon: Icon }) => {
                    const isSelected = bottomNavigationValue === title;
                    return (
                        <BottomNavigationAction
                            key={title}
                            label={title}
                            icon={Icon ? <Icon variant={isSelected ? 'Bold' : 'Linear'} size={24} /> : undefined}
                            value={title}
                            onClick={() => navigate(path, { replace: true })}
                        />
                    );
                })}
            </BottomNavigation>
        </BottomNavigationPaper>
    );
};

export default MobileBottomNavigation;
