import { ArrowBack, ArrowBackIos, Menu } from '@mui/icons-material';
import { IconButton, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router';

import { useAppDispatch } from '~/constants/redux';
import { toggleMobileDrawer } from '~/redux/actions/ui';
import { useRouteHandle } from '~/router';

const HeaderContainer = styled(Stack)(({ theme: { palette } }) => ({
    flexShrink: 0,
    color: 'white',
    backgroundColor: palette.primary.main,
    height: '56px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '0 8px',
    zIndex: 10,
}));

const HeaderDrawerButton = () => {
    const navigate = useNavigate();
    const { backOne, backTo } = useRouteHandle();

    const dispatch = useAppDispatch();

    const BackIcon = isIOS ? ArrowBackIos : ArrowBack;

    const handleGoBackClick = () => {
        if (backOne) navigate(-1);
        else if (backTo) navigate(backTo, { replace: true });
    };

    const handleToggleDrawer = () => dispatch(toggleMobileDrawer());

    return backOne || backTo ? (
        <IconButton size="large" onClick={handleGoBackClick} color="inherit">
            <BackIcon />
        </IconButton>
    ) : (
        <IconButton size="large" onClick={handleToggleDrawer} color="inherit">
            <Menu />
        </IconButton>
    );
};

const HeaderTitle = () => {
    const { title } = useRouteHandle();

    return (
        <Typography fontSize="20px" fontWeight={700} lineHeight="32px">
            {title}
        </Typography>
    );
};

const HeaderActions = ({ actions }: { actions?: React.ReactNode }) => {
    if (!actions) return null;

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
            }}
        >
            {actions}
        </Stack>
    );
};

const Header = ({ actions }: { actions?: React.ReactNode }) => (
    <HeaderContainer>
        <HeaderDrawerButton />
        <HeaderTitle />
        <HeaderActions actions={actions} />
    </HeaderContainer>
);

export default Header;
