import { Stack, Typography, styled } from '@mui/material';
import React from 'react';

import TeamSelector from '../../../components/TeamSelector';

const HeaderContainer = styled(Stack)(() => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px 24px',
    backgroundColor: '#fff',
}));

const HeaderSection = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
});

const Header = () => {
    return (
        <HeaderContainer>
            <HeaderSection>
                <Typography variant="h6">Home</Typography>
            </HeaderSection>
            <HeaderSection>
                <TeamSelector />
            </HeaderSection>
        </HeaderContainer>
    );
};

export default Header;
