/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ComponentOverride } from '.';

export const MuiButton: ComponentOverride<'MuiButton'> = {
    defaultProps: {
        variant: 'contained',
        disableElevation: true,
    },
    styleOverrides: {
        root: {
            fontWeight: 700,
            lineHeight: 1.5,
            letterSpacing: 0,
            textTransform: 'none',
            borderRadius: 8,
        },
        contained: ({ theme: { palette } }) => ({
            backgroundColor: palette.primary[500],
            '&.Mui-disabled': {
                color: 'white',
                backgroundColor: palette.primary[100],
            },
        }),
        outlined: ({ theme: { palette } }) => ({
            color: palette.grey[900],
            backgroundColor: 'white',
            borderColor: palette.grey[100],
            '&.Mui-disabled': {
                color: palette.grey[200],
                borderColor: palette.grey[100],
            },
        }),
        containedError: ({ theme: { palette } }) => ({
            backgroundColor: palette.error[600],
            '&.Mui-disabled': {
                color: 'white',
                backgroundColor: palette.error[100],
            },
        }),
        outlinedError: ({ theme: { palette } }) => ({
            color: palette.error[600],
            backgroundColor: palette.error[50],
            borderColor: palette.error[100],
            '&.Mui-disabled': {
                color: palette.error[200],
                backgroundColor: palette.error[50],
                borderColor: palette.error[100],
            },
        }),
        containedSuccess: ({ theme: { palette } }) => ({
            backgroundColor: palette.primary[500],
            '&.Mui-disabled': {
                color: 'white',
                backgroundColor: palette.primary[100],
            },
        }),
        outlinedSuccess: ({ theme: { palette } }) => ({
            color: palette.primary[500],
            backgroundColor: palette.primary[50],
            borderColor: palette.primary[100],
            '&.Mui-disabled': {
                color: palette.primary[200],
                backgroundColor: palette.primary[50],
                borderColor: palette.primary[100],
            },
        }),
        sizeLarge: {
            fontSize: 16,
            lineHeight: '24px',
            padding: '14px 18px',
            '& svg': {
                fontSize: '24px !important',
            },
        },
        sizeMedium: {
            fontSize: 16,
            lineHeight: '20px',
            padding: '12px 16px',
            '& svg': {
                fontSize: '20px !important',
            },
        },
        sizeSmall: {
            fontSize: 14,
            lineHeight: '16px',
            padding: '10px 16px',
            '& svg': {
                fontSize: '16px !important',
            },
        },
    },
};
