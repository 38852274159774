import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetCommunitySettings } from '~/scheduling/api/types/community-settings/getCommunitySettings';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetCommunitySettingsResult = NonNullable<ReturnType<typeof useGetCommunitySettings>['data']>;

export const useGetCommunitySettings = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetCommunitySettings.Response>(
            `/scheduling/${branchId}/community-settings`,
            options
        );

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['community-settings', branchId], queryFn, staleTime: 300000 });
};
