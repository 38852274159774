import { Box, Theme, Typography, styled } from '@mui/material';
import React from 'react';

type ColumnType = 'regular' | 'neutral' | 'attention';

const mapTypeToStyle = (palette: Theme['palette']) => {
    return {
        header: {
            regular: {
                backgroundColor: palette.primary[50] as string,
                color: palette.primary[500] as string,
            },
            attention: {
                backgroundColor: palette.error[50] as string,
                color: palette.error[600] as string,
            },
            neutral: {
                backgroundColor: palette.grey[50],
                color: palette.grey[600],
            },
        } satisfies Record<ColumnType, React.CSSProperties>,
        slot: {
            regular: {
                backgroundColor: '#FFF',
                color: palette.grey[900],
            },
            attention: {
                backgroundColor: palette.error[25] as string,
                color: palette.grey[900],
            },
            neutral: {
                backgroundColor: palette.grey[25],
                color: palette.grey[900],
            },
        } satisfies Record<ColumnType, React.CSSProperties>,
    };
};

const RowStyled = styled(Box)<{ header?: boolean }>(({ theme, header: isHeader }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: isHeader ? 'none' : `1px solid ${theme.palette.grey[200]}`,
}));

const Header = styled(Typography)<{ type: ColumnType }>(({ theme, type }) => ({
    flex: 1,
    minHeight: '44px',
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    textTransform: 'capitalize',
    ...mapTypeToStyle(theme.palette).header[type ?? 'regular'],
}));

const Cell = styled(Header)<{ exceedsThreshold?: boolean }>(({ theme, type, exceedsThreshold }) => ({
    fontWeight: 400,
    ...mapTypeToStyle(theme.palette).slot[type ?? 'regular'],
    ...(exceedsThreshold
        ? {
              color: theme.palette.error[600],
              fontWeight: 600,
          }
        : {}),
}));

const Row = (props: {
    header?: boolean;
    cells: { value: string; exceedsThreshold?: boolean }[];
    schema: ColumnType[];
}) => {
    return (
        <RowStyled header={props.header}>
            {props.cells.map((cell, i) =>
                props.header ? (
                    <Header key={cell.value + i} type={props.schema[i]}>
                        {cell.value}
                    </Header>
                ) : (
                    <Cell key={cell.value + i} type={props.schema[i]} exceedsThreshold={cell.exceedsThreshold}>
                        {cell.value}
                    </Cell>
                )
            )}
        </RowStyled>
    );
};

type TableProps = {
    schema: ColumnType[];
    headers: string[];
    data: { value: string; exceedsThreshold: boolean }[][];
};

const Table = (props: TableProps) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Row header schema={props.schema} cells={props.headers.map((value) => ({ value }))} />
            {props.data.map((row, i) => (
                <Row key={i} schema={props.schema} cells={row} />
            ))}
        </Box>
    );
};

export default Table;
