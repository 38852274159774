import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import {
    BadgeMostAboveAndBeyond,
    BadgeMostCommunicate,
    BadgeMostResidentServed,
    BadgeTopPerformer,
} from '~/components/Svg';
import { pxToRem } from '~/components/theme/typography';
import { BadgeType } from '~/types/myRewards';

const BADGE_TYPE_TO_ICON: Record<BadgeType, (props) => JSX.Element> = {
    ABOVE_AND_BEYOND: BadgeMostAboveAndBeyond,
    MOST_COMMUNICATIVE: BadgeMostCommunicate,
    MOST_RESIDENTS_SERVED: BadgeMostResidentServed,
    TOP_PERFORMANCE: BadgeTopPerformer,
};

const BADGE_TYPE_TO_TEXT = {
    ABOVE_AND_BEYOND: 'Above and Beyond',
    MOST_COMMUNICATIVE: 'Most Communicative',
    MOST_RESIDENTS_SERVED: 'Most Residents Served',
    TOP_PERFORMANCE: 'Top Performer',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    icon: {
        // display: 'inline-flex',
        color: theme.palette.badge,
    },
}));

type Props = {
    badgeType: BadgeType;
};

const BadgeItem = ({ badgeType }: Props) => {
    const classes = useStyles();
    const Icon = BADGE_TYPE_TO_ICON[badgeType];

    return (
        <Box
            sx={{
                width: pxToRem(88),
                fontSize: pxToRem(12),
                textAlign: 'center',
            }}
        >
            <Box
                sx={{
                    borderRadius: pxToRem(88),
                    backgroundColor: '#F8F8F9',
                    display: 'flex',
                    alignItems: 'center',
                    width: pxToRem(88),
                    height: pxToRem(88),
                    justifyContent: 'center',
                    marginBottom: pxToRem(12),
                }}
            >
                <Icon className={classes.icon} size={48} viewBox="0 0 48 48" />
            </Box>
            {BADGE_TYPE_TO_TEXT[badgeType]}
        </Box>
    );
};

export default BadgeItem;
