import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useRewardsSummaryQuery } from '~/api/queries/caregiverRewards';
import { NotificationCenter } from '~/components/Shared/NotificationCenter';
import { GoldCoin } from '~/components/Svg/GoldCoin';
import { pxToRem } from '~/components/theme/typography';
import { usePermissions } from '~/permissions/utils';
import { useRouteHandle } from '~/router';
import { ReduxStore } from '~/types/redux';

import { DESKTOP_HEADER_HEIGHT, HeaderTitle } from './shared';

const HeaderContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(32),
        display: { xs: 'none', lg: 'flex' },
        alignItems: 'center',
        gap: pxToRem(16),
        width: '100%',
        height: DESKTOP_HEADER_HEIGHT,
    })
);

const RewardsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        color: '#006B75',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: pxToRem(4),
        px: pxToRem(8),
        pt: pxToRem(2),
        pb: pxToRem(4),
        border: '1px solid',
        borderRadius: pxToRem(48),
    })
);

const DesktopHeader = () => {
    const { title, backOne, backTo } = useRouteHandle();
    const navigate = useNavigate();

    const { userId, branchId, novuSubscriberId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const hasPermission = usePermissions();

    const { data: rewardsSummaryData } = useRewardsSummaryQuery(
        hasPermission('Community', 'read-reward') ? userId : undefined,
        branchId
    );

    const handleGoBackClick = () => {
        if (backOne) navigate(-1);
        else if (backTo) navigate(backTo, { replace: true });
    };

    return (
        <HeaderContainer>
            {(backOne || !!backTo) && (
                <IconButton size="large" aria-haspopup="false" onClick={handleGoBackClick} color="inherit">
                    <ArrowBackIcon />
                </IconButton>
            )}

            <HeaderTitle variant="h3">{title}</HeaderTitle>

            {hasPermission('Community', 'update-reward') && (
                <RewardsContainer onClick={() => navigate('/rewards', { replace: true })}>
                    <GoldCoin size={20} viewBox="0 0 20 20" />
                    <Typography variant="caption">{rewardsSummaryData?.totalPoints ?? ''}</Typography>
                </RewardsContainer>
            )}

            <NotificationCenter subscriberId={String(userId)} subscriberHash={novuSubscriberId} />
        </HeaderContainer>
    );
};

export default DesktopHeader;
