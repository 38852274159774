import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { Clock } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime, Duration, Interval } from 'luxon';
import React from 'react';

import { useChangeSlotTime } from '~/scheduling/api/queries/shift-slot/changeSlotTime';
import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import SegmentedModal from '~/scheduling/components/SegmentedModal';
import { buildShiftInterval } from '~/scheduling/utils/dates';

import {
    filledSlotEditPartialEndTimeAtom,
    filledSlotEditPartialStartTimeAtom,
    filledSlotModalSlotIdAtom,
    isFilledSlotEditPartialModalOpenAtom,
} from '../../../atoms';

const FilledSlotEditPartialParams = () => {
    const slotId = useAtomValue(filledSlotModalSlotIdAtom)!;

    const [startTime, setStartTime] = useAtom(filledSlotEditPartialStartTimeAtom);
    const [endTime, setEndTime] = useAtom(filledSlotEditPartialEndTimeAtom);

    const { data: roleData } = useGetRoles();
    const roleShiftById = roleData?.roleShiftById;

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !startTime || !endTime || !roleShiftById || !slotById) return null;

    const { shiftDay: shiftDayStr, startTime: slotStartTime, roleShiftId } = slotById.get(slotId)!;
    const shiftDay = DateTime.fromISO(shiftDayStr).setZone(slotStartTime.zone, { keepLocalTime: true });
    const { shiftStartTime, shiftEndTime } = roleShiftById.get(roleShiftId)!;

    // Build expanded shift interval to account for staff who work non-standard hours
    const shiftInterval = buildShiftInterval(shiftDay, shiftStartTime, shiftEndTime);
    const expandedShiftInterval = Interval.fromDateTimes(
        shiftInterval.start.minus({ hours: 2 }),
        shiftInterval.end.plus({ hours: 2 })
    );
    const splitInterval = expandedShiftInterval.splitBy(Duration.fromObject({ minutes: 30 })) as Interval<true>[];

    const startOptions = splitInterval.map(({ start }) => ({ value: start, label: start.toFormat('hh:mm a') }));
    const endOptions = splitInterval.map(({ end }) => ({ value: end, label: end.toFormat('hh:mm a') }));

    const onChangeStart = (newStartTime: DateTime<true>) => {
        setStartTime(newStartTime);

        // Clamp to the nearest end time greater than the new start time
        if (newStartTime >= endTime) setEndTime(endOptions.find(({ value }) => value > newStartTime)!.value);
    };

    const onChangeEnd = (newEndTime: DateTime<true>) => {
        setEndTime(newEndTime);

        // Clamp to the nearest start time less than the new end time
        if (newEndTime <= startTime) setStartTime(startOptions.findLast(({ value }) => value < newEndTime)!.value);
    };

    return (
        <Stack spacing="20px">
            <Stack spacing="4px">
                <Typography variant="body1" fontWeight={700}>
                    Partial Shift Interval
                </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="48px">
                    <FilledSlotEditPartialSelect options={startOptions} value={startTime} onChange={onChangeStart} />
                    <span>to</span>
                    <FilledSlotEditPartialSelect options={endOptions} value={endTime} onChange={onChangeEnd} />
                </Stack>
            </Stack>
        </Stack>
    );
};

const FilledSlotEditPartialSelect = ({
    options,
    value,
    onChange,
}: {
    options: { value: DateTime<true>; label: string }[];
    value: DateTime<true>;
    onChange: (value: DateTime<true>) => void;
}) => {
    const { palette } = useTheme();

    return (
        <FormControl
            size="medium"
            sx={{
                flex: 1,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '12px',
                    width: '20px',
                    height: '20px',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                }}
            >
                <Clock color={palette.grey[300]} weight="fill" fontSize="20px" />
            </Box>
            <Select
                value={value.toISO()}
                onChange={(e) => onChange(DateTime.fromISO(e.target.value, { setZone: true }) as DateTime<true>)}
                sx={{ pl: '24px' }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value.toMillis()} value={option.value.toISO()}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const FilledSlotEditPartialActions = () => {
    const slotId = useAtomValue(filledSlotModalSlotIdAtom)!;
    const toggle = useSetAtom(isFilledSlotEditPartialModalOpenAtom);

    const startTime = useAtomValue(filledSlotEditPartialStartTimeAtom);
    const endTime = useAtomValue(filledSlotEditPartialEndTimeAtom);

    const { mutateAsync: changeSlotTime, isPending } = useChangeSlotTime();

    if (!slotId || !startTime || !endTime) return null;

    return (
        <>
            <Button variant="outlined" onClick={toggle} disabled={isPending}>
                Cancel
            </Button>
            <Button
                onClick={async () => {
                    await changeSlotTime({ slotId, data: { startTime, endTime } });
                    toggle();
                }}
                disabled={isPending}
            >
                {isPending ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Save'}
            </Button>
        </>
    );
};

const FilledSlotEditPartialModal = () => {
    const [isOpen, toggle] = useAtom(isFilledSlotEditPartialModalOpenAtom);

    return (
        <SegmentedModal
            isOpen={isOpen}
            onClose={toggle}
            header="Edit Partial Shift"
            actions={<FilledSlotEditPartialActions />}
        >
            <FilledSlotEditPartialParams />
        </SegmentedModal>
    );
};

export default FilledSlotEditPartialModal;
