import { Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { useWindowSize } from '../../../../../components/useWindowSize';

import FilledSlotModal from './components/Modal/FilledSlotModal';
import MoveSlotModal from './components/Modal/MoveSlotModal';
import ReplaceSlotModal from './components/Modal/ReplaceSlotModal';
import WeekCalendarHeader from './components/WeekCalendarHeader';
import WeekGrid from './components/WeekGrid';

const Week = () => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);

    const [windowSize] = useWindowSize();

    const scrollBarWidth = useMemo(() => {
        if (!ref || !gridRef) return 0;
        return ref.clientWidth - gridRef.clientWidth;
    }, [ref, gridRef, windowSize]);

    return (
        <>
            <Stack ref={setRef} overflow="hidden">
                <WeekCalendarHeader scrollBarWidth={scrollBarWidth} />
                <WeekGrid ref={setGridRef} />
            </Stack>

            <FilledSlotModal />
            <MoveSlotModal />
            <ReplaceSlotModal />
        </>
    );
};

export default Week;
