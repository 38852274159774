import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { api } from '~/api';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';
import { BranchResponse } from '~/types/branches';
import { ReduxStore } from '~/types/redux';

export const useBranchQuery = (branchId?: number) => {
    const token = useToken();

    return useErrorHandledQuery(
        ['branch', branchId],
        async (): Promise<BranchResponse> =>
            (
                await api.get(`/branches/${branchId}`, {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!branchId, staleTime: 300000 }
    );
};

export const useBranchShifts = (branchId?: number) => {
    const { data } = useBranchQuery(branchId);

    return data?.shifts || [];
};

export const withBranchShiftSelector = (WrappedComponent: any) => (props: any) => {
    const { branchId: defaultBranchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [branchId, setBranchId] = useState<number | undefined>(defaultBranchId);

    const branchShifts = useBranchShifts(branchId);

    if (branchShifts.length === 0) {
        return null;
    }

    return <WrappedComponent {...props} setSelectedBranchId={setBranchId} selectedBranchId={branchId} />;
};
