import {
    THEME_APP_COLORS,
    THEME_COLOR_ACTIONS,
    THEME_COLOR_ACTIVITY_INDICATOR,
    THEME_COLOR_BADGE,
    THEME_COLOR_BLUE,
    THEME_COLOR_BORDER,
    THEME_COLOR_CHARTS,
    THEME_COLOR_COMMON,
    THEME_COLOR_ERROR,
    THEME_COLOR_GREEN,
    THEME_COLOR_GREY,
    THEME_COLOR_INFO,
    THEME_COLOR_NAVBAR,
    THEME_COLOR_NAVIGATION,
    THEME_COLOR_ORANGE,
    THEME_COLOR_PRIMARY,
    THEME_COLOR_SECONDARY,
    THEME_COLOR_SELECTOR,
    THEME_COLOR_SHADOW,
    THEME_COLOR_SOCIAL_MEDIA,
    THEME_COLOR_SUCCESS,
    THEME_COLOR_TABLE_HEADER,
    THEME_COLOR_WARNING,
    THEME_COLOR_YELLOW,
} from '~/constants/theme';
import { CustomPalette } from '~/types/theme';

const palette: CustomPalette = {
    action: { ...THEME_COLOR_ACTIONS },
    app: { ...THEME_APP_COLORS },
    badge: THEME_COLOR_BADGE,
    black: THEME_COLOR_COMMON.black,
    blue: { ...THEME_COLOR_BLUE },
    border: THEME_COLOR_BORDER,
    charts: THEME_COLOR_CHARTS,
    common: { ...THEME_COLOR_COMMON },
    error: { ...THEME_COLOR_ERROR },
    green: { ...THEME_COLOR_GREEN },
    grey: { ...THEME_COLOR_GREY },
    info: { ...THEME_COLOR_INFO },
    navbar: { ...THEME_COLOR_NAVBAR },
    navigation: { ...THEME_COLOR_NAVIGATION },
    orange: { ...THEME_COLOR_ORANGE },
    primary: { ...THEME_COLOR_PRIMARY },
    secondary: { ...THEME_COLOR_SECONDARY },
    shadow: THEME_COLOR_SHADOW,
    socialMedia: { ...THEME_COLOR_SOCIAL_MEDIA },
    success: { ...THEME_COLOR_SUCCESS },
    tableHeader: THEME_COLOR_TABLE_HEADER,
    warning: { ...THEME_COLOR_WARNING },
    yellow: THEME_COLOR_YELLOW,
    selector: THEME_COLOR_SELECTOR,
    activityIndicator: THEME_COLOR_ACTIVITY_INDICATOR,
};

export default palette;
