import { Box, Button } from '@mui/material';
import { DesktopTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getNearbyShiftsAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { useCompanyCarePlansListQuery } from '~/api/queries/companyCarePlans';
import { useResidentsQuery } from '~/api/queries/residents';
import { useCreateDailyTask } from '~/api/queries/tasks/dailyTasks';
import { useUserFraudStatusQuery } from '~/api/queries/user';
import langDictionary from '~/app-strings';
import { CustomAutocomplete, CustomDialog } from '~/components/Custom';
import { ShiftSelector } from '~/components/Filtering';
import Loading from '~/components/Shared/Loading';
import UnscheduledTasksConfirmation from '~/components/Shared/NurseCallsDialog/UnscheduledTasksConfirmation';
import { pxToRem } from '~/components/theme/typography';
import { useAppDispatch } from '~/constants/redux';
import { getDateInUtc } from '~/lib/date';
import { usePermissions } from '~/permissions/utils';
import { showAlert } from '~/redux/actions/messages';
import { AddTask, DailyTasksCreateParams } from '~/types/dailyTasks';
import { DropdownStructure, DropdownValue } from '~/types/inputs';
import { MessageProps } from '~/types/messages';
import { ReduxStore } from '~/types/redux';
import { ResidentsListReadParams } from '~/types/residents';

type UnscheduledTaskDialogStep = 'ADD' | 'CONFIRM';

type Props = {
    isOpen: boolean;
    defaultResidentId?: number;
    canChangeResident: boolean;
    timezone: string;
    currentShift: number;
    onSubmit: () => void;
    onClose: () => void;
};

const UnscheduledTaskDialog = (props: Props) => {
    const { isOpen, defaultResidentId, canChangeResident, timezone, currentShift, onSubmit, onClose } = props;

    const posthog = usePostHog();

    const { home, shared, error: errorDictionary } = langDictionary;
    const [taskOptions, setTaskOptions] = useState<DropdownStructure[]>([]);
    const [residentOptions, setResidentOptions] = useState<DropdownStructure[]>([]);
    const [queryParams, setQueryParams] = useState<ResidentsListReadParams | null>(null);
    const [currentStep, setCurrentStep] = useState<UnscheduledTaskDialogStep>('ADD');
    const [isCreateLoading, setIsCreateLoading] = useState(false);

    const dispatch = useAppDispatch();
    const createTaskMutation = useCreateDailyTask();

    const hasPermission = usePermissions();
    const updateResidentAction = hasPermission('Community', 'update-resident-action');

    const { branchId, userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const branchShifts = useBranchShifts(branchId);

    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);
    const shiftDayDateByShiftId = {};
    nearbyShifts.forEach(({ shift: { id: shiftId }, shiftDayDate }) => {
        shiftDayDateByShiftId[shiftId] = shiftDayDate;
    });

    const initialForm: AddTask = {
        residentId: defaultResidentId,
        date: shiftDayDateByShiftId[currentShift],
        shift: currentShift,
        startTime: null,
        endTime: null,
    };

    const [form, setForm] = useState<AddTask>(initialForm);

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery(queryParams);

    const { data: companyCarePlansList } = useCompanyCarePlansListQuery(branchId!);

    const { data: userFraud } = useUserFraudStatusQuery(branchId, currentShift);

    useEffect(() => {
        if (defaultResidentId) {
            setForm((prevForm) => ({
                ...prevForm,
                residentId: defaultResidentId,
            }));
        }
    }, [defaultResidentId]);

    useEffect(() => setForm((prevForm) => ({ ...prevForm, shift: currentShift })), [currentShift]);

    const getResidentFirstNameById = (id?: number) =>
        residentsList?.find((resident) => resident.residentId === id)?.firstName;

    const resetDialog = () => {
        onClose();
        setForm(initialForm);
        setCurrentStep('ADD');
    };

    const handleShiftChange = (newShiftId: string) => {
        setForm({
            ...form,
            date: shiftDayDateByShiftId[newShiftId],
            shift: Number(newShiftId),
        });
    };

    const handleDropdownChange = (fieldName: string) => (optionValue: DropdownValue) => {
        setForm({
            ...form,
            [fieldName]: optionValue,
        });
    };

    const handleStartTimeChange = (newStartTime: Date | null) => {
        setForm({
            ...form,
            startTime: newStartTime,
        });
    };

    const handleEndTimeChange = (newEndTime: Date | null) => {
        setForm({
            ...form,
            endTime: newEndTime,
        });
    };

    const handleSubmit = async () => {
        if (!updateResidentAction) {
            const message: MessageProps = {
                open: true,
                message: errorDictionary.onlyDirectorsCareGivers,
                alertSeverity: 'error',
                status: 400,
            };

            dispatch(showAlert(message));
            return;
        }

        let created = false;

        // Check if is missing any form value.
        const isFormIncomplete = !form.companyCarePlanId || !form.residentId || !form.shift || !form.date;

        // If there is any missing value, throw an error.
        if (isFormIncomplete) {
            // Set the message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: home.missingParamsForAddedTask,
                alertSeverity: 'error',
                status: 400,
            };

            dispatch(showAlert(message));
        } else if (!isFormIncomplete) {
            // Define the params to send to the API.
            const params: DailyTasksCreateParams = {
                branchId: Number(branchId),
                residentId: Number(form.residentId),
                companyCarePlanId: Number(form.companyCarePlanId),
                date: form.date,
                shiftId: form.shift,
            };

            if (!branchId) {
                throw new Error('no branch specified');
            }

            setIsCreateLoading(true);

            // Trigger the action for creating the Added Task.
            const { status } = await createTaskMutation.mutateAsync(params);
            created = status === 201 ? true : false;

            setIsCreateLoading(false);
        }

        if (created) onSubmit(); // This is generally used for triggering the party animation

        resetDialog();
    };

    useEffect(() => {
        if (!residentsList?.length) {
            // Set the params to send to the API.
            const params = {
                branchId: branchId!,
            };

            setQueryParams(params);
        }
    }, []);

    useEffect(() => {
        if (companyCarePlansList?.length) {
            // Define the Tasks options to show.
            const newOptions: DropdownStructure[] = companyCarePlansList
                .filter((companyCarePlan) => companyCarePlan.isAllieCarePlan) // Simpler, curated list of PRNs
                .map((companyCarePlan) => ({
                    label: companyCarePlan.taskName,
                    value: companyCarePlan.companyCarePlanId,
                }));
            setTaskOptions(newOptions);

            // Initialize the selected Task with the first one from the list.
            if (newOptions.length) {
                setForm({
                    ...form,
                    companyCarePlanId: newOptions[0].value,
                });
            }
        }
    }, [JSON.stringify(companyCarePlansList)]);

    useEffect(() => {
        if (residentsList?.length) {
            // Define the Residents options to show.
            const newOptions: DropdownStructure[] = residentsList.map((resident) => ({
                label: `${resident.firstName} ${resident.lastName} - Apt ${resident.roomNumber ?? 'not set'}`,
                value: resident.residentId,
            }));

            newOptions.sort((a, b) => {
                const firstNameA = a.label.split(' ')[0];
                const firstNameB = b.label.split(' ')[0];
                return firstNameA.localeCompare(firstNameB);
            });
            setResidentOptions(newOptions);

            // Initialize the selected Resident with the first one from the list if the default value is empty
            if (defaultResidentId === undefined) {
                setForm({
                    ...form,
                    residentId: newOptions[0].value,
                });
            }
        }
    }, [JSON.stringify(residentsList)]);

    // TODO: create a fraud status specifically for this.
    const shouldShowFriction = [
        126, 305, 270, 485, 305, 297, 253, 533, 525, 407, 343, 284, 396, 398, 410, 325, 267, 672, 263, 411,
    ].includes(userId);

    const isAddButtonDisabled =
        !form.residentId || !form.companyCarePlanId || (shouldShowFriction && (!form.startTime || !form.endTime));

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    const titleByStep = {
        ADD: home.addTask,
    };

    const contentByStep = {
        ADD: (
            <>
                <ShiftSelector
                    width="100%"
                    selectedShiftId={form.shift}
                    currentShiftId={currentShift}
                    shiftOptions={nearbyShifts}
                    onChange={handleShiftChange}
                    fullWidth
                    isDialog
                />
                {canChangeResident && (
                    <CustomAutocomplete
                        label={shared.resident}
                        value={form.residentId}
                        options={residentOptions}
                        fullWidth
                        required
                        onChange={handleDropdownChange('residentId')}
                    />
                )}
                <CustomAutocomplete
                    label={home.serviceProvided}
                    value={form.companyCarePlanId}
                    options={taskOptions}
                    fullWidth
                    required
                    onChange={handleDropdownChange('companyCarePlanId')}
                />
                {shouldShowFriction && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: pxToRem(8),
                            }}
                        >
                            <DesktopTimePicker
                                slotProps={{
                                    textField: {
                                        sx: {
                                            flexGrow: 1,
                                        },
                                    },
                                }}
                                value={form.startTime}
                                label="Start Time"
                                onChange={handleStartTimeChange}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                            />
                            <DesktopTimePicker
                                slotProps={{
                                    textField: {
                                        sx: {
                                            flexGrow: 1,
                                        },
                                    },
                                }}
                                label="End Time"
                                value={form.endTime}
                                onChange={handleEndTimeChange}
                                minTime={form.startTime ?? undefined}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                )}
            </>
        ),
        CONFIRM: (
            <Box sx={{ '#dialog-title': { paddingTop: `${pxToRem(24)} !important` } }}>
                <UnscheduledTasksConfirmation
                    loading={isCreateLoading}
                    onCancel={() => {
                        posthog.capture('unscheduled_task_confirmation:cancel_button_click', {
                            user_id: userId,
                            care_plan_id: form.companyCarePlanId,
                        });

                        resetDialog();
                    }}
                    onConfirm={() => {
                        posthog.capture('unscheduled_task_confirmation:confirm_button_click', {
                            user_id: userId,
                            care_plan_id: form.companyCarePlanId,
                        });

                        handleSubmit();
                    }}
                    residentFirstName={getResidentFirstNameById(form.residentId) || ''}
                    completedTasksNumber={1}
                />
            </Box>
        ),
    };

    const actionsByStep = {
        ADD: (
            <>
                <Button variant="outlined" color="secondary" onClick={resetDialog}>
                    {shared.cancel}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isAddButtonDisabled || isCreateLoading}
                    onClick={(userFraud?.status ?? true) ? () => setCurrentStep('CONFIRM') : handleSubmit}
                >
                    {shared.add}
                </Button>
            </>
        ),
    };

    return (
        <CustomDialog
            closeable
            open={isOpen}
            title={titleByStep[currentStep]}
            width="100%"
            onClose={resetDialog}
            content={contentByStep[currentStep]}
            actions={actionsByStep[currentStep]}
        />
    );
};

export default UnscheduledTaskDialog;
