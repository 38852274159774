import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, IconButton, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowHeightReturnValue,
    GridRowParams,
} from '@mui/x-data-grid-old';
import { mapValues } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { pxToRem } from '~/components/theme/typography';
import { Section } from '~/pages/OperationsDashboard/constants';
import { SectionDetail, SectionsWithCollapsebleDetailItemTypes } from '~/types/operationsDashboard';

import { DetailsRow } from './DetailsRow';
import { formatDateTime, isChangeOfConditionDetail } from './constants';

export type DataRow = {
    id: string;
    residentName: string;
    residentRoomNumber: string;
    details: SectionDetail[];
    isDetailRow?: boolean;
};

type BranchStatsTableWithCollapsibleRowsProps = {
    rows: DataRow[];
    dataType: Section;
    dynamicColumns: {
        field: string;
        headerName: string;
    }[];
    renderDateSelector: () => JSX.Element | null;
};

const BranchStatsTableWithCollapsibleRows = ({
    rows,
    dataType,
    dynamicColumns,
    renderDateSelector,
}: BranchStatsTableWithCollapsibleRowsProps) => {
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

    const [expanded, setExpanded] = useState(false);
    const [openRows, setOpenRows] = useState<Record<string, boolean>>({});

    React.useEffect(() => {
        setOpenRows(rows.reduce<Record<string, boolean>>((agg, row) => ({ ...agg, [row.id]: false }), {}));
    }, [JSON.stringify(rows)]);

    const handleToggleAll = () => {
        setOpenRows((prev) => mapValues(prev, () => !expanded));

        setExpanded((prev) => !prev);
    };

    // Toggle row visibility
    const handleRowClick = (id: string) => {
        setOpenRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    // Add a details row below each original row
    const rowsWithDetails = useMemo(
        () =>
            rows.reduce<DataRow[]>((acc, row) => {
                acc.push(row);

                if (openRows[row.id]) {
                    acc.push({
                        ...row,
                        id: `${row.id}-details`,
                        isDetailRow: true,
                    });
                }

                return acc;
            }, []),
        [openRows, rows]
    );

    // Create dynamic columns that will be added to the table
    const dynamicCols = dynamicColumns.map(({ field, headerName }) => ({
        field,
        headerName,
        width: isLargeScreen ? 220 : 180,
        renderCell: (params: GridRenderCellParams) => (
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                    lineHeight: 1.5,
                }}
            >
                {field.toLowerCase().includes('date') ? formatDateTime(params.value as string) : params.value}
            </Box>
        ),
    }));

    const colsNumber = 2 + dynamicColumns.length;

    const residentNameCol = {
        field: 'residentName',
        headerName: 'Resident',
        flex: 1,
        minWidth: isLargeScreen ? 220 : 200,
        renderCell: ({ row }: GridRenderCellParams<DataRow>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: pxToRem(4) }}>
                <Link component={RouterLink} to={`/residents/${row.id}/deep-dive`}>
                    <Box
                        component="span"
                        sx={{
                            fontWeight: 500,
                            overflow: 'hidden',
                            textWrap: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                            lineHeight: 1.5,
                            py: { xs: pxToRem(10), lg: pxToRem(12) },
                        }}
                    >
                        {row.residentName}
                    </Box>
                </Link>
                <Box
                    component="span"
                    sx={{
                        fontWeight: 500,
                        fontSize: { xs: pxToRem(12), lg: pxToRem(14) },
                        lineHeight: 1.45,
                        color: '#6F6F79',
                    }}
                >
                    {`(${row.residentRoomNumber ?? 'Apt not set'})`}
                </Box>
                {row.details.some((detail) => isChangeOfConditionDetail(detail) && detail.isNewEvent) && (
                    <Box
                        sx={{
                            fontWeight: 600,
                            px: pxToRem(4),
                            py: pxToRem(2),
                            borderRadius: pxToRem(3),
                            fontSize: { xs: pxToRem(12), lg: pxToRem(14) },
                            color: '#FFF',
                            bgcolor: '#FA9C7A',
                        }}
                    >
                        New
                    </Box>
                )}
            </Box>
        ),
    };

    const expandCol = {
        field: 'expand',
        headerName: '',
        sortable: false,
        width: isLargeScreen ? 50 : 40,
        // Added to make details row span all columns
        colSpan: ({ row }: GridRenderCellParams<DataRow>) => (row.isDetailRow ? colsNumber : 1),
        renderCell: (params: GridRenderCellParams<DataRow>) =>
            params.row.isDetailRow ? (
                // If row is a details row, render the details row component
                <DetailsRow
                    detailsData={params.row.details}
                    dataType={dataType as SectionsWithCollapsebleDetailItemTypes}
                />
            ) : (
                <IconButton aria-label="expand row" size="small" disableRipple>
                    {openRows[params.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            ),
    };

    const columns: GridColDef[] = [expandCol, residentNameCol, ...dynamicCols];

    const defaultSortColumn = dynamicColumns.length > 0 ? dynamicColumns[0].field : null;

    const sortByDate = dynamicColumns.length > 0 ? dynamicColumns[0].field.includes('date') : false;

    if (rows.length === 0) {
        return (
            <Typography
                sx={{
                    color: '#2C2D3B',
                    fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                }}
            >
                No data available
            </Typography>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    verticalAlign: 'middle',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mr: pxToRem(24),
                }}
            >
                {renderDateSelector()}
                <Button onClick={handleToggleAll} variant="outlined">
                    {expanded ? 'Collapse all' : 'Expand all'}
                </Button>
            </Box>
            <DataGrid
                autoHeight
                hideFooter
                rows={rowsWithDetails}
                columns={columns}
                columnHeaderHeight={40}
                onRowClick={(params: GridRowParams<DataRow>) => {
                    if (!params.row.isDetailRow) {
                        handleRowClick(params.row.id);
                    }
                }}
                getRowHeight={() => 'auto' as GridRowHeightReturnValue}
                disableRowSelectionOnClick
                disableColumnMenu
                getRowClassName={(params: GridRowParams<DataRow>) => (params.row.isDetailRow ? 'details-row' : '')}
                initialState={{
                    sorting: {
                        sortModel: sortByDate && defaultSortColumn ? [{ field: defaultSortColumn, sort: 'desc' }] : [],
                    },
                }}
                sx={{
                    border: 'none',
                    height: pxToRem(320),
                    '& .MuiDataGrid-columnHeader': {
                        color: '#9B9CA2',
                        fontSize: { xs: pxToRem(12), lg: pxToRem(14) },
                        '&:focus-within': {
                            outline: 'none',
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        fontWeight: 700,
                        fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                    },
                    '& .MuiDataGrid-iconButtonContainer': {
                        visibility: 'visible',
                        ml: { xs: 0, md: pxToRem(6) },
                    },
                    '& .MuiDataGrid-sortIcon': {
                        opacity: '.3 !important',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none !important',
                    },
                    '& .details-row.Mui-hovered': {
                        backgroundColor: 'inherit',
                    },
                    '& .details-row:hover': {
                        backgroundColor: 'inherit',
                    },
                }}
            />
        </>
    );
};

export default BranchStatsTableWithCollapsibleRows;
