/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ComponentOverride } from '.';

export const MuiTooltip: ComponentOverride<'MuiTooltip'> = {
    defaultProps: {
        arrow: true,
    },
    styleOverrides: {
        arrow: {
            color: 'white',
        },
        tooltip: {
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        },
    },
};
