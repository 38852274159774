import { Typography, useTheme } from '@mui/material';
import { DateTime, WeekdayNumbers } from 'luxon';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PiWarningCircleFill } from 'react-icons/pi';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { getWeekDays } from '~/scheduling/utils/dates';

import { mapWeeklySchedule } from '../../mapStaff';
import GridContainer from '../shared/GridContainer';

import { ScheduleDay } from './ScheduleDay';
import { StaffScheduleProps } from './shared';

export const WeeklySchedule = ({ form, schedule, scheduleIndex, isSaving, disabled }: StaffScheduleProps) => {
    const { palette } = useTheme();
    const { data: communitySettings } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const { data: roleData } = useGetRoles();
    const scheduleData = React.useMemo(
        () =>
            schedule && firstDayOfWeek && roleData
                ? mapWeeklySchedule(schedule, firstDayOfWeek, roleData.roleShiftById)
                : undefined,
        [schedule, firstDayOfWeek, roleData]
    );

    return (
        <Controller
            name={`schedules.${scheduleIndex}.shifts`}
            control={form.control}
            rules={{
                validate: (data) =>
                    data
                        ?.flat()
                        .slice(0, 7)
                        .some(
                            ({ enabled, staffRoleId, locationId, staffRoleShiftId }) =>
                                enabled && staffRoleId && locationId && staffRoleShiftId
                        )
                        ? undefined
                        : 'At least 1 shift needs to be filled out',
            }}
            render={({ fieldState: { error } }) => (
                <>
                    {error && (
                        <Typography
                            variant="body1"
                            color={palette.error[500] as string}
                            fontWeight={400}
                            fontSize="12px"
                            display="flex"
                            gap="4px"
                            alignItems="center"
                        >
                            <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                            {error.message}
                        </Typography>
                    )}
                    <GridContainer minmax={240}>
                        {scheduleData
                            ? scheduleData.map((scheduleDay, index) => (
                                  <ScheduleDay
                                      key={index + 'weekly'}
                                      scheduleIndex={scheduleIndex}
                                      title="day"
                                      form={form}
                                      dayIndex={index}
                                      scheduleDay={scheduleDay}
                                      weekDay={DateTime.fromObject({
                                          weekday: scheduleDay.dayIndex as WeekdayNumbers,
                                      }).toFormat('cccc')}
                                      withSwitch
                                      disabled={disabled || isSaving}
                                  />
                              ))
                            : getWeekDays(DateTime.now(), firstDayOfWeek ?? 7).map(({ weekday }, index) => (
                                  <ScheduleDay
                                      key={index + 'weekly'}
                                      scheduleIndex={scheduleIndex}
                                      title="day"
                                      form={form}
                                      dayIndex={index}
                                      weekDay={DateTime.fromObject({ weekday }).toFormat('cccc')}
                                      withSwitch
                                      disabled={isSaving}
                                  />
                              ))}
                    </GridContainer>
                </>
            )}
        />
    );
};
