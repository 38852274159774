import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PiWarningCircleFill } from 'react-icons/pi';

import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import { mapFourOnTwoOffSchedule } from '../../mapStaff';
import GridContainer from '../shared/GridContainer';

import { ScheduleDay } from './ScheduleDay';
import { StaffScheduleProps } from './shared';

export const FourOnTwoOffSchedule = ({ form, schedule, scheduleIndex, isSaving, disabled }: StaffScheduleProps) => {
    const { palette } = useTheme();

    const { data: roleData } = useGetRoles();
    const scheduleData = React.useMemo(
        () => schedule && roleData && mapFourOnTwoOffSchedule(schedule, roleData.roleShiftById),
        [schedule, roleData]
    );

    return (
        <Controller
            name={`schedules.${scheduleIndex}.shifts`}
            control={form.control}
            rules={{
                validate: (data) =>
                    data
                        ?.flat()
                        .slice(0, 4)
                        .some(
                            ({ staffRoleId, locationId, staffRoleShiftId }) =>
                                !staffRoleId || !locationId || !staffRoleShiftId
                        )
                        ? 'All four shifts need to be filled out'
                        : undefined,
            }}
            render={({ fieldState: { error } }) => (
                <>
                    {error && (
                        <Typography
                            variant="body1"
                            color={palette.error[500] as string}
                            fontWeight={400}
                            fontSize="12px"
                            display="flex"
                            gap="4px"
                            alignItems="center"
                        >
                            <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                            {error.message}
                        </Typography>
                    )}
                    <GridContainer minmax={240}>
                        {scheduleData
                            ? scheduleData.map((scheduleDay, index) => (
                                  <ScheduleDay
                                      key={index + 'fourOnTwoOff'}
                                      scheduleIndex={scheduleIndex}
                                      title="day"
                                      form={form}
                                      dayIndex={index}
                                      scheduleDay={scheduleDay}
                                      disabled={disabled || isSaving}
                                  />
                              ))
                            : [...(new Array(4) as unknown[])].map((_, index) => (
                                  <ScheduleDay
                                      key={index + 'fourOnTwoOff'}
                                      scheduleIndex={scheduleIndex}
                                      title="day"
                                      form={form}
                                      dayIndex={index}
                                      disabled={isSaving}
                                  />
                              ))}
                    </GridContainer>
                </>
            )}
        />
    );
};
