import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Loading from '~/components/Shared/Loading';
import { pxToRem } from '~/components/theme/typography';
import { Section } from '~/pages/OperationsDashboard/constants';
import { setSelectedStartDate } from '~/redux/actions/opsDashboard';
import { OperationsLevel, OperationsPeriod } from '~/types/operationsDashboard';
import { ReduxStore } from '~/types/redux';

import MobileDashboardTabPanels from '../Mobile/MobileDashbardTabPanels';
import { SectionDataModule } from '../Module/SectionDataModule';

import { getSectionsToShow, useModuleData } from './helpers';

type Props = {
    mobileTabSelected: 0 | 1;
    region: string;
    branch: string;
    careType: string;
    operationsPeriod: OperationsPeriod;
    hasCompanyAccess: boolean;
    hasRegions: boolean;
    hasBranches: boolean;
    onMobileTabChange: (newValue: number) => void;
};

export const OperationsDashboardMain = ({
    mobileTabSelected,
    region,
    branch,
    careType,
    operationsPeriod,
    hasCompanyAccess,
    hasRegions,
    hasBranches,
    onMobileTabChange,
}: Props) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const { sessionData } = useSelector((state: ReduxStore) => state.session);
    const { selectedStartDate, selectedStartDatePeriod } = useSelector((state: ReduxStore) => state.opsDashboard);

    const dispatch = useDispatch();

    let level: OperationsLevel;

    if (hasRegions && hasCompanyAccess && region === 'all') {
        level = 'company';
    } else if (hasRegions && branch === 'all') {
        level = 'region';
    } else {
        level = 'branch';
    }

    const moduleData = useModuleData(level, operationsPeriod, sessionData, region, branch, careType, hasCompanyAccess);
    const sectionsToShow = getSectionsToShow(level, moduleData);

    // reset selected start date to the second to last date period every time the period changes
    // don't reset it if the dateOptions get reloaded (changing care type, region etc.), but make sure it is set on first load
    useEffect(() => {
        if (selectedStartDatePeriod !== operationsPeriod || selectedStartDate === undefined) {
            dispatch(
                setSelectedStartDate(moduleData.dateOptions ? moduleData.dateOptions[1] : undefined, operationsPeriod)
            );
        }
    }, [operationsPeriod, moduleData.dateOptions]);

    if (moduleData.status === 'loading') {
        return <Loading />;
    }

    return (
        <Box
            sx={{
                minHeight: '100vh',
                backgroundColor: { xs: 'common.white', lg: 'app.grey.main' },
                mt: { xs: pxToRem(hasRegions || hasBranches ? 198 : 130), lg: 0 },
                p: { lg: pxToRem(32) },
            }}
        >
            {isMobile ? (
                <MobileDashboardTabPanels
                    moduleData={moduleData}
                    sectionsToShow={sectionsToShow}
                    mobileTabSelected={mobileTabSelected}
                    onMobileTabChange={onMobileTabChange}
                    region={region}
                    branch={branch}
                    careType={careType}
                    operationsPeriod={operationsPeriod}
                    level={level}
                />
            ) : (
                <Box
                    sx={{
                        display: 'grid',
                        gap: pxToRem(32),
                    }}
                >
                    {Object.keys(sectionsToShow).map((section) => (
                        <SectionDataModule
                            key={section}
                            moduleData={moduleData}
                            dataType={section as Section}
                            level={level}
                            operationsPeriod={operationsPeriod}
                            regionId={region}
                            branchId={branch}
                            careType={careType}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};
