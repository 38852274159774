import { groupBy, range } from 'lodash';
import { DateTime } from 'luxon';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';

import { GetRolesResult } from '~/scheduling/api/queries/staff-roles/getRoles';
import { CreateStaff } from '~/scheduling/api/types/staff/createStaff';
import { CreateStaffSchedule } from '~/scheduling/api/types/staff/createStaffSchedule';
import { GetStaff } from '~/scheduling/api/types/staff/getStaff';
import { UpdateStaff } from '~/scheduling/api/types/staff/updateStaff';
import { getWeekDaysOrder } from '~/scheduling/utils/dates';

import { StaffList } from '../types';

import { StaffDetailsFormFields } from './types';

export const mapStaff = (
    staff: GetStaff.Staff,
    firstDayOfWeek: number,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffDetailsFormFields => {
    return {
        name: staff.name,
        type: staff.staffType,
        roles: staff.roles.map((role) => role.staffRoleId),
        mainlyServe: staff.primaryLocation.id.toString(),
        email: staff.email,
        phone: staff.phoneNumber.replace(/^\+1|[\s-()]/g, ''),
        homeAddress: staff.address,
        state: staff.state,
        city: staff.city,
        zipCode: staff.zipcode,
        schedules: staff.schedules?.map((schedule) => mapScheduleConfig(schedule, firstDayOfWeek, roleShiftById)),
    };
};

const mapReadStaffScheduleCustomShiftTimes = (
    shift: GetStaff.Shift,
    roleShiftById: GetRolesResult['roleShiftById']
) => {
    const roleShift = roleShiftById.get(shift.staffRoleShiftId)!;
    const customStartTime = DateTime.fromISO(shift.customStartTime ?? roleShift.shiftStartTime);
    let customEndTime = DateTime.fromISO(shift.customEndTime ?? roleShift.shiftEndTime);

    // Some shifts are overnight, so we need to add a day to the end time
    if (customStartTime > customEndTime) customEndTime = customEndTime.plus({ days: 1 });

    return {
        customStartTime: +customStartTime,
        customEndTime: +customEndTime,
    };
};

export const mapScheduleConfig = (
    schedule: GetStaff.Schedule,
    firstDayOfWeek: number,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffList.ScheduleConfig => {
    let shifts: StaffList.ScheduleConfigData[][] = [];
    if (schedule.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY) {
        // for this schedule type, the backend will only send the data
        // from the enabled days, so the front end need to filled out
        // the array with empty fields it self, and that's what the mapper
        // following mapper does.
        shifts = [mapWeeklySchedule(schedule, firstDayOfWeek, roleShiftById)];
    } else if (schedule.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY) {
        shifts = mapBiweeklySchedule(schedule, firstDayOfWeek, roleShiftById);
    } else {
        shifts = [
            schedule.shifts.map((shift) => ({
                enabled: true,
                dayOfWeek: shift.weekday?.toString(),
                staffRoleShiftId: shift.staffRoleShiftId.toString(),
                staffRoleId: shift.staffRoleId.toString(),
                locationId: shift.locationId.toString(),
                ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
            })),
        ];
    }

    return {
        type: schedule.type,
        startDate: DateTime.fromISO(schedule.startAt),
        shifts,
    };
};

export const mapBaylorSchedule = (
    schedule: GetStaff.Schedule,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffList.BaylorScheduleData[] => {
    const data = schedule.shifts.slice(0, 4).map((shift) => ({
        dayOfWeek: shift.weekday!.toString(),
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const fillWithEmpty = [
        ...data,
        ...Array<StaffList.BaylorScheduleData>(4 - data.length).fill({
            dayOfWeek: '',
            staffRoleShiftId: '',
            staffRoleId: '',
            locationId: '',
        }),
    ];

    return fillWithEmpty;
};

export const mapWeeklySchedule = (
    schedule: GetStaff.Schedule,
    firstDayOfWeek: number,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffList.WeekScheduleData[] => {
    const shifts = schedule.shifts.map<StaffList.WeekScheduleData>((shift) => ({
        enabled: true,
        dayIndex: shift.weekday!,
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const disabledField: StaffList.WeekScheduleData = {
        enabled: false,
        dayIndex: 0,
        staffRoleShiftId: '',
        staffRoleId: '',
        locationId: '',
    };

    const weekdaysOrder = getWeekDaysOrder(firstDayOfWeek);

    const weeklySchedule = weekdaysOrder.map<StaffList.WeekScheduleData>((weekday) => {
        // filling up with disabled fields
        return shifts.find((shift) => shift.dayIndex === weekday + 1) ?? { ...disabledField, dayIndex: weekday + 1 };
    });

    return weeklySchedule;
};

export const mapBiweeklySchedule = (
    schedule: GetStaff.Schedule,
    firstDayOfWeek: number,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffList.BiweeklyScheduleData[][] => {
    const shifts = schedule.shifts.map<StaffList.BiweeklyScheduleData>((shift) => ({
        enabled: true,
        dayIndex: shift.weekday!,
        weekIndex: shift.index ?? 0,
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const disabledField: StaffList.BiweeklyScheduleData = {
        enabled: false,
        dayIndex: 0,
        staffRoleShiftId: '',
        staffRoleId: '',
        locationId: '',
    };

    const weekdaysOrder = getWeekDaysOrder(firstDayOfWeek);

    const shiftsGroupedByWeek = groupBy(shifts, 'weekIndex');

    const biweeklySchedule: StaffList.BiweeklyScheduleData[][] = [[], []];

    for (const weekIndex of range(2)) {
        const weekSchedule = weekdaysOrder.map<StaffList.BiweeklyScheduleData>((weekday) => {
            const shift = shiftsGroupedByWeek[weekIndex]?.find((shift) => shift.dayIndex === weekday + 1);
            // filling up with disabled fields
            return (
                shift ?? {
                    ...disabledField,
                    dayIndex: weekday + 1,
                }
            );
        });

        biweeklySchedule[weekIndex].push(...weekSchedule);
    }

    return biweeklySchedule;
};

export const mapFourOnTwoOffSchedule = (
    schedule: GetStaff.Schedule,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffList.FourOnTwoOffScheduleData[] => {
    const data = schedule.shifts.slice(0, 4).map((shift) => ({
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const fillWithEmpty = [
        ...data,
        ...Array<StaffList.FourOnTwoOffScheduleData>(4 - data.length).fill({
            staffRoleShiftId: '',
            locationId: '',
            staffRoleId: '',
        }),
    ];

    return fillWithEmpty;
};

export const mapCustomSchedule = (
    schedule: GetStaff.Schedule,
    roleShiftById: GetRolesResult['roleShiftById']
): StaffList.CustomScheduleData[] => {
    return schedule.shifts.map((shift) => ({
        dayOfWeek: shift.weekday?.toString() ?? '',
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));
};

export const mapStaffInputParams = (
    data: StaffDetailsFormFields,
    primaryStaffRole: number
): CreateStaff.Params | UpdateStaff.Params => {
    return {
        name: data.name,
        type: data.type,
        email: data.email,
        phoneNumber: `+1${data.phone.replace(/^\+1|[\s-()]/g, '')}`,
        address: data.homeAddress,
        state: data.state,
        city: data.city,
        zipCode: data.zipCode,
        primaryLocationId: +data.mainlyServe,
        roles: data.roles.map((id) => ({
            staffRoleId: +id,
            primary: +id === primaryStaffRole,
        })),
    };
};

const mapCreateStaffScheduleCustomShiftTimes = (
    shift: StaffList.ScheduleConfigData,
    roleShiftById: GetRolesResult['roleShiftById']
) => {
    const toISOTime = (time: number) =>
        DateTime.fromMillis(time).toISOTime({ suppressMilliseconds: true, includeOffset: false })!; // HH:mm:ss

    const roleShift = roleShiftById.get(+shift.staffRoleShiftId)!;
    const customStartTime = shift.customStartTime ? toISOTime(shift.customStartTime) : undefined;
    const customEndTime = shift.customEndTime ? toISOTime(shift.customEndTime) : undefined;

    return {
        customStartTime: customStartTime !== roleShift.shiftStartTime ? customStartTime : undefined,
        customEndTime: customEndTime !== roleShift.shiftEndTime ? customEndTime : undefined,
    };
};

export const mapCreateStaffScheduleParams = (
    data: StaffList.ScheduleConfig,
    roleShiftById: GetRolesResult['roleShiftById'],
    firstDayOfWeek: number
): Omit<CreateStaffSchedule.Params, 'staffId'> => {
    const isToday = data.startDate?.hasSame(DateTime.now(), 'day');
    let shifts: StaffList.ScheduleConfigData[] = [];

    const weekDaysOrder = getWeekDaysOrder(firstDayOfWeek);

    if (data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.CUSTOM_WEEKLY) {
        shifts = data.shifts[0];
    } else if (data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY) {
        shifts = data.shifts[0].slice(0, 7);
    } else if (data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY) {
        // this case is handled in line 271 below
    } else {
        shifts = data.shifts[0].slice(0, 4);
    }

    const createBiweeklyShifts = (
        biweeklyShifts: StaffList.ScheduleConfigData[][]
    ): CreateStaffSchedule.ShiftScheduleParam[] => {
        return biweeklyShifts.flatMap((bShifts, weekIndex) =>
            bShifts.reduce((acc, shift, index) => {
                if (shift.enabled) {
                    // weekdaysOrder is 0 based, so we need to add 1
                    const weekday = weekDaysOrder[index] + 1;

                    return [
                        ...acc,
                        {
                            staffRoleId: +shift.staffRoleId,
                            staffRoleShiftId: +shift.staffRoleShiftId,
                            locationId: +shift.locationId,
                            weekday: weekday,
                            index: weekIndex,
                        },
                    ];
                }
                return acc;
            }, [])
        );
    };

    return {
        type: data.type,
        startAt: isToday ? undefined : data.startDate?.toFormat('yyyy-MM-dd'), // back handles undefined as today
        shifts:
            data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY
                ? createBiweeklyShifts(data.shifts)
                : shifts.reduce((shifts, shift, index) => {
                      // when the staff schedule type changes, like from weekly to four on two off,
                      // the remaining shifts will be empty, so we need to filter out these empty shifts
                      if (!shift.staffRoleId || !shift.staffRoleShiftId || !shift.locationId) {
                          return shifts;
                      }

                      // when the schedule type is four on two off, we need to include the index
                      if (data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.FOUR_ON_TWO_OFF) {
                          return [
                              ...shifts,
                              {
                                  staffRoleId: +shift.staffRoleId,
                                  staffRoleShiftId: +shift.staffRoleShiftId,
                                  locationId: +shift.locationId,
                                  index,
                                  ...mapCreateStaffScheduleCustomShiftTimes(shift, roleShiftById),
                              },
                          ];
                      }

                      // we need to filter unable shifts in weekly schedule
                      if (data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY && !shift.enabled) {
                          return shifts;
                      }

                      // weekdaysOrder is 0 based, so we need to add 1
                      const weekday = weekDaysOrder[index] + 1;

                      return [
                          ...shifts,
                          {
                              staffRoleId: +shift.staffRoleId,
                              staffRoleShiftId: +shift.staffRoleShiftId,
                              locationId: +shift.locationId,
                              weekday:
                                  // when the schedule type is weekly, we need to include the weekday
                                  // which will be the same as the index
                                  data.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY
                                      ? weekday
                                      : shift.dayOfWeek
                                        ? +shift.dayOfWeek
                                        : undefined,
                              ...mapCreateStaffScheduleCustomShiftTimes(shift, roleShiftById),
                          },
                      ];
                  }, []),
    };
};
