import { pxToRem } from '~/components/theme/typography';

export default function Tabs(theme) {
    const { palette } = theme;

    return {
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottomWidth: pxToRem(1),
                    borderBottomStyle: 'solid',
                    borderBottomColor: palette.divider,
                    minHeight: 'unset',
                    '& .MuiTab-root': {
                        color: palette.grey[600],
                        fontWeight: 400,
                        '&.MuiButtonBase-root': {
                            padding: `${pxToRem(8)} ${pxToRem(16)}`,
                            minHeight: pxToRem(32),
                        },
                        '&.Mui-selected': {
                            color: palette.common.black,
                            fontWeight: 'bold',
                        },
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: palette.common.black,
                    },
                },
            },
        },
    };
}
