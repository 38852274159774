import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

export const ResidentsIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <g id="vuesax/linear/people">
            <g id="vuesax/linear/people_2">
                <g id="people">
                    <g id="Group">
                        <path
                            id="Vector"
                            d="M18.5001 7.16C18.4401 7.15 18.3701 7.15 18.3101 7.16C16.9301 7.11 15.8301 5.98 15.8301 4.58C15.8301 3.15 16.9801 2 18.4101 2C19.8401 2 20.9901 3.16 20.9901 4.58C20.9801 5.98 19.8801 7.11 18.5001 7.16Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M17.4699 14.4402C18.8399 14.6702 20.3499 14.4302 21.4099 13.7202C22.8199 12.7802 22.8199 11.2402 21.4099 10.3002C20.3399 9.59016 18.8099 9.35016 17.4399 9.59016"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <g id="Group_2">
                        <path
                            id="Vector_3"
                            d="M6.46998 7.16C6.52998 7.15 6.59998 7.15 6.65998 7.16C8.03998 7.11 9.13998 5.98 9.13998 4.58C9.13998 3.15 7.98998 2 6.55998 2C5.12998 2 3.97998 3.16 3.97998 4.58C3.98998 5.98 5.08998 7.11 6.46998 7.16Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_4"
                            d="M7.49994 14.4402C6.12994 14.6702 4.61994 14.4302 3.55994 13.7202C2.14994 12.7802 2.14994 11.2402 3.55994 10.3002C4.62994 9.59016 6.15994 9.35016 7.52994 9.59016"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <g id="Group_3">
                        <path
                            id="Vector_5"
                            d="M12.5001 14.6302C12.4401 14.6202 12.3701 14.6202 12.3101 14.6302C10.9301 14.5802 9.83008 13.4502 9.83008 12.0502C9.83008 10.6202 10.9801 9.47021 12.4101 9.47021C13.8401 9.47021 14.9901 10.6302 14.9901 12.0502C14.9801 13.4502 13.8801 14.5902 12.5001 14.6302Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_6"
                            d="M9.59021 17.7804C8.18021 18.7204 8.18021 20.2603 9.59021 21.2003C11.1902 22.2703 13.8102 22.2703 15.4102 21.2003C16.8202 20.2603 16.8202 18.7204 15.4102 17.7804C13.8202 16.7204 11.1902 16.7204 9.59021 17.7804Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </g>
    </Base>
);
