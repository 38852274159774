import { MoreHoriz as MoreOptionsIcon } from '@mui/icons-material';
import { Box, Card, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { pxToRem } from '~/components/theme/typography';
import { usePermissions } from '~/permissions/utils';
import { ReduxStore } from '~/types/redux';

const CardStyle = styled(Card)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        p: pxToRem(8),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        color: theme.palette.common.black,
        backgroundColor: theme.palette.app.grey.main,
        fontWeight: 'bold',
        width: '100%',
    })
);

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pb: pxToRem(8),
        display: 'flex',
        width: '100%',
        '&:last-of-type': {
            pb: 0,
        },
    })
);

const FirstRowContent = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        width: `calc(100% - ${pxToRem(24)})`,
    })
);

const GreyLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        display: 'flex',
        color: theme.palette.grey[500],
    })
);

const MenuIcon = styled(IconButton)(({ theme }) =>
    theme.unstable_sx({
        p: 0,
        width: pxToRem(24),
        height: pxToRem(24),
    })
);

const MenuStyle = styled(Menu)(({ theme }) =>
    theme.unstable_sx({
        '& .MuiPaper-root': {
            width: pxToRem(112),
            '& .MuiList-root.MuiMenu-list .MuiMenuItem-root.MuiMenuItem-gutters': {
                justifyContent: 'center',
            },
        },
    })
);

const SecondRowContent = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    })
);

const ReportRow = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    })
);

type Props = {
    caregiver: string;
    caregiverId: number;
    category: string;
    elapsedTimeLabel: string;
    report: string;
    onEdit: () => void;
    onDelete: () => void;
};

const ShiftNoteCard = (props: Props) => {
    const { caregiver, caregiverId, category, elapsedTimeLabel, report, onEdit, onDelete } = props;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const hasPermission = usePermissions();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setMenuOpen(true);
        setAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
        setAnchorElement(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        onEdit();
    };

    const handleDelete = () => {
        handleMenuClose();
        onDelete();
    };

    return (
        <CardStyle>
            <Row>
                <FirstRowContent variant="subtitle2">
                    {caregiver}
                    <GreyLabel variant="body2">{elapsedTimeLabel}</GreyLabel>
                </FirstRowContent>
                {hasPermission('Community', 'update-resident-action') && userId === caregiverId && (
                    <>
                        <MenuIcon onClick={handleClick}>
                            <MoreOptionsIcon />
                        </MenuIcon>
                        <MenuStyle
                            id="shift-note-menu"
                            anchorEl={anchorElement}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={menuOpen}
                            onClose={handleMenuClose}
                        >
                            <MenuItem dense onClick={handleEdit}>
                                Edit
                            </MenuItem>
                            <MenuItem dense onClick={handleDelete}>
                                Delete
                            </MenuItem>
                        </MenuStyle>
                    </>
                )}
            </Row>
            <Row>
                <SecondRowContent variant="subtitle2">
                    Category
                    <GreyLabel variant="body2">{category}</GreyLabel>
                </SecondRowContent>
            </Row>
            <Row>
                <ReportRow variant="body2">{report}</ReportRow>
            </Row>
        </CardStyle>
    );
};

export default ShiftNoteCard;
