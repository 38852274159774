import React from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';
import { UpdateStaff } from '~/scheduling/api/types/staff/updateStaff';

export const useUpdateStaff = (staffId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = React.useCallback(
        async (data: UpdateStaff.Params) => {
            const options = { headers: { Authorization: token } };
            await api.put(`/scheduling/${branchId}/staff/${staffId}`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = React.useCallback(async () => {
        // Staff
        await queryClient.invalidateQueries({ queryKey: ['staff', branchId, staffId] });
        await queryClient.invalidateQueries({ queryKey: ['staff-schedules', branchId, staffId] });
        await queryClient.invalidateQueries({ queryKey: ['staff-list', branchId] });

        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

        // Dashboard
        await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['open-shifts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

        // Reports
        await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
