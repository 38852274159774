import { atom } from 'jotai';

import { guardAtom } from '~/scheduling/utils/atoms';

import { StaffList } from '../types';

export type StaffScheduleModalData = {
    onConfirm: () => Promise<void>;
    schedule: Pick<StaffList.StaffSchedule, 'type' | 'shifts'>;
};

export const primaryStaffRoleAtom = atom<number | null>(null);

export const staffScheduleModalDataAtom = atom<StaffScheduleModalData | null>(null);
export const isStaffScheduleModalOpenAtom = guardAtom(staffScheduleModalDataAtom);
