import { Box, MenuItem, Select, Typography, useTheme } from '@mui/material';
import React, { ComponentProps } from 'react';

interface DropdownOption {
    value: string | number;
    label: string;
}

interface DropdownProps {
    placeholder?: string;
    icon?: React.ReactNode;
    options: DropdownOption[];
    selectProps?: ComponentProps<typeof Select>;
    value?: string | number;
    error?: boolean;
    onChange?: (e: unknown) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
    placeholder,
    icon,
    options,
    selectProps,
    value,
    error,
    onChange,
}) => {
    const { palette } = useTheme();

    const renderOptions = () => {
        return options.map((option: DropdownOption) => {
            return (
                <MenuItem key={option.value.toString()} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    };

    return (
        <Select
            sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? (palette.error[500] as string) : undefined,
                },
            }}
            value={value ?? ''}
            fullWidth
            onChange={(e) => onChange?.(e.target.value)}
            displayEmpty
            renderValue={(value: string | number) => {
                const option = options.find((option) => option.value === value);

                const emptyLabel = placeholder ?? 'Select';
                const label = value ? (option?.label ?? emptyLabel) : emptyLabel;

                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                        {icon}
                        <Typography sx={{ opacity: option ? 1 : 0.38 }}>{label}</Typography>
                    </Box>
                );
            }}
            {...selectProps}
        >
            {renderOptions()}
        </Select>
    );
};
