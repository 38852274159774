import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetOtherStaff } from '~/scheduling/api/types/shift-slot/getOtherStaff';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetOtherStaffResult = NonNullable<ReturnType<typeof useGetOtherStaff>['data']>;

export const useGetOtherStaff = (slotId: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetOtherStaff.Response>(
            `/scheduling/${branchId}/shift-slot/${slotId}/other-staff`,
            options
        );

        return data.response;
    }, [branchId, token, slotId]);

    return useErrorHandledQuery({ queryKey: ['other-staff', branchId, slotId], queryFn, staleTime: 300000 });
};
