import Box from '@mui/material/Box';
import React from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';
import { FaShower as ShowerIcon } from 'react-icons/fa';
import { IoShirtSharp as LaundryIcon } from 'react-icons/io5';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    needsShower?: boolean;
    showerComplete?: boolean;
    laundryComplete?: boolean;
    isOnHospice?: boolean;
};

export const ActivityIndicator = (props: Props) => {
    const { needsShower, showerComplete, laundryComplete, isOnHospice } = props;

    const isComplete = (needsShower && showerComplete) || laundryComplete;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                py: pxToRem(4),
                px: pxToRem(8),
                mb: pxToRem(4),
                bgcolor: isComplete ? 'activityIndicator.completeBackground' : 'activityIndicator.incompleteBackground',
                color: isComplete ? 'activityIndicator.complete' : 'activityIndicator.incomplete',
                fontSize: { xs: pxToRem(12), md: pxToRem(13), lg: pxToRem(14) },
                borderRadius: pxToRem(24),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: pxToRem(4),
                }}
            >
                {needsShower ? <ShowerIcon /> : <LaundryIcon />}
                {needsShower ? `Shower${isOnHospice ? ' (Hospice)' : ''}` : 'Laundry'}
                {isComplete && <CheckIcon />}
            </Box>
        </Box>
    );
};
