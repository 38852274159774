import { Box, Button, FormControl, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Calendar } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import SegmentedModal from '../../../../../components/SegmentedModal';
import {
    addShiftSelectedCareTypeIdAtom,
    addShiftSelectedDayAtom,
    addShiftSelectedRoleIdAtom,
    addShiftSelectedShiftIdAtom,
    isAddShiftModalOpenAtom,
    selectStaffModalSlotIdAtom,
} from '../../atoms';
import { useShiftActions } from '../../useShiftActions';

const AddShiftParams = () => {
    const { data: roleData } = useGetRoles();
    const roles = roleData?.roles ?? [];
    const roleShifts = roleData?.roleShifts ?? [];

    const { data: locationData } = useGetLocations();
    const locations = locationData?.locations ?? [];

    const shiftOptions = useMemo(() => roleShifts.map(({ id, name }) => ({ value: id, label: name })), [roleShifts]);
    const roleOptions = useMemo(() => roles.map(({ id, name }) => ({ value: id, label: name })), [roles]);
    const locationOptions = useMemo(() => locations.map(({ id, name }) => ({ value: id, label: name })), [locations]);

    const [selectedDay, setSelectedDay] = useAtom(addShiftSelectedDayAtom);
    const setSelectedShiftId = useSetAtom(addShiftSelectedShiftIdAtom);
    const setSelectedRoleId = useSetAtom(addShiftSelectedRoleIdAtom);
    const setSelectedLocationId = useSetAtom(addShiftSelectedCareTypeIdAtom);

    return (
        <Stack spacing="20px">
            <AddShiftParamDatePicker label="Select date" value={selectedDay} onChange={setSelectedDay} />
            <AddShiftParamSelect label="Select shift" options={shiftOptions} onChange={setSelectedShiftId} />
            <AddShiftParamSelect label="Select role" options={roleOptions} onChange={setSelectedRoleId} />
            <AddShiftParamSelect label="Select location" options={locationOptions} onChange={setSelectedLocationId} />
        </Stack>
    );
};

const AddShiftParam = ({ label, children }: { label: string; children: React.ReactNode }) => (
    <Stack spacing="4px">
        <Typography variant="body1" fontWeight={700}>
            {label}
        </Typography>
        {children}
    </Stack>
);

const AddShiftParamDatePicker = ({
    label,
    value,
    onChange,
}: {
    label: string;
    value: DateTime;
    onChange: (value: DateTime) => void;
}) => {
    const { palette } = useTheme();

    return (
        <>
            <AddShiftParam label={label}>
                <Box width="100%" position="relative">
                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
                        <MobileDatePicker
                            format="EEE, LLL d"
                            value={value}
                            onChange={(date) => date && onChange(date)}
                            sx={{
                                width: '100%',
                                '& .MuiIconButton-edgeEnd': {
                                    color: palette.primary.main,
                                    mr: 0,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: 12,
                            transform: 'translateY(-50%)',
                            mt: '0 !important',
                            lineHeight: 0,
                        }}
                    >
                        <Calendar color={palette.primary.main} weight="fill" fontSize={20} />
                    </Box>
                </Box>
            </AddShiftParam>
        </>
    );
};

const AddShiftParamSelect = ({
    label,
    options,
    onChange,
}: {
    label: string;
    options: { value: number; label: string }[];
    onChange: (value: number) => void;
}) => (
    <AddShiftParam label={label}>
        <FormControl>
            <Select displayEmpty defaultValue={0} onChange={(e) => onChange(Number(e.target.value))}>
                <MenuItem disabled value={0}>
                    {label}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </AddShiftParam>
);

const AddShiftActions = () => {
    const toggle = useSetAtom(isAddShiftModalOpenAtom);
    const setSelectStaffModalSlotIdAtom = useSetAtom(selectStaffModalSlotIdAtom);

    const selectedDay = useAtomValue(addShiftSelectedDayAtom);
    const selectedShiftId = useAtomValue(addShiftSelectedShiftIdAtom);
    const selectedRoleId = useAtomValue(addShiftSelectedRoleIdAtom);
    const selectedCareTypeId = useAtomValue(addShiftSelectedCareTypeIdAtom);

    const isDisabled = !selectedDay || !selectedShiftId || !selectedRoleId || !selectedCareTypeId;

    const { createShiftSlot } = useShiftActions();
    const createSlot = () =>
        createShiftSlot(selectedDay.toISODate(), selectedShiftId!, selectedRoleId!, selectedCareTypeId!);

    return (
        <>
            <Button
                variant="outlined"
                color="error"
                onClick={() => {
                    createSlot();
                    toggle();
                }}
                disabled={isDisabled}
            >
                Create Open Shift
            </Button>
            <Button
                onClick={() => {
                    const slotId = createSlot();
                    setSelectStaffModalSlotIdAtom(slotId);
                    toggle();
                }}
                disabled={isDisabled}
            >
                Select Staff
            </Button>
        </>
    );
};

const AddShiftModal = () => {
    const [isOpen, toggle] = useAtom(isAddShiftModalOpenAtom);

    return (
        <SegmentedModal isOpen={isOpen} onClose={toggle} header="Add Shift" actions={<AddShiftActions />} closeButton>
            <AddShiftParams />
        </SegmentedModal>
    );
};

export default AddShiftModal;
