import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';

import { pxToRem } from '~/components/theme/typography';
import { RECENT_UNSCHEDULED_TASKS_DIALOG_ANALYTICS } from '~/constants/analytics';
import { RecentUnscheduledTasksResponse } from '~/types/dailyTasks';

type Props = {
    selectedValues: number[];
    onClose: () => void;
    onSubmit: () => void;
    changeSelectedValues: (selectedValues: number[]) => void;
    tasks: RecentUnscheduledTasksResponse[];
    loading?: boolean;
};

const RecentUnscheduledTaskCheckbox = (props: Props) => {
    const { selectedValues, onClose, onSubmit, changeSelectedValues, tasks, loading } = props;

    const isChecked = (id: number) => selectedValues.includes(id);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isChecked(Number(event.target.value))) {
            const filteredValues = selectedValues.filter((value) => value !== Number(event.target.value));
            changeSelectedValues(filteredValues);
            return;
        }

        changeSelectedValues([...selectedValues, Number(event.target.value)]);
    };

    const isDisabled = selectedValues.length === 0;

    return (
        <>
            <FormGroup
                sx={{
                    display: 'grid',
                    gap: pxToRem(12),
                    pr: { md: pxToRem(12) },
                    mb: pxToRem(16),
                    maxHeight: '70vh',
                    overflowY: 'auto',
                }}
            >
                {tasks?.map(({ id: value, name: label }) => (
                    <FormControlLabel
                        key={value}
                        value={value}
                        label={label}
                        sx={{
                            paddingBlock: pxToRem(12),
                            pl: pxToRem(12),
                            bgcolor: isChecked(value) ? '#F2F8F8' : 'grey.200',
                            borderRadius: pxToRem(8),
                            border: `1px solid ${isChecked(value) ? '#006b75' : 'transparent'}`,
                            m: '0 !important',
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 700,
                                lineHeight: 1.7,
                            },
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    p: 0,
                                    mr: pxToRem(8),
                                }}
                                checked={isChecked(value)}
                                onChange={handleCheckboxChange}
                                icon={
                                    <Box
                                        sx={{
                                            width: pxToRem(16),
                                            height: pxToRem(16),
                                            borderRadius: '50%',
                                            bgcolor: 'selector.deselected',
                                        }}
                                    />
                                }
                                checkedIcon={
                                    <CheckIcon
                                        fill="#F2F8F8"
                                        style={{
                                            backgroundColor: '#006b75',
                                            borderRadius: '50%',
                                        }}
                                    />
                                }
                            />
                        }
                    />
                ))}
            </FormGroup>
            <Box
                sx={{
                    mt: pxToRem(8),
                    display: 'grid',
                    gridTemplateColumns: '40% 1fr',
                    gap: pxToRem(12),
                }}
            >
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                    sx={{
                        p: pxToRem(12),
                        fontSize: pxToRem(14),
                    }}
                    data-analytics-id={RECENT_UNSCHEDULED_TASKS_DIALOG_ANALYTICS.NO_BUTTON}
                >
                    No
                </Button>
                <Button
                    color="primary"
                    onClick={onSubmit}
                    disabled={isDisabled || loading}
                    sx={{
                        fontSize: pxToRem(14),
                        '&:disabled': {
                            bgcolor: '#C7C8CB',
                        },
                    }}
                    data-analytics-id={RECENT_UNSCHEDULED_TASKS_DIALOG_ANALYTICS.SUBMIT_BUTTON}
                >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Submit'}
                </Button>
            </Box>
        </>
    );
};

export default RecentUnscheduledTaskCheckbox;
