import { Stack } from '@mui/material';
import React from 'react';

import langDictionary from '~/app-strings';
import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';
import { pxToRem } from '~/components/theme/typography';

import SortButtonGroup from './SortButtonGroup';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSort: (sortBy: string, sortOrder: string) => void;
    sortBy: string;
    sortOrder: string;
};

const SortDialog = (props: Props) => {
    const { isOpen, onClose, onSort, sortBy = 'roomNumber', sortOrder = 'asc' } = props;

    const {
        sortDialogTitle: sortDialogTitleTxt,
        sortDialogNameButton: sortDialogNameButtonTxt,
        sortDialogRoomButton: sortDialogRoomButtonTxt,
        sortDialogShowerLaundryButton: sortDialogShowerLaundryButtonTxt,
        sortDialogAscendingButton: sortDialogAscendingButtonTxt,
        sortDialogDescendingButton: sortDialogDescendingButtonTxt,
    } = langDictionary.shared;

    const sortByOptions: SortOption[] = [
        { value: 'roomNumber', label: sortDialogRoomButtonTxt },
        { value: 'firstName', label: sortDialogNameButtonTxt },
        { value: 'shower&Laundry', label: sortDialogShowerLaundryButtonTxt },
    ];

    const sortOrderOptions: SortOption[] = [
        { value: 'asc', label: sortDialogAscendingButtonTxt },
        { value: 'desc', label: sortDialogDescendingButtonTxt },
    ];

    const handleClose = () => {
        onClose();
    };

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={sortDialogTitleTxt}
            width="100%"
            onClose={handleClose}
            content={
                <Stack spacing={3} sx={{ mt: pxToRem(4) }}>
                    <SortButtonGroup
                        buttons={sortByOptions.map(({ value, label }) => ({
                            title: label,
                            isSelected: sortBy === value,
                            action: () => {
                                onSort(value, sortOrder);
                            },
                        }))}
                    />
                    <SortButtonGroup
                        buttons={sortOrderOptions.map(({ value, label }) => ({
                            title: label,
                            isSelected: sortOrder === value,
                            action: () => {
                                onSort(sortBy, value);
                            },
                        }))}
                    />
                </Stack>
            }
        />
    );
};

export default SortDialog;
