import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';
import { CreateAgencyStaff } from '~/scheduling/api/types/agency-staff/createAgencyStaff';

export const useCreateAgencyStaff = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async (params: CreateAgencyStaff.Params) => {
            const options = { headers: { Authorization: token } };
            const { data } = await api.post<CreateAgencyStaff.Response>(
                `/scheduling/${branchId}/agency-staff`,
                params.data,
                options
            );

            return data.response.id;
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['agency-staff', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
