import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetSlotRequests } from '~/scheduling/api/types/shift-slot/getSlotRequests';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetSlotRequestsResult = NonNullable<ReturnType<typeof useGetSlotRequests>['data']>;

export const useGetSlotRequests = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetSlotRequests.Response>(
            `/scheduling/${branchId}/shift-slot/requests`,
            options
        );

        return data.response.map(remapSlot);
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['slot-requests', branchId], queryFn, staleTime: 30000 });
};

const remapSlot = ({ shiftDay, requests, ...suggested }: GetSlotRequests.Slot) => ({
    ...suggested,
    shiftDay: DateTime.fromISO(shiftDay),
    requests: requests.map(remapRequest),
});

const remapRequest = ({ createdAt, ...request }: GetSlotRequests.Request) => ({
    ...request,
    createdAt: DateTime.fromISO(createdAt),
});
