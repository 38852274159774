import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSound from 'use-sound';

import { withBranchShiftSelector } from '~/api/queries/branch';
import { useResidentDailyTasksQuery, useResidentQuery } from '~/api/queries/residents';
import notificationSound from '~/assets/notification-sound.mp3';
import yaySound from '~/assets/yay-sound.mp3';
import CustomConfetti from '~/components/Custom/CustomConfetti';
import Loading from '~/components/Shared/Loading';
import PageStructure from '~/pages/PageStructure';
import ResidentChipsContainer from '~/pages/Residents/Details/ChipsContainer';
import ResidentPersonalInfoContainer from '~/pages/Residents/Details/PersonalInfoContainer';
import ShiftNotesContainer from '~/pages/Residents/Details/ShiftNotesContainer';
import ResidentTasksContainer from '~/pages/Residents/Details/TasksContainer';
import ResidentTopContainer from '~/pages/Residents/Details/TopContainer';
import { hideAlert, showAlert } from '~/redux/actions/messages';
import { toggleResidentParty } from '~/redux/actions/residents';
import { MessageProps } from '~/types/messages';
import { ReduxStore } from '~/types/redux';

import { RecentEvents } from './RecentEvents';

type Props = {
    displayParty: boolean;
    branchId: number;
    dispatchToggleResidentParty: () => void;
};

const ResidentDetails = (props: Props) => {
    const { residentId: strResidentId } = useParams() || {};
    const residentId = Number(strResidentId!);
    const { displayParty, dispatchToggleResidentParty } = props;
    const [party, setParty] = useState<boolean>(false);
    const [playYaySound, { stop }] = useSound(yaySound);
    const [playCoinSound] = useSound(notificationSound);

    // TODO: This is broken: if you have permission for whatever community, you can see any resident
    const { branchId } = props;

    const { refetch: residentDailyTasksRefetch } = useResidentDailyTasksQuery(Number(residentId), branchId);

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(residentId, branchId);

    const handleTasksComplete = () => {
        if (displayParty) {
            playYaySound();
            setParty(true);
            dispatchToggleResidentParty();
        }
    };

    const triggerResidentActions = () => {
        residentDailyTasksRefetch();
    };

    useEffect(() => {
        if (residentId) {
            triggerResidentActions();
        }
    }, [residentId]);

    useEffect(() => stop(), []);

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const residentName = `${residentData?.firstName} ${residentData?.lastName}`;

    return (
        <PageStructure>
            <ResidentTopContainer residentData={residentData} />
            <ResidentChipsContainer
                residentId={residentId}
                branchId={residentData.branchId}
                displayPartyOnSubmit={() => {
                    playCoinSound();
                    setParty(true);
                }}
            />
            <ShiftNotesContainer residentId={residentId} branchId={residentData.branchId} />
            <ResidentPersonalInfoContainer personalInfo={residentData?.personalInfo} />
            <RecentEvents events={residentData?.recentEvents} />
            <ResidentTasksContainer
                residentId={Number(residentId)}
                onComplete={handleTasksComplete}
                residentName={residentName}
                branchId={residentData.branchId}
            />
            <CustomConfetti activate={party} stop={() => setParty(false)} />
        </PageStructure>
    );
};

const mapStateToProps = ({ residents, session }: ReduxStore) => {
    const { displayParty } = residents;
    const {
        sessionData: { branchId },
    } = session;

    return { displayParty, branchId };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
    dispatchShowAlert: (message: MessageProps) => dispatch(showAlert(message)),
    dispatchHideAlert: () => dispatch(hideAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withBranchShiftSelector(ResidentDetails));
