import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { Scheduling } from '../../types';

import { CalendarDayColumn } from './CalendarDayColumns';
import { CalendarHeader } from './CalendarHeader';
import { CalendarWeekRow } from './CalendarWeekRow';
import { LAST_MONTH_OF_YEAR } from './constants';
import { daysMatrix } from './util';

interface CalendarProps {
    startDate: Scheduling.CalendarData;
    endDate: Scheduling.CalendarData;
    month?: number;
    year?: number;
    disablePast?: boolean;
    hidePreviousButton?: boolean;
    hideNextButton?: boolean;
    onPreviousMonthClick?: () => void;
    onNextMonthClick?: () => void;
    onChangeValue?: (dated: Scheduling.DateRange) => void;
    disableFn?: (date: DateTime) => boolean;
}

export const Calendar = (props: CalendarProps) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const [calendarYear, setCalendarYear] = React.useState<number>(currentYear);
    const [calendarMonth, setCalendarMonth] = React.useState<number>(currentMonth);

    // this component can be controlled or not
    const month = props.month ?? calendarMonth;
    const year = props.year ?? calendarYear;

    const handleClick = (day: number) => {
        const currentDate = DateTime.fromObject({ day, month: month + 1, year });
        const startDateAsDate = DateTime.fromObject({ ...props.startDate, month: props.startDate.month + 1 });
        const endDateAsDate = DateTime.fromObject({ ...props.endDate, month: props.endDate.month + 1 });

        const isAfterEndDate = currentDate > endDateAsDate;
        const isBeforeStartDate = currentDate < startDateAsDate;
        const isTheSameDate = currentDate.equals(startDateAsDate) || currentDate.equals(endDateAsDate);

        // This is the core of the component
        // if we need to change or add anything in how the calendar works
        // this is the spot to do it
        if (isAfterEndDate) {
            props.onChangeValue?.({ startDate: props.startDate, endDate: { day, month, year } });
        } else if (isBeforeStartDate) {
            props.onChangeValue?.({ endDate: props.endDate, startDate: { day, month, year } });
        } else if (isTheSameDate) {
            props.onChangeValue?.({
                endDate: { day, month, year },
                startDate: { day, month, year },
            });
        } else {
            props.onChangeValue?.({ startDate: props.startDate, endDate: { day, month, year } });
        }
    };

    const handleNextMonth = () => {
        // controlled case
        if (props.month != null) {
            return props.onNextMonthClick?.();
        }

        // uncontrolled case
        if (month === LAST_MONTH_OF_YEAR) {
            setCalendarYear((prev) => prev + 1);
        }
        setCalendarMonth((prev) => prev % LAST_MONTH_OF_YEAR);
    };

    const handlePreviousMonth = () => {
        // controlled case
        if (props.month != null) {
            return props.onPreviousMonthClick?.();
        }

        // uncontrolled case
        if (month - 1 === -1) {
            setCalendarYear((prev) => prev - 1);
        }
        setCalendarMonth((prev) => (prev + LAST_MONTH_OF_YEAR) % 12);
    };

    return (
        <Box>
            <CalendarHeader
                hideLeftArray={props.hidePreviousButton}
                hideRightArray={props.hideNextButton}
                monthIndex={month}
                currentYear={year}
                onNextMonth={handleNextMonth}
                onPreviousMonth={handlePreviousMonth}
            />
            <CalendarWeekRow />
            {daysMatrix(month, year).map((week, i) => (
                <Box key={'week' + i} display="flex">
                    {week.map((day, j) => {
                        if (day === '') {
                            return <CalendarDayColumn key={'day' + j}>{day}</CalendarDayColumn>;
                        }

                        // parsing to dateTimes to ease the comparison
                        const currentDate = DateTime.fromObject({ day, month: month + 1, year });
                        const startDateAsDate = DateTime.fromObject({
                            ...props.startDate,
                            month: props.startDate.month + 1,
                        });
                        const endDateAsDate = DateTime.fromObject({ ...props.endDate, month: props.endDate.month + 1 });

                        const selectedDay = currentDate.equals(startDateAsDate) || currentDate.equals(endDateAsDate);

                        const insidePeriod = currentDate >= startDateAsDate && currentDate <= endDateAsDate;

                        // checking if the current date is the start or end to
                        // change the style, changing the linear-gradient in each cases
                        const isStart = currentDate.equals(startDateAsDate) && !startDateAsDate.equals(endDateAsDate);
                        const isEnd = currentDate.equals(endDateAsDate) && !startDateAsDate.equals(endDateAsDate);

                        const disabled = props.disableFn
                            ? props.disableFn(currentDate)
                            : props.disablePast && currentDate < DateTime.now().startOf('day');

                        return (
                            <CalendarDayColumn
                                key={'day' + j}
                                disabled={disabled}
                                selected={selectedDay}
                                insidePeriod={insidePeriod}
                                isStart={isStart}
                                isEnd={isEnd}
                                onClick={() => handleClick(day)}
                            >
                                {day}
                            </CalendarDayColumn>
                        );
                    })}
                </Box>
            ))}
        </Box>
    );
};
