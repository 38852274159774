import { Box, Typography } from '@mui/material';
import React from 'react';
import { BsFillArrowDownCircleFill as DownArrow, BsFillArrowUpCircleFill as UpArrow } from 'react-icons/bs';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    row: WeightMeasure;
};

export const WeightRowContent = ({ row }: Props) => {
    const { dayTimeRange, direction, fromWeight, isAboveThreshold, percentChange, toWeight } = row;

    let directionIcon = <></>;

    if (direction === 'Increase') {
        directionIcon = <UpArrow size={14} />;
    } else if (direction === 'Decrease') {
        directionIcon = <DownArrow size={14} />;
    }

    const weightChange = fromWeight && toWeight ? (Number(toWeight) - Number(fromWeight)).toFixed(2) : null;

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="baseline">
                <Box>
                    <Typography fontSize={{ xs: pxToRem(14) }} fontWeight={700} lineHeight={1.3}>
                        In last {dayTimeRange}d
                    </Typography>
                    <Typography component="h2" textAlign="left" color="#6F6F79">
                        <Typography component="span" fontSize={{ xs: pxToRem(14) }} fontWeight={600}>
                            {weightChange ? `${weightChange} lbs` : 'No Change'}
                        </Typography>{' '}
                        {weightChange && (
                            <Typography component="span" fontSize={{ xs: pxToRem(11) }} ml={pxToRem(4)}>
                                ({fromWeight} &rarr; {toWeight})
                            </Typography>
                        )}
                    </Typography>
                </Box>
                {weightChange && (
                    <Box
                        color={isAboveThreshold ? '#EC3232' : 'primary.main'}
                        display="flex"
                        alignItems="center"
                        gap={pxToRem(4)}
                    >
                        {directionIcon}
                        <Typography
                            fontSize={{
                                xs: pxToRem(14),
                            }}
                            fontWeight={700}
                            lineHeight={1.7}
                        >
                            {percentChange}
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};
