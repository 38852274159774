import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';

export const useCreateSlotRequest = (slotId: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        await api.post(`/scheduling/${branchId}/shift-slot/${slotId}/request`, undefined, options);
    }, [branchId, token]);

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['my-schedule', branchId] }); // Also invalidates 'other-staff'
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
