import { Capacitor } from '@capacitor/core';
import { Box, Link, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FaLaptop } from 'react-icons/fa';

import { pxToRem } from '~/components/theme/typography';
import PageStructure from '~/pages/PageStructure';

import DesktopHeader from '../DesktopHeader';
import MobileHeader from '../MobileHeader';

import { platformMap } from './platformGuardFilter';

type Props = {
    children: React.ReactNode;
    platformGuardBlacklist: ('native' | 'web')[];
    resource?: string;
    mobileHeader?: true;
    desktopHeader?: true;
};

const NoNativePageBody = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        color: 'primary.main',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: pxToRem(32),
        p: pxToRem(32),
        marginTop: pxToRem(32),
    })
);

const NoNativeTypography = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: '#6F6F79',
        lineHeight: 1.7,
        fontSize: pxToRem(16),
        textAlign: 'center',
    })
);

const NoNativeLink = styled(Link)(({ theme }) =>
    theme.unstable_sx({
        color: 'primary.dark',
        fontWeight: 600,
    })
);

export default function PlatformGuard({
    children,
    platformGuardBlacklist,
    resource,
    mobileHeader,
    desktopHeader,
}: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isBlocked = platformGuardBlacklist.includes(platformMap[Capacitor.getPlatform()]);

    if (isBlocked) {
        return (
            <Stack height="100%">
                {isMobile ? mobileHeader && <MobileHeader /> : desktopHeader && <DesktopHeader />}
                <PageStructure>
                    <NoNativePageBody>
                        <FaLaptop size={pxToRem(100)} />
                        <NoNativeTypography>
                            {resource ? resource : 'This page'} is not available on the mobile app. Please use the web
                            version at{' '}
                            <NoNativeLink href="https://app.alliehealth.com">app.alliehealth.com</NoNativeLink>
                        </NoNativeTypography>
                    </NoNativePageBody>
                </PageStructure>
            </Stack>
        );
    }

    return children;
}
