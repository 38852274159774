import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Pluralize from 'pluralize';
import React from 'react';

import { HandStopIcon, LikeIcon, LovelyIcon } from '~/components/Svg';
import { pxToRem } from '~/components/theme/typography';
import { ThanksTextParams, ThanksType } from '~/types/myRewards';

const Text = ({ children }) => (
    <Box
        sx={{
            textAlign: 'left',
            fontSize: pxToRem(14),
        }}
    >
        {children}
    </Box>
);

const Stat = ({ children }) => (
    <Box
        sx={{
            display: 'inline-block',
            fontWeight: 700,
        }}
    >
        {children}
    </Box>
);

const THANKS_TYPE_TO_ICON: Record<ThanksType, (props) => JSX.Element> = {
    UNSCHEDULED_TASKS: HandStopIcon,
    RESIDENTS: LikeIcon,
    NOTES: LovelyIcon,
};

const THANKS_TYPE_TO_TEXT = {
    UNSCHEDULED_TASKS: ({ residentsCount, unscheduledTasksCount, isThisShift }: ThanksTextParams) => (
        <Text>
            You went above and beyond for <Stat>{residentsCount}</Stat> {Pluralize('resident', residentsCount)}{' '}
            <Stat>{unscheduledTasksCount}</Stat> {Pluralize('time', unscheduledTasksCount)}{' '}
            {isThisShift ? 'this shift' : 'last shift'}.
        </Text>
    ),
    RESIDENTS: ({ residentsCount }: ThanksTextParams) => (
        <Text>
            Horray for helping <Stat>{residentsCount}</Stat> {Pluralize('resident', residentsCount)} with their care
            tasks!
        </Text>
    ),
    NOTES: ({ notesCount, residentsCount }: ThanksTextParams) => (
        <Text>
            High-five for sharing <Stat>{notesCount}</Stat> {Pluralize('note', notesCount)} regarding{' '}
            <Stat>{residentsCount}</Stat> {Pluralize('resident', residentsCount)}. You rock!
        </Text>
    ),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    icon: {
        // display: 'inline-flex',
        color: theme.palette.app.orange.main,
    },
}));

type Props = {
    thanksType: 'UNSCHEDULED_TASKS' | 'RESIDENTS' | 'NOTES';
    contentValues: ThanksTextParams;
};

const ThanksItem = ({ thanksType, contentValues }: Props) => {
    const classes = useStyles();
    const Icon = THANKS_TYPE_TO_ICON[thanksType];
    const TextComponent = THANKS_TYPE_TO_TEXT[thanksType];

    return (
        <Box
            sx={{
                fontSize: pxToRem(14),
                backgroundColor: '#F8F8F9',
                display: 'flex',
                flexDirection: 'row',
                padding: pxToRem(16),
                alignItems: 'center',
                borderRadius: pxToRem(8),
            }}
        >
            <Box
                sx={{
                    borderRadius: pxToRem(100),
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: pxToRem(48),
                    height: pxToRem(48),
                    justifyContent: 'center',
                    marginRight: pxToRem(12),
                }}
            >
                <Icon className={classes.icon} size={24} viewBox="0 0 24 24" />
            </Box>
            <TextComponent {...contentValues} />
        </Box>
    );
};

export default ThanksItem;
