import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import { selectedPeriodAtom } from './atoms';
import Header from './components/Header';
import AddShiftModal from './components/Modal/AddShiftModal';
import AddTraineeModal from './components/Modal/AddTraineeModal';
import ChangeStaffModal from './components/Modal/ChangeStaffModal';
import SelectStaffModal from './components/Modal/SelectStaffModal';
import ConfirmStaffModal from './components/Modal/SelectStaffModal/ConfirmStaffModal';
import Week from './components/Week';

const Schedule = () => {
    const selectedPeriod = useAtomValue(selectedPeriodAtom);

    return (
        <>
            <Stack position="relative" height="100%">
                <Header />
                {selectedPeriod === 'week' && <Week />}
            </Stack>

            <AddShiftModal />
            <AddTraineeModal />
            <ChangeStaffModal />
            <SelectStaffModal />
            <ConfirmStaffModal />
        </>
    );
};

export default Schedule;
