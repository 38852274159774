import React from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';
import { DeleteStaffSchedule } from '~/scheduling/api/types/staff/deleteStaffSchedule';

export const useDeleteStaffSchedule = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = React.useCallback(
        async ({ staffId, staffScheduleId }: DeleteStaffSchedule.Params) => {
            const options = { headers: { Authorization: token } };
            await api.delete(`/scheduling/${branchId}/staff/${staffId}/schedule/${staffScheduleId}`, options);

            return staffId;
        },
        [branchId, token]
    );

    const onSuccess = React.useCallback(
        async (staffId: number) => {
            // Staff
            await queryClient.invalidateQueries({ queryKey: ['staff', branchId, staffId] });
            await queryClient.invalidateQueries({ queryKey: ['staff-schedules', branchId, staffId] });
            await queryClient.invalidateQueries({ queryKey: ['staff-list', branchId] });

            // Schedule
            await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });
            await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

            // Dashboard
            await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
            await queryClient.invalidateQueries({ queryKey: ['open-shifts', branchId] });
            await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

            // Reports
            await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
            await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
        },
        [branchId]
    );

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
