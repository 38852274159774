import { Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    label: string;
    date?: string;
};

const fontSizeStyle = {
    '&.MuiTypography-root': {
        fontSize: { xs: pxToRem(12), md: pxToRem(14) },
    },
};

export const ResidentInfoBadge = ({ label, date }: Props) => (
    <Typography
        color="#6F6F79"
        bgcolor="grey.200"
        px={pxToRem(8)}
        py={pxToRem(4)}
        display="inline-block"
        borderRadius={pxToRem(4)}
        sx={fontSizeStyle}
    >
        {label}
        {date && (
            <Typography component="span" fontWeight={700} sx={fontSizeStyle}>
                {` ${date}`}
            </Typography>
        )}
    </Typography>
);
