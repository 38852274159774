import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetSlotStaffWarnings } from '~/scheduling/api/types/shift-slot/getSlotStaffWarnings';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetSlotStaffWarningsResult = NonNullable<ReturnType<typeof useGetSlotStaffWarnings>['data']>;

export const useGetSlotStaffWarnings = (slotId: number | null, staffId: number | null) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const params = { slotId, staffId };

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token }, params };
        const { data } = await api.get<GetSlotStaffWarnings.Response>(
            `/scheduling/${branchId}/shift-slot/${slotId}/staff-warnings`,
            options
        );

        return data.response;
    }, [branchId, token, params]);

    return useErrorHandledQuery({
        queryKey: ['staff-warnings', branchId, params],
        queryFn,
        enabled: !!slotId && !!staffId,
        staleTime: 300000,
    });
};
