import { Box, Divider, Drawer, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { GoSignOut } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import LogoImage from '~/assets/allie_logo.svg';
import platformGuardFilter from '~/components/Layout/PlatformGuard/platformGuardFilter';
import { pxToRem } from '~/components/theme/typography';
import { useAppDispatch } from '~/constants/redux';
import { getInitials } from '~/lib/common';
import { signOut } from '~/redux/actions/session';
import { ReduxStore } from '~/types/redux';

import {
    BOTTOM_NAVIGATION_OPTIONS,
    DESKTOP_DRAWER_WIDTH,
    DRAWER_OPTIONS,
    DrawerAvatar,
    DrawerGreetingBox,
    DrawerGreetingContainer,
    DrawerList,
    DrawerListItemIcon,
    DrawerOptions,
    DrawerPrivacyLink,
    DrawerSupportListItem,
    getDrawerGreeting,
} from './shared';

const DesktopDrawer = () => {
    const { hardShutdown } = useIntercom();
    const dispatch = useAppDispatch();

    const { firstName, lastName, picture: userPicture } = useSelector((state: ReduxStore) => state.session.sessionData);
    const userFullName = `${firstName} ${lastName}`;
    const userInitials = getInitials(firstName, lastName);

    const handleSignOut = () => {
        hardShutdown(); // Reset Intercom settings on web since it is shared across users
        void dispatch(signOut());
    };

    return (
        <Box sx={{ width: pxToRem(DESKTOP_DRAWER_WIDTH) }}>
            <Drawer open variant="persistent" PaperProps={{ sx: { width: DESKTOP_DRAWER_WIDTH, zIndex: 0 } }}>
                <Box sx={{ p: pxToRem(24), pb: pxToRem(8) }}>
                    <img src={LogoImage} alt="AllieHealth" width={160} />
                </Box>

                <DrawerGreetingContainer>
                    <DrawerAvatar alt={userFullName} src={userPicture}>
                        {userInitials}
                    </DrawerAvatar>
                    <DrawerGreetingBox>
                        <Typography variant="subtitle1">{getDrawerGreeting()}</Typography>
                        <Typography variant="body2">{userFullName}</Typography>
                    </DrawerGreetingBox>
                </DrawerGreetingContainer>

                <DrawerList>
                    <DrawerOptions options={platformGuardFilter(BOTTOM_NAVIGATION_OPTIONS)} />

                    <Divider />

                    <DrawerOptions options={DRAWER_OPTIONS} />

                    <ListItem>
                        <DrawerSupportListItem>
                            support@alliehealth.com
                            <DrawerPrivacyLink href="/privacy-policy" target="_blank">
                                Privacy
                            </DrawerPrivacyLink>
                        </DrawerSupportListItem>
                    </ListItem>

                    <Divider />

                    <ListItem>
                        <ListItemButton onClick={handleSignOut}>
                            <DrawerListItemIcon>
                                <GoSignOut size={24} />
                            </DrawerListItemIcon>
                            <ListItemText primary="Sign Out" />
                        </ListItemButton>
                    </ListItem>
                </DrawerList>
            </Drawer>
        </Box>
    );
};

export default DesktopDrawer;
