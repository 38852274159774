import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/scheduling/api/common';
import { GetOvertimeStaff } from '~/scheduling/api/types/reports/getOvertimeStaff';
import { ReportPeriod } from '~/scheduling/pages/Reports/types';

import { getPeriodParams } from './helpers';

type GetOvertimeStaffParams = {
    period: ReportPeriod;
    firstDayOfWeek?: number;
};

export const useGetOvertimeStaff = (params: GetOvertimeStaffParams) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token }, params: getPeriodParams(params) };

        const { data } = await api.get<GetOvertimeStaff.Response>(
            `/scheduling/${branchId}/reports/overtime-staff`,
            options
        );

        return data.response;
    }, [branchId, params, token]);

    return useErrorHandledQuery({
        queryKey: ['overtime-staff', branchId, params.period, params.firstDayOfWeek],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!params && !!params.firstDayOfWeek,
    });
};
