import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetTeams } from '~/scheduling/api/types/teams/getTeams';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetTeamsResult = NonNullable<ReturnType<typeof useGetTeams>['data']>;

export const useGetTeams = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetTeams.Response>(`/scheduling/${branchId}/teams`, options);

        const teams = data.response;
        const teamById = new Map(teams.map((slot) => [slot.id, slot]));

        return { teams, teamById };
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['teams', branchId], queryFn, staleTime: 300000 });
};
