import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/scheduling/api/common';
import { GetMasterSchedule } from '~/scheduling/api/types/master-schedule/getMasterSchedule';

export const useGetMasterSchedule = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };

        const { data } = await api.get<GetMasterSchedule.Response>(
            `/scheduling/${branchId}/master-schedule/team/${teamId}`,
            options
        );

        return data.response;
    }, [branchId, teamId, token]);

    return useErrorHandledQuery({
        queryKey: ['master-schedule', branchId, teamId],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!teamId,
    });
};
