import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';
import { UpdateSlotStaffNotification } from '~/scheduling/api/types/shift-slot/updateSlotStaffNotification';

export const useUpdateSlotStaffNotification = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ slotId, notificationId, data }: UpdateSlotStaffNotification.Params) => {
            const options = { headers: { Authorization: token } };
            await api.patch(
                `/scheduling/${branchId}/shift-slot/${slotId}/notification/${notificationId}`,
                data,
                options
            );
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['my-notifications', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
