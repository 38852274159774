import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetMySchedule } from '~/scheduling/api/types/shift-slot/getMySchedule';
import { useWeekInterval } from '~/scheduling/useWeekInterval';

import { queryClient, useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetMyScheduleResult = NonNullable<ReturnType<typeof useGetMySchedule>['data']>;

export const useGetMySchedule = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const { weekInterval } = useWeekInterval();

    const params = weekInterval
        ? ({
              startDay: weekInterval.start.toISODate(),
              endDay: weekInterval.end.toISODate(),
          } satisfies GetMySchedule.Params)
        : null;

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token }, params };
        const { data } = await api.get<GetMySchedule.Response>(
            `/scheduling/${branchId}/shift-slot/my-schedule`,
            options
        );

        const slots = data.response.map(remapSlot);
        const slotById = new Map(slots.map((slot) => [slot.id, slot]));

        await queryClient.invalidateQueries({ queryKey: ['other-staff', branchId] });

        return { slots, slotById };
    }, [branchId, params, token]);

    return useErrorHandledQuery({
        queryKey: ['my-schedule', branchId, params],
        queryFn,
        enabled: !!params,
        staleTime: 30000,
    });
};

export const remapSlot = ({ startTime, endTime, ...slot }: GetMySchedule.Slot) => ({
    ...slot,
    startTime: fromISO(startTime),
    endTime: fromISO(endTime),
});

const fromISO = (date: string) => DateTime.fromISO(date, { setZone: true }) as DateTime<true>;
