import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    icon: ReactNode;
    padding?: number;
    size?: number;
};

const AuthIcon = ({ icon, padding, size }: Props) => (
    <Box
        sx={{
            display: 'grid',
            placeContent: 'center',
            mx: 'auto',
            width: pxToRem(size || 116),
            height: pxToRem(size || 116),
            p: pxToRem(padding || 40),
            color: 'primary.dark',
            bgcolor: '#F2F8F8',
            borderRadius: '50%',
            border: '1px solid #C2DBDE',
        }}
    >
        {icon}
    </Box>
);

export default AuthIcon;
