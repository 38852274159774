import { Close } from '@mui/icons-material';
import { Fade, IconButton, PaletteOptions, ThemeOptions, ThemeProvider, createTheme, styled } from '@mui/material';
import { AlertColor, Alert as BaseAlert } from '@mui/material';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useEffect } from 'react';

import typography from '~/scheduling/theme/typography';

const alertAtom = atom({
    isOpen: false,
    severity: '' as AlertColor,
    message: '',
    duration: 0,
});

export const useAlert = () => {
    const [alert, setAlert] = useAtom(alertAtom);

    const showAlert = useCallback(
        (severity: AlertColor, message: string, duration: number = 10000) =>
            setAlert({ isOpen: true, severity, message, duration }),
        [setAlert]
    );

    const hideAlert = useCallback(() => setAlert((prev) => ({ ...prev, isOpen: false })), [setAlert]);

    // Auto-hide the alert after some duration
    useEffect(() => {
        const timer = alert.isOpen ? setTimeout(hideAlert, alert.duration) : undefined;
        return () => clearTimeout(timer);
    }, [alert]);

    return { showAlert, hideAlert };
};

const Alert = styled(BaseAlert)({
    width: 'calc(100% - 16px)',
    position: 'fixed',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: 'calc(env(safe-area-inset-top) + 8px)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    '& .MuiAlert-action': { padding: 0 },
});

const severityToCloseButtonColor: Record<AlertColor, string> = {
    success: 'rgba(255,255,255,0.5)',
    info: 'rgba(0,0,0,0.25)',
    warning: 'rgba(255,255,255,0.5)',
    error: 'rgba(0,0,0,0.25)',
};

const palette = {
    success: { main: '#71e140' },
    info: { main: '#3390e7' },
    warning: { main: '#ffca2c' },
    error: { main: '#ff4842' },
} as PaletteOptions;

const theme = createTheme({ palette, typography } as ThemeOptions);

const CustomAlertAction = () => {
    const { severity } = useAtomValue(alertAtom);
    const setAlert = useSetAtom(alertAtom);

    return (
        <IconButton
            color="inherit"
            size="small"
            onClick={() => setAlert((prev) => ({ ...prev, isOpen: false }))}
            sx={{ backgroundColor: severityToCloseButtonColor[severity] }}
        >
            <Close fontSize="inherit" />
        </IconButton>
    );
};

const CustomAlert = () => {
    const { isOpen, message, severity } = useAtomValue(alertAtom);

    return (
        // Using a separate ThemeProvider for redefining some colors
        <ThemeProvider theme={theme}>
            <Fade in={isOpen}>
                <Alert variant="filled" severity={severity} action={<CustomAlertAction />}>
                    {message}
                </Alert>
            </Fade>
        </ThemeProvider>
    );
};

export default CustomAlert;
