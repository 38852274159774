import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type Props = {
    mobileTabSelected: number;
    onChange: (newValue: number) => void;
};

const MobileDashboardTabs = ({ mobileTabSelected, onChange }: Props) => (
    <Box sx={{ width: '100%' }}>
        <Tabs
            value={mobileTabSelected}
            onChange={(event, newValue: number) => onChange(newValue)}
            aria-label="Overview and Details tabs"
            variant="fullWidth"
            sx={{
                '& .MuiTab-root': {
                    color: 'common.white',
                    fontSize: pxToRem(16),
                    lineHeight: 1.5,
                    '&.Mui-selected': {
                        color: 'common.white',
                    },
                },
                '& .MuiTabs-indicator': {
                    backgroundColor: 'common.white',
                },
            }}
        >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Detail" {...a11yProps(1)} />
        </Tabs>
    </Box>
);

export default MobileDashboardTabs;
