import React from 'react';

import { api } from '~/api';

import { useBranchId, useErrorHandledQuery, useToken } from '../../common';
import { StaffListResponse, StaffResponse } from '../../types/staff';

export type GetStaffListResult = NonNullable<ReturnType<typeof useGetStaffList>['data']>;

export const useGetStaffList = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<StaffListResponse>(`/scheduling/${branchId}/staff`, options);

        const staffList = data.response.map(remapStaff);
        const staffById = new Map(staffList.map((staff) => [staff.id, staff]));

        return { staffList, staffById };
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['staff-list', branchId], queryFn, enabled: !!branchId, staleTime: 30000 });
};

const remapStaff = ({ name, ...staff }: StaffResponse) => {
    const split = name.split(' ');

    return {
        ...staff,
        name,
        firstName: split[0],
        lastName: split[1],
    };
};
