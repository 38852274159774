import React, { useEffect } from 'react';
import { Outlet } from 'react-router';

const BodyHeight = () => {
    useEffect(() => {
        document.body.style.height = '100%';
        return () => void (document.body.style.height = '');
    }, []);

    return <Outlet />;
};

export default BodyHeight;
