import { Box, Paper, Switch, Typography, styled, useTheme } from '@mui/material';
import { DateTime, Duration, Interval } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { PiArrowDown, PiArrowUp, PiBriefcase, PiCalendar, PiClock, PiUser } from 'react-icons/pi';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { FormDropdown } from '~/scheduling/components/form/FormDropdown';
import { StaffList } from '~/scheduling/pages/StaffList/types';
import { buildShiftInterval } from '~/scheduling/utils/dates';

import { DAYS_OF_WEEK } from '../../constants';
import { StaffDetailsFormFields } from '../../types';

const ScheduleDayContainer = styled(Paper)(({ theme }) => ({
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    borderRadius: '8px',
}));

const ScheduleDayHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
});

interface ScheduleDayProps {
    form: UseFormReturn<StaffDetailsFormFields>;
    title: string;
    scheduleDay?: StaffList.ScheduleDayData;
    dayIndex: number;
    scheduleIndex: number;
    weekIndex?: number;
    weekDay?: string;
    withSwitch?: boolean;
    withDayOfWeekField?: boolean;
    disabled?: boolean;
}

export const ScheduleDay = ({
    form,
    title,
    scheduleDay,
    dayIndex,
    scheduleIndex,
    withDayOfWeekField,
    weekDay,
    weekIndex = 0,
    withSwitch,
    disabled,
}: ScheduleDayProps) => {
    const [staffRoles, type, enabled, selectedRole, selectedShiftId, customStartTime, customEndTime] = useWatch({
        name: [
            'roles',
            `schedules.${scheduleIndex}.type`,
            `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.enabled`,
            `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.staffRoleId`,
            `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.staffRoleShiftId`,
            `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customStartTime`,
            `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customEndTime`,
        ],
        control: form.control,
    });
    const { palette } = useTheme();

    const { data: roleData } = useGetRoles();
    const roles = roleData?.roles;
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const staffTypeOptions = useMemo(() => {
        return (
            roles
                ?.filter((option) => staffRoles?.includes(option.id))
                .map((option) => ({
                    label: option.name,
                    value: option.id.toString(),
                })) ?? []
        );
    }, [staffRoles]);

    const { data: locationsData } = useGetLocations();

    const locationOptions = useMemo(
        () =>
            (locationsData?.locations ?? []).map((location) => ({
                value: location.id.toString(),
                label: location.name,
            })),
        [locationsData]
    );

    const shiftOptions = useMemo(
        () =>
            roleById
                ?.get(+selectedRole)
                ?.staffRoleShifts.map(({ id, name }) => ({ value: id.toString(), label: name })) ?? [],
        [roleData, selectedRole]
    );

    const shiftInterval = useMemo(() => {
        const roleShiftId = selectedShiftId ? +selectedShiftId : null;
        const roleShift = roleShiftId ? roleShiftById?.get(roleShiftId) : null;

        if (!roleShift) return null;

        const { shiftStartTime, shiftEndTime } = roleShift;
        return buildShiftInterval(DateTime.now(), shiftStartTime, shiftEndTime);
    }, [selectedShiftId, roleShiftById]);

    // Reset custom start and end times when shift changes
    useEffect(() => {
        // Do not reset if not in edit mode
        if (disabled || !shiftInterval) return;

        form.setValue(
            `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customStartTime`,
            +shiftInterval.start
        );
        form.setValue(`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customEndTime`, +shiftInterval.end);
    }, [shiftInterval]);

    const expandedShiftInterval = useMemo(() => {
        if (!shiftInterval) return null;

        // Build expanded shift interval to account for staff who work non-standard hours
        return Interval.fromDateTimes(
            shiftInterval.start.minus({ hours: 2 }),
            shiftInterval.end.plus({ hours: 2 })
        ) as Interval<true>;
    }, [shiftInterval]);

    const splitInterval = useMemo(
        () => (expandedShiftInterval?.splitBy(Duration.fromObject({ minutes: 30 })) as Interval<true>[]) ?? [],
        [expandedShiftInterval]
    );

    const startTimeOptions = useMemo(
        () => splitInterval.map(({ start }) => ({ value: +start, label: start.toFormat('hh:mm a') })),
        [splitInterval]
    );

    const endTimeOptions = useMemo(
        () => splitInterval.map(({ end }) => ({ value: +end, label: end.toFormat('hh:mm a') })),
        [splitInterval]
    );

    // If in edit mode, clamp start time to the nearest value less than the new end time
    useEffect(() => {
        if (!disabled && customStartTime && customEndTime && customStartTime >= customEndTime) {
            form.setValue(
                `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customStartTime`,
                startTimeOptions.findLast(({ value }) => value < customEndTime)?.value
            );
        }
    }, [customEndTime]);

    // If in edit mode, clamp end time to the nearest value greater than the new start time
    useEffect(() => {
        if (!disabled && customStartTime && customEndTime && customStartTime >= customEndTime) {
            form.setValue(
                `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customEndTime`,
                endTimeOptions.find(({ value }) => value > customStartTime)?.value
            );
        }
    }, [customStartTime]);

    const enableSwitch = !(enabled != null ? enabled === false : scheduleDay?.enabled === false);

    const disabledFields =
        disabled ||
        // should only disable fields if the schedule type is weekly or biweekly
        ((type === STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY || type === STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY) &&
            !enableSwitch);

    return (
        <ScheduleDayContainer elevation={0}>
            <ScheduleDayHeader>
                <Box display="flex" gap="4px">
                    <Typography
                        sx={{ fontSize: '15px', fontWeight: '700', textTransform: 'capitalize' }}
                    >{`${title} ${dayIndex + 1}`}</Typography>
                    {weekDay && <Typography sx={{ fontSize: '15px', fontWeight: '400' }}>{weekDay}</Typography>}
                </Box>
                {withSwitch && (
                    <Controller
                        control={form.control}
                        defaultValue={enableSwitch}
                        name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.enabled`}
                        render={({ field: { onChange } }) => (
                            <Switch
                                defaultChecked={enableSwitch}
                                disabled={disabled}
                                onChange={(_, check) => onChange(check)}
                            />
                        )}
                    />
                )}
            </ScheduleDayHeader>
            {withDayOfWeekField && (
                <FormDropdown
                    name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.dayOfWeek`}
                    placeholder="Day of Week"
                    icon={<PiCalendar size={20} color={palette.grey[300]} />}
                    form={form}
                    options={DAYS_OF_WEEK.map((dayOfWeek, value) => ({
                        value: (value + 1).toString(),
                        label: dayOfWeek,
                    }))}
                    selectProps={{ disabled: disabledFields, defaultValue: scheduleDay?.dayOfWeek }}
                />
            )}
            <FormDropdown
                form={form}
                name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.staffRoleId`}
                placeholder="Staff Role"
                icon={<PiUser size={20} color={palette.grey[300]} />}
                options={staffTypeOptions}
                selectProps={{ disabled: disabledFields, defaultValue: scheduleDay?.staffRoleId }}
            />
            <FormDropdown
                name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.locationId`}
                placeholder="Location"
                icon={<PiBriefcase size={20} color={palette.grey[300]} />}
                form={form}
                options={locationOptions}
                selectProps={{ disabled: disabledFields, defaultValue: scheduleDay?.locationId }}
            />
            <FormDropdown
                name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.staffRoleShiftId`}
                placeholder="Shift"
                icon={<PiClock size={20} color={palette.grey[300]} />}
                form={form}
                options={shiftOptions}
                selectProps={{ disabled: disabledFields || !selectedRole, defaultValue: scheduleDay?.staffRoleShiftId }}
            />
            <FormDropdown
                name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customStartTime`}
                placeholder="Start Time"
                icon={<PiArrowUp size={20} color={palette.grey[300]} />}
                form={form}
                options={startTimeOptions}
                selectProps={{
                    disabled: disabledFields || !selectedShiftId,
                    defaultValue: scheduleDay?.customStartTime,
                }}
            />
            <FormDropdown
                name={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}.customEndTime`}
                placeholder="End Time"
                icon={<PiArrowDown size={20} color={palette.grey[300]} />}
                form={form}
                options={endTimeOptions}
                selectProps={{
                    disabled: disabledFields || !selectedShiftId,
                    defaultValue: scheduleDay?.customEndTime,
                }}
            />
        </ScheduleDayContainer>
    );
};
