import { Button, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PiPlusBold, PiWarningCircleFill } from 'react-icons/pi';

import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { GetStaff } from '~/scheduling/api/types/staff/getStaff';

import { mapCustomSchedule } from '../../mapStaff';
import GridContainer from '../shared/GridContainer';

import { ScheduleDay } from './ScheduleDay';
import { StaffScheduleProps } from './shared';

const AddScheduleDay = styled(Button)(({ theme: { palette } }) => ({
    minHeight: '364px',
    height: '100%',
    color: palette.primary[400],
    borderColor: palette.primary[500],
}));

export const CustomSchedule = ({ form, schedule, scheduleIndex, isSaving, disabled }: StaffScheduleProps) => {
    const { palette } = useTheme();
    const [scheduleDays, setScheduleDays] = React.useState(0);

    const { data: roleData } = useGetRoles();
    const scheduleData = React.useMemo(
        () => schedule && roleData && mapCustomSchedule(schedule, roleData.roleShiftById),
        [schedule, roleData]
    );

    return (
        <Controller
            control={form.control}
            name={`schedules.${scheduleIndex}.shifts`}
            rules={{
                validate: (data) =>
                    data
                        ?.flat()
                        .some(
                            ({ staffRoleId, locationId, staffRoleShiftId }) =>
                                staffRoleId && locationId && staffRoleShiftId
                        )
                        ? undefined
                        : 'At least 1 shift needs to be filled out',
            }}
            render={({ fieldState: { error } }) => (
                <React.Fragment>
                    {error && (
                        <Typography
                            variant="body1"
                            color={palette.error[500] as string}
                            fontWeight={400}
                            fontSize="12px"
                            display="flex"
                            gap="4px"
                            alignItems="center"
                        >
                            <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                            {error.message}
                        </Typography>
                    )}
                    <GridContainer minmax={240}>
                        {scheduleData?.map((scheduleDay, index) => (
                            <ScheduleDay
                                key={index + 'custom'}
                                scheduleIndex={scheduleIndex}
                                form={form}
                                title="Shift"
                                dayIndex={index}
                                scheduleDay={scheduleDay}
                                withDayOfWeekField
                                disabled={disabled || isSaving}
                            />
                        ))}
                        {[...(Array(scheduleDays) as unknown[])].map((_, index) => (
                            <ScheduleDay
                                key={(scheduleData ?? [])?.length + index + 'custom'}
                                scheduleIndex={scheduleIndex}
                                form={form}
                                title="Shift"
                                dayIndex={(scheduleData ?? [])?.length + index}
                                withDayOfWeekField
                                disabled={isSaving}
                            />
                        ))}
                        {!disabled && (
                            <AddScheduleDay
                                variant="outlined"
                                startIcon={<PiPlusBold />}
                                onClick={() => setScheduleDays((prev) => prev + 1)}
                                disabled={isSaving}
                            >
                                Add Shift
                            </AddScheduleDay>
                        )}
                    </GridContainer>
                </React.Fragment>
            )}
        />
    );
};
