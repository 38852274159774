import { DateTime } from 'luxon';

import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import { StaffResponse } from '~/scheduling/api/types/staff';

import { StaffList } from './types';

export const useStaffList = () => {
    const { data, ...rest } = useGetStaffList();

    return {
        data: data ? mapStaffList(data.staffList) : [],
        ...rest,
    };
};

const mapStaffList = (data: StaffResponse[]): StaffList.StaffData[] => {
    return data.map((staff) => ({
        id: staff.id,
        staffName: staff.name,
        staffType: staff.staffType,
        mainlyServe: staff.primaryLocation.abbreviation ?? staff.primaryLocation.name,
        staffRoles: staff.roles.map((role) => ({ id: role.staffRoleId, name: role.name, primary: role.primary })),
        upcomingVacation: staff.upcomingVacation ?? undefined,
        currentSchedule: staff.currentSchedule
            ? {
                  ...staff.currentSchedule,
                  startAt: DateTime.fromISO(staff.currentSchedule.startAt),
                  endAt: staff.currentSchedule.endAt ? DateTime.fromISO(staff.currentSchedule.endAt) : undefined,
                  shifts: staff.currentSchedule.shifts.map((shift) => ({ ...shift, index: shift.index })),
              }
            : undefined,
    }));
};
