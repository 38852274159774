import { Box, Breadcrumbs, Button, CircularProgress, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { PiCaretRightBold } from 'react-icons/pi';
import { Link, useNavigate, useParams } from 'react-router-dom';

const HeaderContainer = styled(Stack)(() => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px 24px',
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
}));

const HeaderSection = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
});

const Header = ({ isSaving, isLoading }: { isSaving: boolean; isLoading: boolean }) => {
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/scheduling/staff-list');
    };

    const isDisabled = isSaving || isLoading;

    return (
        <HeaderContainer>
            <HeaderSection>
                <Box>
                    <Typography variant="h6">{params.id ? 'Edit Staff' : 'Add Staff'}</Typography>
                    <Breadcrumbs separator={<PiCaretRightBold fontSize="small" />} aria-label="breadcrumb">
                        <Link to="/scheduling/staff-list">Staff</Link>
                        <Typography color="#B1B2B7">{params.id ? 'Edit Staff' : 'Add Staff'}</Typography>
                    </Breadcrumbs>
                </Box>
            </HeaderSection>
            <HeaderSection>
                <Button type="button" variant="outlined" size="medium" onClick={handleCancel} disabled={isDisabled}>
                    Cancel
                </Button>
                <Button type="submit" size="medium" disabled={isDisabled}>
                    {isSaving ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Save'}
                </Button>
            </HeaderSection>
        </HeaderContainer>
    );
};

export default Header;
