import { Autocomplete, Box, Button, Typography, styled } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { PiMagnifyingGlass } from 'react-icons/pi';

import { useCreateStaffVacation } from '~/scheduling/api/queries/staff/createStaffVacation';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import { Scheduling } from '~/scheduling/types';

import SegmentedModal from '../../../../components/SegmentedModal';
import { MultiCalendar } from '../../../../components/calendar';
import { TextField } from '../../../../components/form/TextField';
import {
    endDate as defaultEndDate,
    startDate as defaultStartDate,
    isStaffVacationModalOpenAtom,
    vacationModalDateRange,
} from '../../atom';

import { formatPeriodString } from './util';

const MagnifyingGlassIcon = styled(PiMagnifyingGlass)(() => ({
    width: '20px',
    height: '20px',
}));

const CheckIconContainer = styled(Box)(({ theme }) => ({
    width: 'fit-content',
    padding: '32px',
    backgroundColor: theme.palette.primary[50],
    borderRadius: '100px',
}));

const CheckIcon = styled(FaCircleCheck)(({ theme }) => ({
    width: '24px',
    height: '24px',
    color: theme.palette.primary[500],
}));

interface StaffVacationModalActionProps {
    staffId: number | null;
    onSubmit: () => void;
    isPending: boolean;
}

const calendarDateToISOString = (calendarData: Scheduling.CalendarData): string => {
    const parsedDate = { ...calendarData, month: calendarData.month + 1 };
    return DateTime.fromObject(parsedDate).toISODate()!;
};

const StaffVacationModalAction = (props: StaffVacationModalActionProps) => {
    const dateRange = useAtomValue(vacationModalDateRange);

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="body1" fontWeight={700} color="grey.500">
                {formatPeriodString(dateRange)}
            </Typography>
            <Button onClick={props.onSubmit} disabled={!props.staffId || props.isPending}>
                {props.isPending ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    'Add Time Off'
                )}
            </Button>
        </Box>
    );
};

const StaffVacationModal = () => {
    const [isOpen, toggle] = useAtom(isStaffVacationModalOpenAtom);
    const [dateRange, setDateRange] = useAtom(vacationModalDateRange);

    const [isCompleted, setIsComplete] = React.useState(false);
    const [staffId, setStaffId] = React.useState<number | null>(null);
    const { data } = useGetStaffList();
    const { mutateAsync: createStaffVacation, isPending: isCreateStaffVacationPending } = useCreateStaffVacation();

    const staffOptions = useMemo(
        () =>
            data?.staffList
                ?.map(({ id, name }) => ({ id, label: name }))
                .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
        [data]
    );

    const handleClose = () => {
        setIsComplete(false);
        setDateRange({ startDate: defaultStartDate, endDate: defaultEndDate });
        toggle();
    };

    const handleConfirm = async () => {
        await createStaffVacation({
            staffId: staffId!,
            startDate: calendarDateToISOString(dateRange.startDate),
            endDate: calendarDateToISOString(dateRange.endDate),
        });
        setIsComplete(true);
    };

    const staffName = React.useMemo(() => {
        const selectedStaffName = staffId && data?.staffById?.get(staffId)?.name;
        return selectedStaffName ?? '';
    }, [staffId, data]);

    return (
        <SegmentedModal
            sx={{ minWidth: '520px' }}
            isOpen={isOpen}
            onClose={handleClose}
            header={!isCompleted ? 'Add Staff Time Off' : undefined}
            actions={
                !isCompleted && (
                    <StaffVacationModalAction
                        onSubmit={handleConfirm}
                        staffId={staffId}
                        isPending={isCreateStaffVacationPending}
                    />
                )
            }
            closeButton
        >
            {isCompleted ? (
                <Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CheckIconContainer>
                            <CheckIcon />
                        </CheckIconContainer>
                        <Box height="24px" />
                        <Typography variant="h5" color="grey.900">
                            Time Off Scheduled!
                        </Typography>
                        <Box height="12px" />
                        <Typography variant="body1" color="grey.500">
                            {staffName} is being removed from their shifts during vacation
                        </Typography>
                    </Box>
                    <Box height="32px" />
                    <Button onClick={handleClose} variant="outlined" fullWidth>
                        Close
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                        Staff name
                    </Typography>
                    <Autocomplete
                        options={staffOptions}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Search for staff name" icon={<MagnifyingGlassIcon />} />
                        )}
                        onChange={(_, value) => value && setStaffId(value.id)}
                    />
                    <Box height="12px" />
                    <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                        Select Vacation Time
                    </Typography>
                    <MultiCalendar dateRange={dateRange} setDateRange={setDateRange} disablePast />
                </Box>
            )}
        </SegmentedModal>
    );
};

export default StaffVacationModal;
