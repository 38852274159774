import { Box, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { camelCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { useResidentShiftNotesCreateMutation } from '~/api/queries/shiftNotes/shiftNotes';
import langDictionary from '~/app-strings';
import Loading from '~/components/Shared/Loading';
import { GoldCoin } from '~/components/Svg/GoldCoin';
import { pxToRem } from '~/components/theme/typography';
import { getDateInUtc } from '~/lib/date';
import UnscheduledTaskDialog from '~/pages/Home/components/UnscheduledTaskDialog';
import ShiftNoteDialog from '~/pages/Residents/Details/components/ShiftNoteDialog';
import { readShiftNotesCategories } from '~/redux/actions/shiftNotesCategories';
import { ShiftNotesCategoryResponse } from '~/types/ShiftNotesCategories';
import { ReduxStore } from '~/types/redux';

const ChipsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        display: 'none',
        alignItems: 'center',
        justifyContent: { xs: 'space-around', md: 'center' },
        flexWrap: 'wrap',
    })
);

const ChipStyle = styled(Chip)(({ theme }) =>
    theme.unstable_sx({
        mx: { md: pxToRem(8) },
        color: theme.palette.common.white,
        backgroundColor: theme.palette.app.orange.main,
        borderColor: theme.palette.grey[500],
        borderRadius: theme.shape.borderRadiusSm,
        fontWeight: 'bold',
        cursor: 'pointer',
        width: { xs: '31%', md: pxToRem(224) },
        height: { xs: pxToRem(40), md: pxToRem(32) },
        [`@media (max-width: ${pxToRem(339)})`]: {
            mb: pxToRem(16),
            width: '100%',
            height: pxToRem(32),
        },
        '& .MuiChip-label': {
            overflow: 'unset',
            whiteSpace: 'normal',
            textAlign: 'center',
        },
    })
);

const AddButtonContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        width: '100%',
    })
);

const AddButtonStyle = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        gap: pxToRem(5),
        padding: pxToRem(12),
        width: { xs: '100%', md: pxToRem(300) },
        fontWeight: 600,
        fontSize: pxToRem(14),
        backgroundColor: `${theme.palette.app.green.main} !important`,
        height: { xs: pxToRem(40), md: pxToRem(40) },
        '&:last-of-type': {
            marginLeft: { xs: 0, md: pxToRem(10) },
            marginTop: { xs: pxToRem(10), md: 0 },
        },
    })
);

type Props = {
    loading: boolean;
    shiftNotesCategoriesList: ShiftNotesCategoryResponse[];
    residentId: number;
    branchId: number;
    timezone: string;
    dispatchReadShiftNotesCategories: (branchId: number) => void;
    displayPartyOnSubmit: () => void;
};

const ResidentChipsContainer = (props: Props) => {
    const {
        loading,
        shiftNotesCategoriesList,
        residentId,
        branchId,
        timezone,
        dispatchReadShiftNotesCategories,
        displayPartyOnSubmit,
    } = props;

    const { residents: residentsDictionary } = langDictionary;
    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] = useState<boolean>(false);
    const [isUnscheduledTaskDialogOpen, setIsUnscheduledTaskDialogOpen] = useState<boolean>(false);

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const { mutate: residentShiftNoteCreate } = useResidentShiftNotesCreateMutation(userId);

    const branchShifts = useBranchShifts(branchId);

    const {
        shift: { id: currentShiftId },
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);

    const toggleUnscheduledTaskDialog = () => setIsUnscheduledTaskDialogOpen((previousState) => !previousState);
    const toggleShiftNotesDialog = () => setIsShiftNoteDialogOpen((previousState) => !previousState);

    const handleShiftNoteSubmit = async (report: string, newShiftNoteCategory: number) => {
        residentShiftNoteCreate({
            residentId,
            branchId,
            userId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
        });

        displayPartyOnSubmit();
    };

    const handleUnscheduledTaskSubmit = () => {
        displayPartyOnSubmit();
    };

    useEffect(() => {
        if (branchId && !shiftNotesCategoriesList.length) {
            dispatchReadShiftNotesCategories(branchId);
        }
    }, [branchId, shiftNotesCategoriesList]);

    if (loading) return <Loading />;

    return (
        <>
            <ChipsContainer>
                {shiftNotesCategoriesList.map((category) => {
                    const { id, name } = category;
                    const categoryCode = camelCase(name);
                    return <ChipStyle key={id} label={residentsDictionary[categoryCode]} variant="outlined" />;
                })}
            </ChipsContainer>
            <AddButtonContainer>
                <AddButtonStyle onClick={toggleUnscheduledTaskDialog}>
                    <Box
                        sx={{
                            marginRight: pxToRem(4),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    {residentsDictionary.unscheduledTaskAddButton}
                </AddButtonStyle>
                <AddButtonStyle onClick={toggleShiftNotesDialog}>
                    <Box
                        sx={{
                            marginRight: pxToRem(4),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    {residentsDictionary.shiftNotesAddButton}
                </AddButtonStyle>
            </AddButtonContainer>
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                onSubmit={handleShiftNoteSubmit}
                onClose={toggleShiftNotesDialog}
            />
            <UnscheduledTaskDialog
                isOpen={isUnscheduledTaskDialogOpen}
                defaultResidentId={residentId}
                timezone={timezone}
                currentShift={currentShiftId as number}
                onSubmit={handleUnscheduledTaskSubmit}
                onClose={toggleUnscheduledTaskDialog}
                canChangeResident={false}
            />
        </>
    );
};

const mapStateToProps = ({ session, shiftNotesCategories }: ReduxStore) => {
    const { timezone } = session;
    const { shiftNotesCategoriesList, loading } = shiftNotesCategories;

    return {
        loading,
        shiftNotesCategoriesList,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadShiftNotesCategories: (branchId: number) => dispatch(readShiftNotesCategories(branchId)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentChipsContainer: any = connect(mapStateToProps, mapDispatchToProps)(ResidentChipsContainer);

export default ConnectedResidentChipsContainer;
