import { Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { PiCheckBold, PiSparkleFill } from 'react-icons/pi';
import { useBlocker } from 'react-router';

import { isSaveOptimizationModalOpenAtom } from '../atom';
import { thereAreNewChanges } from '../helpers';
import { useBudgetedShifts } from '../useBudgetedShifts';
import { useUpdateBudgets } from '../useUpdateBudgets';

const HeaderContainer = styled(Stack)(() => ({
    position: 'static',
    top: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px 24px',
    backgroundColor: '#fff',
}));

const HeaderSection = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
});

export const Header = () => {
    const toggle = useSetAtom(isSaveOptimizationModalOpenAtom);
    const { palette } = useTheme();
    const { budgetedShifts } = useBudgetedShifts();
    const { budgetsToChange, reset: resetBudgets } = useUpdateBudgets();

    const disableButton = React.useMemo(
        () => !thereAreNewChanges(budgetsToChange, budgetedShifts),
        [budgetsToChange, budgetedShifts]
    );

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => !disableButton && currentLocation.pathname !== nextLocation.pathname
    );

    // prevent user from switching pages
    React.useEffect(() => {
        if (blocker.state === 'blocked') {
            if (confirm('Are you sure you want to leave? You will lose any unsaved changes.')) {
                resetBudgets();
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
    }, [blocker, resetBudgets]);

    // prevent user from closing the tab
    React.useEffect(() => {
        const preventTabToClose = (e: Event) => {
            if (!disableButton) {
                e.preventDefault();
            }
        };

        window.addEventListener('beforeunload', preventTabToClose);

        return () => window.removeEventListener('beforeunload', preventTabToClose);
    }, [disableButton]);

    return (
        <HeaderContainer>
            <HeaderSection>
                <Typography variant="h6">Staff Optimizations</Typography>
                <PiSparkleFill color={palette.primary[500] as string} size={16} />
            </HeaderSection>
            <Button sx={{ gap: '8px' }} disabled={disableButton} onClick={toggle}>
                <PiCheckBold color="white" size={16} /> Save Changes
            </Button>
        </HeaderContainer>
    );
};
