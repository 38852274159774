import { Box } from '@mui/material';
import React from 'react';

import { ResidentRowTask } from '~/components/Shared/Task/ConfirmedTaskRow/ResidentRowTask';
import { pxToRem } from '~/components/theme/typography';
import { ResidentTasks, TaskLoadingState } from '~/types/dailyTasks';

import { CollapseIcon } from './resident/CollapseIcon';
import { ResidentDetails } from './resident/ResidentDetails';
import { ResidentPhoto } from './resident/ResidentPhoto';
import { TaskCounter } from './resident/TaskCounter';

type Props = {
    isOpen: boolean;
    residentTasks: ResidentTasks;
    taskIdCompletionOptions: { [key: number]: number };
    loadingTaskStates: { [key: number]: TaskLoadingState };
    onResidentToggle: (number) => void;
    onToggleTaskNotes: (number) => void;
    onToggleEditAddTaskNotes: (number) => void;
    onToggleRejectDialog: (number) => void;
    onCompletionOptionsChange: (taskId: number, completionValue: number) => void;
};

export const ResidentRow = (props: Props) => {
    const {
        isOpen,
        residentTasks,
        taskIdCompletionOptions,
        loadingTaskStates,
        onResidentToggle,
        onToggleTaskNotes,
        onToggleEditAddTaskNotes,
        onToggleRejectDialog,
        onCompletionOptionsChange,
    } = props;

    const { id, firstName, lastName, photo, roomNumber, tasks } = residentTasks;

    const onToggleResident = () => onResidentToggle(id);

    return (
        <Box
            sx={{
                borderBottom: 1,
                borderBottomColor: '#DEDEE0',
                paddingBottom: pxToRem(16),
                paddingTop: pxToRem(16),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: isOpen ? pxToRem(16) : 0,
                }}
            >
                <ResidentPhoto residentId={id} firstName={firstName} lastName={lastName} photo={photo} />
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                    }}
                    onClick={onToggleResident}
                >
                    <ResidentDetails firstName={firstName} lastName={lastName} roomNumber={roomNumber} />
                    <TaskCounter count={tasks.length} />
                    <CollapseIcon isCollapsed={!isOpen} />
                </Box>
            </Box>
            {isOpen &&
                tasks.map((task) => {
                    const onToggleNotes = () => onToggleTaskNotes(task.dailyTaskRecordId);
                    const onToggleEditNotes = () => onToggleEditAddTaskNotes(task.dailyTaskRecordId);
                    const onToggleReject = () => onToggleRejectDialog(task.dailyTaskRecordId);
                    const completionOptionValue = taskIdCompletionOptions[task.dailyTaskRecordId];
                    return (
                        <ResidentRowTask
                            key={task.dailyTaskRecordId}
                            taskName={task.taskName}
                            taskTime={task.taskTime}
                            taskType={task.taskType}
                            taskNotes={task.taskNotes}
                            taskCompletionOptions={task.taskCompletionOptions}
                            taskStatusId={task.taskStatusId}
                            userTaskNotes={task.caregiverNotes}
                            caregiver={task.caregiver}
                            completedDateTime={task.taskStatusDateTime ?? undefined}
                            onToggleNotes={onToggleNotes}
                            onToggleEditAddTaskNotes={onToggleEditNotes}
                            onToggleRejectDialog={onToggleReject}
                            onCompletionOptionsChange={(completionValue: number) =>
                                onCompletionOptionsChange(task.dailyTaskRecordId, completionValue)
                            }
                            completionOptionValue={completionOptionValue}
                            loadingState={loadingTaskStates[task.dailyTaskRecordId] ?? null}
                        />
                    );
                })}
        </Box>
    );
};
