import { styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';

import { SHIFT_SLOT_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import ShiftSlotCell from './ShiftSlotCell';
import type { WeekGridSchema } from './types';

const EmptyCell = styled(Grid)({
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid',
});

const ShiftDayColumn = ({ day: { day, slots, isPreview } }: { day: WeekGridSchema.Day }) => {
    const colors =
        isPreview || day.isWeekend
            ? {
                  headerBackground: 'transparent',
                  headerBorder: 'white',
                  columnBackground: 'transparent',
              }
            : {
                  headerBackground: 'primary.50',
                  headerBorder: 'grey.100',
                  columnBackground: 'grey.50',
                  cellBackground: 'white',
              };

    return (
        <Grid container direction="column" xs bgcolor={colors.columnBackground}>
            <EmptyCell
                sx={{
                    bgcolor: colors.headerBackground,
                    borderColor: colors.headerBorder,
                }}
            />
            {slots.map((slot, index) => (
                <ShiftSlotCell
                    key={index}
                    slot={slot}
                    bgcolor={
                        // Preview and weekend cells should only have a background if they also have a slot,
                        // but they have different colors. Normal slots should always have a white background.
                        isPreview
                            ? slot
                                ? 'white' // To contrast with the gray slot button
                                : 'transparent'
                            : day.isWeekend
                              ? slot
                                  ? 'rgba(255, 255, 255, 0.5)' // To make the background stripes visible
                                  : 'transparent'
                              : 'white'
                    }
                />
            ))}
        </Grid>
    );
};

export default ShiftDayColumn;
