import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { DateTime, Duration } from 'luxon';

import { SHIFT_SLOT_CHANGE_REASON } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { SELECTOR_ALL_ID } from '../../../constants';
import { atomWithStorage, atomWithToggle, guardAtom } from '../../../utils/atoms';

import { SchedulePeriod } from './types';
import { getNotifyStaffDefaultValues, getNotifyStaffTimeOptions } from './utils/notifyStaff';

const interval = Duration.fromObject({ hour: 1 }); // TODO: Get from community settings

export const selectedCareTypeIdAtom = atomWithStorage('scheduling_Schedule_selectedCareTypeId', SELECTOR_ALL_ID);
export const selectedPeriodAtom = atomWithStorage<SchedulePeriod>('scheduling_Schedule_selectedPeriod', 'week');

export const addShiftSelectedDayAtom = atom(DateTime.now());
export const addShiftSelectedShiftIdAtom = atom<number | null>(null);
export const addShiftSelectedRoleIdAtom = atom<number | null>(null);
export const addShiftSelectedCareTypeIdAtom = atom<number | null>(null);
export const isAddShiftModalOpenAtom = atomWithToggle(false, (_, set) => {
    set(addShiftSelectedDayAtom, DateTime.now());
    set(addShiftSelectedShiftIdAtom, null);
    set(addShiftSelectedRoleIdAtom, null);
    set(addShiftSelectedCareTypeIdAtom, null);
});

export const addTraineeModalSlotIdAtom = atom<number | null>(null);
export const addTraineeIdAtom = atom<number | null>(null);
export const isAddTraineeModalOpenAtom = guardAtom(addTraineeModalSlotIdAtom, (set) => set(addTraineeIdAtom, null));

export const changeStaffModalSlotIdAtom = atom<number | null>(null);
export const changeStaffSelectedReasonAtom = atom<SHIFT_SLOT_CHANGE_REASON | null>(null);
export const isChangeStaffModalOpenAtom = guardAtom(changeStaffModalSlotIdAtom, (set) =>
    set(changeStaffSelectedReasonAtom, null)
);

const _selectStaffModalSlotIdAtom = atom<number | null>(null);
export const selectStaffModalSlotIdAtom = atom(
    (get) => get(_selectStaffModalSlotIdAtom),
    (_, set, slotId) => {
        set(_selectStaffModalSlotIdAtom, slotId);

        // On 'select staff' modal open
        if (slotId) {
            set(selectStaffNotifySelectedTimesAtom, getNotifyStaffDefaultValues(interval));
            set(selectStaffNotifyTimeOptionsAtom, getNotifyStaffTimeOptions(interval));
        }
    }
);
export const selectStaffModalTabAtom = atom<'suggested' | 'search' | 'notify'>('suggested');

export const _selectStaffSelectedStaffIdAtom = atom<number | null>(null);
export const selectStaffSelectedStaffIdAtom = atom(
    (get) => get(_selectStaffSelectedStaffIdAtom),
    (_, set, staffId) => {
        set(_selectStaffSelectedStaffIdAtom, staffId);

        // On cancel staff selection
        if (!staffId) {
            set(selectStaffNotifySelectedTimesAtom, getNotifyStaffDefaultValues(interval));
            set(selectStaffNotifyTimeOptionsAtom, getNotifyStaffTimeOptions(interval));
            set(selectStaffNotifyNotesAtom, null);

            set(selectStaffSearchValueAtom, '');
        }
    }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const selectStaffNotifiedStaffIdsAtomFamily = atomFamily((id: number) => atom<boolean | null>(false));

export const selectStaffNotifySelectedTimesAtom = atom<ReturnType<typeof getNotifyStaffDefaultValues>>([]);
export const selectStaffNotifyTimeOptionsAtom = atom<ReturnType<typeof getNotifyStaffTimeOptions>>([]);
export const selectStaffNotifyNotesAtom = atom<string | null>(null);
export const isSelectStaffNotifyNotesOpenAtom = atomWithToggle(false);

export const selectStaffSearchValueAtom = atom('');
export const addAgencyStaffNameAtom = atom<string | null>(null);
export const isAddAgencyStaffModalOpenAtom = atomWithToggle(false, (_, set) => set(addAgencyStaffNameAtom, null));

export const isSelectStaffModalOpenAtom = guardAtom(selectStaffModalSlotIdAtom, (set) => {
    // On modal close
    set(selectStaffModalTabAtom, 'suggested');

    // https://github.com/pmndrs/jotai/discussions/1121#discussioncomment-2628583
    selectStaffNotifiedStaffIdsAtomFamily.setShouldRemove(() => true);
    selectStaffNotifiedStaffIdsAtomFamily.setShouldRemove(null);

    set(selectStaffNotifySelectedTimesAtom, []);
    set(selectStaffNotifyTimeOptionsAtom, []);
    set(selectStaffNotifyNotesAtom, null);

    set(selectStaffSearchValueAtom, '');

    set(_selectStaffSelectedStaffIdAtom, null);
});

export const confirmStaffWarningIndexAtom = atom<number | null>(null);
export const isConfirmStaffModalOpenAtom = guardAtom(confirmStaffWarningIndexAtom);
