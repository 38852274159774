import { useDispatch } from 'react-redux';

import { ShiftNotesCategoryResponse } from '~/types/ShiftNotesCategories';
import { FiltersState } from '~/types/filters';
import {
    AppDispatch,
    DailyTasksState,
    ResidentsState,
    SessionState,
    ShiftNotesCategoriesState,
    TaskStatusesState,
    UIState,
    UsersState,
    ZonesState,
} from '~/types/redux';
import { SessionData } from '~/types/session';
import { TaskStatusesResponse } from '~/types/taskStatuses';
import { ZoneResponse } from '~/types/zones';

export const SESSION_DEFAULT_STATE: SessionState = {
    isSignedIn: null,
    isPermitLoaded: false,
    isPostHogLoaded: false,
    sessionData: {} as SessionData,
    timezone: 'America/Los_Angeles',
};

export const DAILY_TASKS_DEFAULT_STATE: DailyTasksState = {
    loading: false,
    dailyTasksList: [],
};

export const USERS_DEFAULT_STATE: UsersState = {
    loading: false,
};

export const ZONES_DEFAULT_STATE: ZonesState = {
    loading: false,
    zone: {} as ZoneResponse,
    zonesList: [],
};

export const RESIDENTS_DEFAULT_STATE: ResidentsState = {
    displayParty: false,
};

export const INFO_REPORT_CATEGORIES_DEFAULT_STATE: ShiftNotesCategoriesState = {
    loading: false,
    shiftNotesCategoriesList: [],
    shiftNoteCategory: {} as ShiftNotesCategoryResponse,
};

export const TASK_STATUSES_DEFAULT_STATE: TaskStatusesState = {
    loading: false,
    taskStatusesList: [],
    taskStatus: {} as TaskStatusesResponse,
};

export const FILTERS_DEFAULT_STATE: FiltersState = {
    caregiverApp: {},
};

export const UI_DEFAULT_STATE: UIState = {
    isMobileDrawerOpen: false,
};

export const useAppDispatch: () => AppDispatch = useDispatch;
