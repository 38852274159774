import { pxToRem } from '~/components/theme/typography';

export default function Menu(theme) {
    const { palette } = theme;
    return {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    '&.MuiPaper-root': {
                        padding: pxToRem(8),
                        backgroundColor: palette.common.white,
                        color: palette.common.black,
                        '& .MuiList-root.MuiMenu-list': {
                            padding: 0,
                            '& .MuiMenuItem-root.MuiMenuItem-gutters': {
                                padding: 0,
                                display: 'flex',
                            },
                            '& .MuiTypography-root.MuiLink-root.MuiLink-underlineNone': {
                                padding: 0,
                                fontWeight: 'bold',
                                color: palette.common.black,
                                cursor: 'pointer',
                                '&:hover': {
                                    fontWeight: 'bold',
                                    color: palette.common.black,
                                },
                                '&:visited': {
                                    fontWeight: 'bold',
                                    color: palette.common.black,
                                },
                            },
                            '& .MuiTypography-root.MuiTypography-body1': {
                                padding: 0,
                                fontWeight: 'bold',
                                color: palette.common.black,
                                cursor: 'pointer',
                                '&:hover': {
                                    fontWeight: 'bold',
                                    color: palette.common.black,
                                },
                                '&:visited': {
                                    fontWeight: 'bold',
                                    color: palette.common.black,
                                },
                            },
                        },
                    },
                },
            },
        },
    };
}
