import { Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { FiFilter as SortIcon } from 'react-icons/fi';

import langDictionary from '~/app-strings';
import { pxToRem } from '~/components/theme/typography';

type Props = {
    onClick: () => void;
};

const SortButton = ({ onClick }: Props) => {
    const { sortButtonTitle: sortButtonTitleTxt } = langDictionary.shared;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Button
            variant="outlined"
            startIcon={<SortIcon />}
            onClick={onClick}
            sx={{
                color: isMobile ? '#DEDEE0' : 'grey.700',
                borderColor: 'grey.400',
                width: '100%',
                height: { xs: pxToRem(38), md: pxToRem(42) },
            }}
        >
            {sortButtonTitleTxt}
        </Button>
    );
};

export default SortButton;
