import { TabList } from '@mui/lab';
import { Button, CircularProgress, Stack, StackProps, Tab, Typography, useTheme } from '@mui/material';
import { Chip } from '@mui/material';
import { Box } from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { STANDARDIZED_STAFF_TYPES_LABELS } from '@allie/utils/src/constants/scheduling/staff-types.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useChangeSlotStaff } from '~/scheduling/api/queries/shift-slot/changeSlotStaff';
import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetSlotStaffWarnings } from '~/scheduling/api/queries/shift-slot/getSlotStaffWarnings';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import SegmentedModal from '~/scheduling/components/SegmentedModal';

import CustomModal from '../../../../../../components/CustomModal';
import CustomTabContext from '../../../../../../components/CustomTabContext';
import { HorizontalSeparator } from '../../../../../../components/shared';
import {
    confirmStaffWarningIndexAtom,
    isSelectStaffModalOpenAtom,
    selectStaffModalSlotIdAtom,
    selectStaffModalTabAtom,
    selectStaffSelectedStaffIdAtom,
} from '../../../atoms';

import NotifyStaffTabPanel from './NotifyStaffTabPanel';
import SearchStaffTabPanel from './SearchStaffTabPanel';
import StaffOption from './StaffOption';
import SuggestedStaffTabPanel from './SuggestedStaffTabPanel';

const SelectStaffHeader = (props: StackProps) => (
    <Stack spacing="8px" {...props}>
        <Typography variant="body1" fontSize="16px" fontWeight={700}>
            Select Staff
        </Typography>
        <SelectStaffHeaderChips />
    </Stack>
);

const SelectStaffHeaderChips = () => {
    const slotId = useAtomValue(selectStaffModalSlotIdAtom);

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !slotById) return null;

    const { shiftDay, roleId, roleShiftId, locationId } = slotById.get(slotId)!;

    const dayStr = DateTime.fromISO(shiftDay).toFormat('EEE, MMM d');
    const role = roleById?.get(roleId)!.name;
    const roleShift = roleShiftById?.get(roleShiftId)!.name;
    const location = locationById?.get(locationId)!.abbreviation;

    return (
        <Stack direction="row" spacing="4px">
            <Chip variant="outlined" size="small" color="primary" label={dayStr} />
            <Chip variant="outlined" size="small" color="primary" label={roleShift} />
            <Chip variant="outlined" size="small" label={location} />
            <Chip variant="outlined" size="small" label={role} />
        </Stack>
    );
};

const SelectStaffNotSelectedModal = () => {
    const [isOpen, onClose] = useAtom(isSelectStaffModalOpenAtom);

    return (
        <CustomModal isOpen={isOpen} onClose={onClose} closeButton>
            <SelectStaffHeader p="24px" />
            <SelectStaffTabs />
        </CustomModal>
    );
};

const SelectStaffTabs = () => {
    const [selectedTab, setSelectedTab] = useAtom(selectStaffModalTabAtom);

    return (
        <CustomTabContext value={selectedTab}>
            <TabList onChange={(_, newTab: typeof selectedTab) => setSelectedTab(newTab)} sx={{ px: '24px' }}>
                <Tab label="Recommended Staff" value="suggested" />
                <Tab label="Notify Team" value="notify" />
                <Tab label="Search" value="search" />
            </TabList>

            <HorizontalSeparator sx={{ bgcolor: 'grey.100' }} />

            <Box p="24px">
                <SuggestedStaffTabPanel value="suggested" />
                <NotifyStaffTabPanel value="notify" />
                <SearchStaffTabPanel value="search" />
            </Box>
        </CustomTabContext>
    );
};

const SelectStaffSelectedModal = () => {
    const [isOpen, onClose] = useAtom(isSelectStaffModalOpenAtom);

    return (
        <SegmentedModal
            isOpen={isOpen}
            onClose={onClose}
            header={<SelectStaffHeader />}
            actions={<SelectStaffSelectedActions />}
            closeButton
        >
            <Stack spacing="4px">
                <Typography variant="body1" fontWeight={700}>
                    Change to
                </Typography>
                <SelectStaffSelectedStaffOption />
            </Stack>
        </SegmentedModal>
    );
};

const SelectStaffSelectedStaffOption = () => {
    const { palette } = useTheme();

    const setSelectStaffModalTab = useSetAtom(selectStaffModalTabAtom);
    const [staffId, setStaffId] = useAtom(selectStaffSelectedStaffIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    if (!staffId || !staffById) return null;

    const { name, staffType } = staffById.get(staffId)!;

    return (
        <StaffOption
            name={name}
            details={[STANDARDIZED_STAFF_TYPES_LABELS[staffType]]} // TODO: Add phone number
            actions={
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<PencilSimple color={palette.grey[600]} weight="fill" />}
                    onClick={() => {
                        setStaffId(null);
                        setSelectStaffModalTab('search');
                    }}
                >
                    Change
                </Button>
            }
            borderRadius="8px"
            border={`1px solid ${palette.grey[100]}`}
        />
    );
};

const SelectStaffSelectedActions = () => {
    const toggle = useSetAtom(isSelectStaffModalOpenAtom);

    const slotId = useAtomValue(selectStaffModalSlotIdAtom);
    const staffId = useAtomValue(selectStaffSelectedStaffIdAtom);

    const { data: warnings, isLoading: isWarningsLoading } = useGetSlotStaffWarnings(slotId, staffId);
    const setWarningIndex = useSetAtom(confirmStaffWarningIndexAtom);

    const { mutateAsync: changeSlotStaff, isPending: isChangeSlotStaffPending } = useChangeSlotStaff();

    if (!slotId || !staffId) return null;

    return (
        <Button
            onClick={async () => {
                if (warnings?.length) setWarningIndex(0);
                else {
                    await changeSlotStaff({ slotId, data: { staffId } });
                    toggle();
                }
            }}
            disabled={isWarningsLoading || isChangeSlotStaffPending}
        >
            {isChangeSlotStaffPending ? (
                <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
            ) : (
                'Confirm'
            )}
        </Button>
    );
};

const SelectStaffModal = () => {
    const selectedStaffId = useAtomValue(selectStaffSelectedStaffIdAtom);

    return selectedStaffId ? <SelectStaffSelectedModal /> : <SelectStaffNotSelectedModal />;
};

export default SelectStaffModal;
