import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { PiTrashBold } from 'react-icons/pi';

import {
    STANDARDIZED_STAFF_SCHEDULE_TYPES,
    STANDARDIZED_STAFF_SCHEDULE_TYPES_LABELS,
} from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';

import { GetStaff } from '~/scheduling/api/types/staff/getStaff';
import { FormDatePicker } from '~/scheduling/components/form/FormDatePicker';
import { FormDropdown } from '~/scheduling/components/form/FormDropdown';

import { StaffDetailsFormFields } from '../../types';

import { BaylorSchedule } from './BaylorSchedule';
import { BiweeklySchedule } from './BiweeklySchedule';
import { CustomSchedule } from './CustomSchedule';
import { FourOnTwoOffSchedule } from './FourOnTwoOffSchedule';
import { WeeklySchedule } from './WeeklySchedule';
import { StaffScheduleProps } from './shared';

const ScheduleComponent = ({ type, ...props }: StaffScheduleProps & { type: STANDARDIZED_STAFF_SCHEDULE_TYPES }) => {
    return (
        {
            [STANDARDIZED_STAFF_SCHEDULE_TYPES.BAYLOR]: <BaylorSchedule {...props} />,
            [STANDARDIZED_STAFF_SCHEDULE_TYPES.FOUR_ON_TWO_OFF]: <FourOnTwoOffSchedule {...props} />,
            [STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY]: <WeeklySchedule {...props} />,
            [STANDARDIZED_STAFF_SCHEDULE_TYPES.CUSTOM_WEEKLY]: <CustomSchedule {...props} />,
            [STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY]: <BiweeklySchedule {...props} />,
        } satisfies Record<STANDARDIZED_STAFF_SCHEDULE_TYPES, React.ReactNode>
    )[type];
};

interface ScheduleSectionProps {
    form: UseFormReturn<StaffDetailsFormFields>;
    scheduleIndex: number;
    staff?: GetStaff.Staff;
    onDelete: () => void;
    isDeleting: boolean;
    isSaving: boolean;
}

const ScheduleSection = ({ form, staff, scheduleIndex, onDelete, isDeleting, isSaving }: ScheduleSectionProps) => {
    const isOnEditingState = !!staff;
    const scheduleType = useWatch({ name: `schedules.${scheduleIndex}.type`, control: form.control });
    const today = DateTime.now().startOf('day');
    const schedule = staff?.schedules[scheduleIndex];
    const previousSchedule = staff?.schedules.at(-1);
    const hasStarted = schedule?.startAt && DateTime.fromISO(schedule?.startAt).startOf('day') <= today;

    const scheduleTypeOptions = sortBy(
        Object.entries(STANDARDIZED_STAFF_SCHEDULE_TYPES_LABELS).map(([key, value]) => ({
            label: value,
            value: key,
        })),
        ({ label }) => label
    );

    const validateDate = (date: DateTime): string | undefined => {
        // when is not editing a previous schedule, the component disable past dates
        if (isOnEditingState) {
            const valueChanged = +DateTime.fromISO(staff.schedules[scheduleIndex]?.startAt) !== +date;
            const isOnPast = valueChanged && date < today;

            return isOnPast ? 'Cannot be in the past' : undefined;
        }
    };

    return (
        <Stack spacing="12px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography sx={{ fontSize: '16px', fontWeight: '700', marginBottom: '8px' }}>
                    {scheduleIndex === 0 ? 'Current Schedule' : 'Future Schedule'}
                </Typography>
                {!hasStarted && (
                    <Button
                        variant="text"
                        color="error"
                        size="small"
                        startIcon={!isDeleting && <PiTrashBold size={16} />}
                        onClick={onDelete}
                        disabled={isSaving}
                        sx={({ palette }) => (isDeleting ? { backgroundColor: palette.error[25] as string } : {})}
                    >
                        {isDeleting ? (
                            <CircularProgress
                                size={16}
                                thickness={4}
                                sx={({ palette }) => ({ color: palette.error[500] as string })}
                            />
                        ) : (
                            'Delete Schedule'
                        )}
                    </Button>
                )}
            </Box>
            <Box display="flex" gap="12px">
                <Box flex={1}>
                    <FormDropdown
                        name={`schedules.${scheduleIndex}.type`}
                        selectProps={{
                            defaultValue: (schedule ?? previousSchedule)?.type,
                            disabled: !!schedule || isSaving,
                        }}
                        form={form}
                        label="Schedule Type"
                        options={scheduleTypeOptions}
                        validations={{ required: 'Required field' }}
                    />
                </Box>
                <Box flex={1}>
                    <FormDatePicker
                        defaultValue={schedule?.startAt ? DateTime.fromISO(schedule.startAt) : undefined}
                        disabled={!!schedule || isSaving}
                        disablePast
                        validations={{ required: 'Required field', validate: validateDate }}
                        label="Start Date"
                        name={`schedules.${scheduleIndex}.startDate`}
                        form={form}
                        format="MM/dd/yyyy"
                    />
                </Box>
            </Box>
            {scheduleType || previousSchedule?.type ? (
                <ScheduleComponent
                    type={scheduleType ?? previousSchedule!.type}
                    scheduleIndex={scheduleIndex}
                    form={form}
                    schedule={schedule ?? previousSchedule}
                    isSaving={isSaving}
                    disabled={!!schedule}
                />
            ) : null}
        </Stack>
    );
};

export default ScheduleSection;
