import { Box, Stack } from '@mui/material';
import React from 'react';

import { LayoutBackground } from '../../components/LayoutBackground';

import BudgetOverviewCard from './components/BudgetOverviewCard';
import { Header } from './components/Header';
import RecommendationList from './components/RecommendationList';
import SaveOptimizationModal from './components/SaveOptimizationModal';
import ShiftCalendar from './components/ShiftCalendar';

const StaffOptimizations = () => {
    const headerHeight = '84px';

    return (
        <Stack position="relative" height="100%">
            <Header />

            <LayoutBackground sx={{ flexDirection: 'row', height: `calc(100% - ${headerHeight})` }}>
                <ShiftCalendar />
                <Box display="flex" gap="24px" flexDirection="column">
                    <BudgetOverviewCard />
                    <RecommendationList />
                </Box>
            </LayoutBackground>

            <SaveOptimizationModal />
        </Stack>
    );
};

export default StaffOptimizations;
