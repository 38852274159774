import { Box, Button, Zoom } from '@mui/material';
import React from 'react';

import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';
import Loading from '~/components/Shared/Loading';
import { GiftIcon } from '~/components/Svg/GiftIcon';
import { pxToRem } from '~/components/theme/typography';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
};

const PointsRedemptionModal = (props: Props) => {
    const { isOpen, onClose, isLoading } = props;

    return (
        <CustomMobileDialog
            closeable={false}
            open={isOpen}
            width="100%"
            onClose={onClose}
            content={
                <>
                    {isLoading ? (
                        <Box
                            sx={{
                                padding: pxToRem(20),
                            }}
                        >
                            <Loading />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: { md: pxToRem(24) },
                            }}
                        >
                            <Box
                                sx={{
                                    color: '#006B75',
                                    width: pxToRem(100),
                                    height: pxToRem(100),
                                    borderRadius: pxToRem(120),
                                    backgroundColor: '#F4F4F5',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: pxToRem(24),
                                }}
                            >
                                <Zoom
                                    in={isOpen}
                                    style={{
                                        transitionDelay: isOpen ? '300ms' : '0ms',
                                    }}
                                >
                                    <Box>
                                        <GiftIcon size={36} />
                                    </Box>
                                </Zoom>
                            </Box>
                            <Box
                                sx={{
                                    fontSize: pxToRem(20),
                                    fontWeight: 700,
                                    lineHeight: pxToRem(32),
                                    marginBottom: pxToRem(8),
                                    textAlign: 'center',
                                }}
                            >
                                $5 On the Way!
                            </Box>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    marginBottom: pxToRem(24),
                                    fontSize: pxToRem(16),
                                    lineHeight: pxToRem(24),
                                    fontWeight: pxToRem(400),
                                }}
                            >
                                {`Thank you so much for your hard work! Your gift card is now on the way. You should receive it through a text message on your phone in the next 1-2 business days.`}
                            </Box>
                            <Box
                                sx={{
                                    mt: pxToRem(8),
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: { xs: '100%', md: pxToRem(200) },
                                }}
                            >
                                <Button
                                    color="primary"
                                    onClick={onClose}
                                    sx={{
                                        width: '100%',
                                        p: pxToRem(12),
                                        fontSize: pxToRem(14),
                                        flexGrow: 1,
                                    }}
                                >
                                    Ok
                                </Button>
                            </Box>
                        </Box>
                    )}
                </>
            }
        />
    );
};

export default PointsRedemptionModal;
