import { ComponentOverride } from '.';

export const MuiChip: ComponentOverride<'MuiChip'> = {
    defaultProps: {
        variant: 'outlined',
    },
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            height: 'fit-content',
            padding: '4px 8px',
            backgroundColor: palette.grey[25],
            borderColor: palette.grey[200],
            borderRadius: '6px',
            '& .MuiChip-label': {
                color: palette.grey[500],
                fontWeight: 600,
                padding: 0,
            },
        }),
        colorPrimary: ({ theme: { palette } }) => ({
            backgroundColor: palette.primary[25],
            borderColor: palette.primary[100],
            '& .MuiChip-label': {
                color: palette.primary.main,
            },
        }),
        sizeMedium: {
            '& .MuiChip-label': {
                fontSize: '15px',
                lineHeight: '20px',
            },
        },
        sizeSmall: {
            '& .MuiChip-label': {
                fontSize: '13px',
                lineHeight: '16px',
            },
        },
    },
};
