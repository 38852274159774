import { Stack } from '@mui/material';
import React from 'react';

import { LayoutBackground } from '../../components/LayoutBackground';

import Alerts from './components/Alerts';
import Header from './components/Header';
import HourCards from './components/HourCards';
import { CallOffTable, PeopleScheduledOvertimeTable } from './components/Table';

const Reports = () => {
    return (
        <Stack position="relative" height="100%">
            <Header />

            <LayoutBackground>
                <Alerts />
                <HourCards />
                <PeopleScheduledOvertimeTable />
                <CallOffTable />
            </LayoutBackground>
        </Stack>
    );
};

export default Reports;
