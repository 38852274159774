import { pxToRem } from '~/components/theme/typography';

export const MOBILE_INPUT_STYLES = {
    '& .MuiTextField-root': {
        width: '100%',
        '& fieldset': {
            borderColor: '#DEDEE0',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
        },
        '& .MuiInputBase-input': {
            color: 'common.white',
            pt: pxToRem(8),
            pb: pxToRem(9),
        },
        '& label': {
            color: 'common.white',
        },
    },
};

export const INPUT_STYLES = {
    '& .MuiTextField-root': {
        width: '100%',
        '& fieldset': {
            borderColor: 'gray.400',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
        },
        '& .MuiInputBase-input': {
            color: 'common.black',
            pt: pxToRem(8),
            pb: pxToRem(9),
        },
        '& label': {
            color: 'gray.400',
        },
    },
};
