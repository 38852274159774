import { DateTime } from 'luxon';
import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/scheduling/api/common';
import { GetLastPublishedDate } from '~/scheduling/api/types/shift-slot/getLastPublishedDate';

export const useGetLastPublishedDate = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };

        const { data } = await api.get<GetLastPublishedDate.Response>(
            `/scheduling/${branchId}/shift-slot/team/${teamId}/last-published-date/`,
            options
        );

        return DateTime.fromISO(data.response);
    }, [branchId, teamId, token]);

    return useErrorHandledQuery({
        queryKey: ['last-published-date', branchId, teamId],
        queryFn,
        staleTime: 30000,
        enabled: !!branchId && !!teamId,
    });
};
