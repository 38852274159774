import { App } from '@capacitor/app';
import { Geolocation, PermissionStatus, Position } from '@capacitor/geolocation';
import { captureException } from '@sentry/react';
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { calculateDistanceBetweenCoordinates } from '~/lib/geolocation';

type GeolocationContextType = {
    permissionStatus: PermissionStatus | null;
    position: Position | null;
    isPositionAccurate: boolean | null;
    distance: number | null;
    requestPermission: () => Promise<PermissionStatus>;
    updatePosition: () => Promise<void>;
    isUserInsideWorkingArea: (params: IsUserInsideWorkingAreaParams) => boolean | null;
};

interface IsUserInsideWorkingAreaParams {
    branchLat: number;
    branchLon: number;
    maxDistance: number;
}

export const GeolocationContext = createContext<GeolocationContextType | null>(null);

interface GeolocationProviderProps {
    children: ReactNode;
}

const ACCURACY_THRESHOLD = 50;

const GeolocationProvider: React.FC<GeolocationProviderProps> = ({ children }) => {
    const [permissionStatus, setPermissionStatus] = useState<PermissionStatus | null>(null);
    const [position, setPosition] = useState<Position | null>(null);
    const [distance, setDistance] = useState<number | null>(null);

    const intervalRef = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        const init = async () => {
            const permissions = await checkPermission();
            if (permissions.location === 'granted') {
                updatePosition();
                enableUpdateInterval();
            }
        };

        init();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!intervalRef.current && permissionStatus?.location === 'granted') {
            enableUpdateInterval();
        }
    }, [position, permissionStatus]);

    App.addListener('resume', async () => {
        const permissions = await checkPermission();
        if (permissions.location === 'granted') {
            updatePosition();
            if (!intervalRef.current) {
                enableUpdateInterval();
            }
        }
    });

    const isPositionAccurate = useMemo(() => {
        if (!position) return null;

        // DEBUG
        // return false;

        return position.coords.accuracy < ACCURACY_THRESHOLD;
    }, [position]);

    const checkPermission = async () => {
        const permissions = await Geolocation.checkPermissions();
        setPermissionStatus(permissions);
        return permissions;
    };

    const requestPermission = async () => {
        const grantedPermissions = await Geolocation.requestPermissions();
        setPermissionStatus(grantedPermissions);
        return grantedPermissions;
    };

    const updatePosition = async () => {
        try {
            const newPosition = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
            });

            setPosition(newPosition);
        } catch (err) {
            captureException(err);
        } finally {
            // Getting the current position might prompt the user for permission,
            // so call this function to keep the state updated
            await checkPermission();
        }
    };

    const enableUpdateInterval = () => {
        intervalRef.current = setInterval(() => {
            updatePosition();
        }, 120000);
    };

    const isUserInsideWorkingArea = ({
        branchLat,
        branchLon,
        maxDistance, // Miles
    }: IsUserInsideWorkingAreaParams): boolean | null => {
        if (!position) {
            return null;
        }

        const { latitude, longitude } = position.coords;

        const dist = calculateDistanceBetweenCoordinates({
            positionA: { latitude, longitude },
            positionB: { latitude: branchLat, longitude: branchLon },
        });

        setDistance(dist);

        if (dist > maxDistance) {
            return false;
        }

        return true;
    };

    return (
        <GeolocationContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                permissionStatus,
                position,
                isPositionAccurate,
                distance,
                requestPermission,
                updatePosition,
                isUserInsideWorkingArea,
            }}
        >
            {children}
        </GeolocationContext.Provider>
    );
};

// Create a custom hook for accessing the context
const useGeolocation = (): GeolocationContextType => {
    const context = useContext(GeolocationContext);
    if (!context) {
        throw new Error('useGeolocation must be used within a GeolocationProvider');
    }
    return context;
};

export { GeolocationProvider, useGeolocation };
