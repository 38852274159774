import { api } from '~/api';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';

export const useResidentDashboardDetailsQuery = (id: number | null, branchId: number) => {
    const token = useToken();

    return useErrorHandledQuery(
        ['resident-dashboard-details', id],
        async (): Promise<ResidentDetailsResponse> =>
            (
                await api.get(`/dashboard/resident-details-v2/${id}`, {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!id, staleTime: 300000 }
    );
};
