import { Box, Typography, styled } from '@mui/material';
import React from 'react';

import { WEEK_DAYS } from './constants';

const WeekColumn = styled(Typography)(({ theme }) => ({
    width: '32px',
    height: '32px',
    padding: '8px',
    textAlign: 'center',
    lineHeight: '16px',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.grey[600],
}));

export const CalendarWeekRow = () => {
    return (
        <Box display="flex">
            {WEEK_DAYS.map((day, i) => (
                <WeekColumn key={'week day' + i} variant="body1">
                    {day}
                </WeekColumn>
            ))}
        </Box>
    );
};
