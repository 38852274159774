import { useMutation, useQueryClient } from '@tanstack/react-query-v4';

import { api } from '~/api';
import { useAppDispatch } from '~/constants/redux';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import { showAlert, throwError } from '~/redux/actions/messages';
import {
    DailyTasksCreateParams,
    DailyTasksReadParams,
    DailyTasksResponse,
    UpdateDailyTasksParams,
} from '~/types/dailyTasks';
import { MessageProps } from '~/types/messages';

export const useDailyTasks = (jsonParams: DailyTasksReadParams) => {
    const params = formatApiParams(jsonParams);
    const token = useToken();

    return useErrorHandledQuery<DailyTasksResponse>(
        ['dailyTasks', jsonParams],
        async (): Promise<DailyTasksResponse> => {
            const { data } = await api.get('/tasks', {
                headers: { authorization: token },
                params,
            });
            return data.response;
        },
        {
            enabled: !!jsonParams.branchId && !!jsonParams.date,
            staleTime: 300000,
        }
    );
};

export const useCreateDailyTask = () => {
    const token = useToken();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    return useMutation(
        async (jsonParams: DailyTasksCreateParams) => {
            const params = formatApiParams(jsonParams);

            const { data, status } = await api.post('/tasks', params, {
                headers: { authorization: token },
                params: { branch_id: jsonParams.branchId },
            });

            return { status, response: data.response };
        },
        {
            onSuccess: async (data) => {
                const { status, response } = data;
                const created = status === 201;

                const message: MessageProps = {
                    message: created
                        ? 'The task has been Created successfully!'
                        : response.isPrnExpected
                          ? "This PRN task was not created because it is already part of the resident's service plan or schedule. Thanks for reporting it!"
                          : 'This PRN was not created! Please try again later.',
                    alertSeverity: created ? 'success' : response.isPrnExpected ? 'info' : 'warning',
                    open: true,
                    status,
                };

                dispatch(showAlert(message));

                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: ['user-fraud-status'] }),
                    queryClient.invalidateQueries({ queryKey: ['dailyTasks'] }),
                    // TODO: specify the residentId
                    queryClient.invalidateQueries({ queryKey: ['residentDailyTasks'] }),
                    queryClient.invalidateQueries({ queryKey: ['rewards'] }),
                ]);
            },
            onError: (error: any) => {
                console.error('Error creating task:', error);
                dispatch(throwError(error));
            },
        }
    );
};

export const useUpdateDailyTasks = () => {
    const token = useToken();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    return useMutation(
        async ({ tasks, branchId }: UpdateDailyTasksParams) => {
            const { status } = await api.put(`/tasks`, tasks, {
                headers: { authorization: token },
                params: { branch_id: branchId },
            });

            return { status };
        },
        {
            onSuccess: async (data) => {
                const { status } = data;
                const success = status === 200;

                const message: MessageProps = {
                    open: true,
                    message: success ? 'The task has been Updated successfully!' : 'Error updating task',
                    alertSeverity: success ? 'success' : 'error',
                    status: 200,
                };

                dispatch(showAlert(message));
                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: ['documentation-actions'] }),
                    queryClient.invalidateQueries({ queryKey: ['dailyTasks'] }),
                    // TODO: specify the residentId
                    queryClient.invalidateQueries({ queryKey: ['residentDailyTasks'] }),
                ]);
            },
            onError: (error: any) => {
                console.error('Error updating task:', error);
                dispatch(throwError(error));
            },
        }
    );
};
